import React from "react";

const OrganisationLicenses = ({ licenses }) => {
  if (!licenses) {
    return null;
  }
  return (
    <div style={{ fontSize: 12 }}>
      {Object.keys(licenses).map(key => {
        const { bookQuota, libraryQuota, quantity, applyToAll } = licenses[key];
        const quantityDisplay = applyToAll ? "Domain" : quantity;
        return (
          <span key={key} style={{ display: "block" }}>
            {quantityDisplay} x ({libraryQuota}x{bookQuota})
          </span>
        );
      })}
    </div>
  );
};

export default OrganisationLicenses;
