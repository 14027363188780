import React from "react";
import { withRouter } from "react-router";
import SearchField from "../../forms/search-field";

function LibrarySearch(props) {
  const { authToken, history, match, searchLibraries } = props;

  const handleSearch = (libraryId) => {
    let trimmedLibraryId = libraryId.trim();
    if (!trimmedLibraryId.length || trimmedLibraryId.length < 2) {
      return;
    }
    searchLibraries({ libraryId: trimmedLibraryId });
    history.push(`/libraries/${trimmedLibraryId}`);
  };

  return (
    <SearchField
      authToken={authToken}
      onSubmit={handleSearch}
      search={match.params.libraryId}
      placeholder="Search by library ID"
    />
  );
}

export default withRouter(LibrarySearch);
