import React, { Fragment } from "react";
import { firebaseDb } from "../../../../../../../core/firebase";

const FEATURES = [
  // {
  //   name: "Translations",
  //   id: "translations",
  // },
];

function FeatureFlags(props) {
  if (!FEATURES.length) {
    return null;
  }

  const { preferences, uid, updateUserValueInStore } = props;

  const features = preferences?.features || {};

  return (
    <Fragment>
      <dt>Feature flags:</dt>
      <dd>
        {FEATURES.map(f => {
          const handleChange = () => {
            const checked = !!(features && features[f.id]);
            const nextValue = !checked ? true : null;
            firebaseDb
              .ref(`/users/${uid}/preferences/features/${f.id}`)
              .set(nextValue)
              .then(() => {
                updateUserValueInStore({
                  uid,
                  location: "preferences",
                  value: {
                    ...preferences,
                    features: {
                      ...features,
                      [f.id]: nextValue,
                    },
                  },
                });
              });
          };
          return (
            <div key={f.id}>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 5,
                }}>
                <input
                  type="checkbox"
                  checked={!!(features && features[f.id])}
                  onChange={handleChange}
                  style={{ marginRight: 5 }}
                />{" "}
                {f.name}
              </label>
            </div>
          );
        })}
      </dd>
    </Fragment>
  );
}

FeatureFlags.propTypes = {};

export default FeatureFlags;
