import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import flow from "lodash.flow";
import { withRouter } from "react-router";
import { getAuthToken } from "../../../../core/auth";
import {
  usersActions,
  getUsersSearching,
  getDomainSearching,
} from "../../../../core/users";
import SearchField from "../../forms/search-field";

class UserSearch extends Component {
  handleSearch = searchTerm => {
    const { searchUsers, searchByDomain } = this.props;
    let trimmedSearch = searchTerm.trim();
    if (!trimmedSearch.length || trimmedSearch.length < 2) {
      return;
    }
    if (trimmedSearch[0] === "@") {
      searchByDomain({ searchTerm: trimmedSearch, newSearch: true });
      this.props.history.push(`/users/?domain=${trimmedSearch}`);
    } else {
      searchUsers({ searchTerm: trimmedSearch });
      this.props.history.push(`/users/${trimmedSearch}`);
    }
  };

  componentWillReceiveProps(nextProps) {
    const { match, resetUserState, searchUsers, usersSearching } = this.props;
    const { params } = match;
    if (
      params.userIdOrEmail !== nextProps.match.params.userIdOrEmail &&
      !usersSearching &&
      !nextProps.usersSearching
    ) {
      if (nextProps.match.params.userIdOrEmail) {
        searchUsers({ searchTerm: nextProps.match.params.userIdOrEmail });
      }
    }
    if (params.userIdOrEmail && !nextProps.match.params.userIdOrEmail) {
      resetUserState();
    }
  }

  getSearchDomain = props => {
    const query = props.location.search;
    if (!query) {
      return null;
    }
    const params = new URLSearchParams(props.location.search);
    return params.get("domain");
  };

  render() {
    const { authToken, match } = this.props;

    return (
      <SearchField
        authToken={authToken}
        onSubmit={this.handleSearch}
        search={match.params.userIdOrEmail || this.getSearchDomain(this.props)}
        placeholder="Search users by email or ID"
      />
    );
  }
}

const mapStateToProps = createSelector(
  getAuthToken,
  getUsersSearching,
  getDomainSearching,
  (authToken, usersSearching, domainSearching) => ({
    authToken,
    usersSearching,
    domainSearching,
  })
);

const mapDispatchToProps = {
  ...usersActions,
};

const decorators = flow([
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
]);

export default decorators(UserSearch);
