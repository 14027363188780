export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCDn1ZFaB4HnQTCZmjHoH-0JIXXPHGvxVM",
  authDomain: "app.bookcreator.com",
  databaseURL: "https://book-creator.firebaseio.com",
  storageBucket: "book-creator.appspot.com",
  messagingSenderId: null,
  projectName: "book-creator",
  projectId: "book-creator",
};
export const ADMIN_URL = "https://admin.bookcreator.com";
export const BOOKCREATOR_APP_URL = "https://app.bookcreator.com";
export const BOOKCREATOR_API_URL = "https://api.bookcreator.com";
export const BOOKCREATOR_ORGS_API_URL = "https://organisations.api.bookcreator.com";
export const ANALYTICS_DOMAIN = "https://analytics.api.bookcreator.com";

export const BOOKCREATOR_ASSETS_URL = "https://assets.api.bookcreator.com";
export const BOOKCREATOR_STORAGE_URL = "https://storage.bookcreator.com";
export const BOOKCREATOR_PROMO_CODE_PREFIX =
  "bookcreator://";
export const SUBSCRIPTIONS_DOMAIN = "https://subscriptions.api.bookcreator.com";
export const FIREBASE_DATABASE_APPS_URL =
  "htts://book-creator-apps.firebaseio.com";
export const FIREBASE_DATABASE_BLOCKS_URL =
  "https://book-creator-blocks.firebaseio.com";
export const FIREBASE_DATABASE_DELETED_ORGS_URL =
  "https://book-creator-closed-organisations.europe-west1.firebasedatabase.app/";
export const NOUN_PROJECT_DOMAIN = "https://noun.api.bookcreator.com";
export const SEARCH_API_DOMAIN = "https://search.api.bookcreator.com";

export const GA_ID = "";

export const SENTRY_URL = "";

export const AUTH_COOKIE_NAME = "mission_bc_auth";
export const APP_AUTH_COOKIE_NAME = "mission_bc_auth";
export const AUTH_COOKIE_DOMAIN = "bookcreator.com";

export const RECURLY_URL = "https://bookcreator.recurly.com/subscribe/";
