// getDomainLicenseRetry
import React, { useState } from "react";
import Modal from "../../../modal";
import { organisationsActions } from "../../../../../core/organisations";
import { connect } from "react-redux";
import { notificationsActions } from "../../../../../core/notifications";
import { applyLicenseToDomain } from "../../../../../core/organisations/orgs-api";

function DomainLicenseRetry(props) {
  const {
    setDomainLicenseRetry,
    orgId,
    domain,
    addErrorMessage,
    addNotification,
    onSuccess,
  } = props;

  const [retrying, setRetrying] = useState(false);

  const onClose = () => {
    setDomainLicenseRetry(null);
  };

  const handleRetry = async () => {
    setRetrying(true);
    try {
      const res = await applyLicenseToDomain({ orgId, domain });
      if (res.ok) {
        onClose();
        addNotification("Domain licenses successfully applied");
        onSuccess();
      } else {
        throw new Error();
      }
    } catch (err) {
      setRetrying(false);
      addErrorMessage("Retry failed, please try again");
    }
  };
  return (
    <Modal
      title="Licenses not applied"
      body="Something went wrong applying the domain license. <br/><br/> Please retry a few times to try and complete the operation, if it doesn't work please contact Engineering."
      modal
      loadingMessage={retrying ? "Retrying" : null}
      onCancel={onClose}
      onConfirm={handleRetry}
      confirmText="Retry"
    />
  );
}

const mapDispatchToProps = {
  ...organisationsActions,
  ...notificationsActions,
};

export default connect(null, mapDispatchToProps)(DomainLicenseRetry);
