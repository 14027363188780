import React from "react";
import classNames from "classnames";
import "./index.scss";

function NounProjectID(props) {
  const { blocked, id, onToggle, firebaseId } = props;

  // console.log({firebaseId})

  const className = classNames("noun-search-id", {
    "noun-search-id--blocked": blocked,
  });

  const handleClick = (evt) => {
    onToggle({ id: firebaseId, value: !blocked ? id : null });
    evt.preventDefault();
  };

  return (
    <div className={className}>
      <span className="noun-search-id__label">
        ID: <strong>{id}</strong>
      </span>
      <button className="noun-search-id__button" onClick={handleClick}>
        {blocked ? "Unblock" : "Block"}
      </button>
    </div>
  );
}

export default NounProjectID;
