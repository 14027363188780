import React, { Component } from "react";
import classNames from "classnames";
import SvgIcon from "../ui/svg-icon";
import CrossIcon from "../../../images/svg/cross.svg";
import TickIcon from "../../../images/svg/tick--thick.svg";
import "./index.scss";

export default class VerifiedBadge extends Component {
  get icon() {
    const { verified } = this.props;
    if (verified) {
      return <SvgIcon icon={TickIcon} name="tick" />;
    } else {
      return <SvgIcon icon={CrossIcon} name="cross" />;
    }
  }

  render() {
    const { verified } = this.props;

    const cssClass = classNames("verified-badge", {
      "verified-badge--verified": verified,
    });

    return (
      <div className={cssClass} title={verified ? "Verified" : "Not verified"}>
        {this.icon}
      </div>
    );
  }
}
