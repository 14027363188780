import React, { Component } from "react";
import { connect } from "react-redux";
import { BOOKCREATOR_API_URL, BOOKCREATOR_APP_URL } from "../../../../config";
import { usersActions } from "../../../../core/users";
import { notificationsActions } from "../../../../core/notifications";
import superagent from "superagent";
import Modal from "../../modal";
import Loader from "../../ui/loader";

class UserToImpersonateModal extends Component {
  state = {
    token: null,
  };

  componentDidMount() {
    this.getLoginToken();
  }

  handleCancelClick = (e) => {
    const { setShowImpersonateUserModal } = this.props;
    setShowImpersonateUserModal(null);
  };

  handleConfirmClick = (e) => {
    const { setShowImpersonateUserModal } = this.props;

    setShowImpersonateUserModal(null);
  };

  get link() {
    const { token } = this.state;
    return `${BOOKCREATOR_APP_URL}/sign-in-with-token/${token}`;
  }

  getLoginToken = () => {
    const { userToImpersonate, addErrorMessage } = this.props;
    const { uid } = userToImpersonate;
    superagent
      .post(`${BOOKCREATOR_API_URL}/admin-tools/v2/${uid}/auth`)
      .withCredentials()
      .end((err, res) => {
        if (err) {
          addErrorMessage("Could not sign in as that user");
        } else {
          this.setState({
            token: res.body,
          });
        }
      });
  };

  render() {
    const { userToImpersonate } = this.props;
    const { token } = this.state;

    const { displayName, email, loading } = userToImpersonate;

    const textAreaStyle = {
      display: "block",
      width: "100%",
      fontSize: 14,
      padding: "5px 10px",
      height: 220,
      margin: "40px auto 0",
      wordBreak: "break-all",
    };

    return (
      <Modal
        modal
        title={loading ? "Signing in..." : "Sign in as user?"}
        hideFooter={loading}
        onCancel={this.handleCancelClick}
        width={800}
        cancelText="Close"
      >
        {loading || !token ? (
          <div style={{ height: 70 }}>
            <Loader center />
          </div>
        ) : (
          <div>
            You are about to sign in as <strong>{displayName}</strong>{" "}
            {email ? `[${email}]` : ""}.
            <br />
            <br />
            Do you have permission from this user to sign in to their account?
            <br />
            <br />
            Copy and paste this link in an incognito window to sign in:
            <textarea style={textAreaStyle} defaultValue={this.link}></textarea>
          </div>
        )}
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  ...notificationsActions,
  ...usersActions,
};

export default connect(null, mapDispatchToProps)(UserToImpersonateModal);
