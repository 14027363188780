import React from "react";
import classNames from "classnames";
import "./index.scss";

function PixabayImageID(props) {
  const { blocked, id, onToggle } = props;

  const className = classNames("pixabay-image-id", {
    "pixabay-image-id--blocked": blocked,
  });

  const handleClick = (evt) => {
    onToggle(id);
    evt.preventDefault();
  };

  return (
    <div className={className}>
      <span className="pixabay-image-id__label">
        ID: <strong>{id}</strong>
      </span>
      <button className="pixabay-image-id__button" onClick={handleClick}>
        {blocked ? "Unblock" : "Block"}
      </button>
    </div>
  );
}

export default PixabayImageID;
