import React, { Component } from "react";
import classNames from "classnames";
import MiniMenu from "../../../components/ui/mini-menu";
import SvgIcon from "../../../components/ui/svg-icon";
import SettingsIcon from "../../../../images/svg/settings.svg";
import "./index.scss";

export default class AnalyticsSettings extends Component {
  state = {
    open: false,
    timeout: null,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleToggleClick = (e) => {
    this.setState({
      open: !this.state.open,
    });
    e.stopPropagation();
    e.preventDefault();
  };

  closeMenu = () => {
    if (this._isMounted) {
      this.setState({
        open: false,
      });
    }
  };

  getPosition = () => {
    return {
      x: this._container.offsetLeft,
      y: this._container.offsetTop,
    };
  };

  handleLabelClick = (e) => {
    e.stopPropagation();
  };

  render() {
    const { compact, textonly, onChange, hiddenCharts } = this.props;

    const { open } = this.state;

    const containerClass = classNames("analytics-settings", {
      "analytics-settings--open": open,
    });

    const options = [
      {
        name: "Teachers",
        key: "teacherCount",
      },
      {
        name: "Students",
        key: "studentCount",
      },
      {
        name: "Libraries created",
        key: "libraryCount",
      },
      {
        name: "Books created",
        key: "bookCount",
      },
      {
        name: "Active users",
        key: "activeUsers",
      },
      {
        name: "Time using Book Creator",
        key: "totalSessionTime",
      },
      {
        name: "Total books created",
        key: "historicBookCount",
      },
      {
        name: "Sessions by OS",
        key: "sessionsByOS",
      },
      {
        name: "Logins by provider",
        key: "loginsByProvider",
      },
    ];

    return (
      <div
        className={containerClass}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        ref={(c) => (this._container = c)}
      >
        <button
          onClick={this.handleToggleClick}
          className="analytics-settings__toggle"
        >
          <SvgIcon icon={SettingsIcon} name="settings" />
        </button>
        {open ? (
          <MiniMenu
            top
            left
            compact={compact}
            textonly={textonly}
            width={250}
            onClose={this.closeMenu}
            onItemClick={this.closeMenu}
          >
            <div className="analytics-settings__items">
              {options.map((option) => {
                const { key, name } = option;
                const hidden = hiddenCharts.includes(key);
                return (
                  <label
                    onClick={this.handleLabelClick}
                    key={key}
                    className="analytics-settings__item"
                  >
                    <input
                      type="checkbox"
                      checked={!hidden}
                      onChange={(e) => onChange(key)}
                    />
                    {name}
                  </label>
                );
              })}
            </div>
          </MiniMenu>
        ) : null}
      </div>
    );
  }
}
