import React, { useState } from "react";
import DomainEditor from "./domain-editor";
import DomainItem from "./item";
import "./index.scss";

export enum OrgUserType {
  TEACHER = "TEACHER",
  STUDENT = "STUDENT",
  ALL = "ALL",
}
export type OrgDomainTestCase = {
  expectedType: OrgUserType;
  email: string;
  valid?: boolean;
};

export type OrgDomain = {
  id?: string;
  domain: string;
  local: string;
  type: OrgUserType;
  testCases?: OrgDomainTestCase[];
};

interface StudentDomainsProps {
  editable?: boolean;
  orgEditMode?: boolean;
  domains?: OrgDomain[];
  setDomains: (domains: OrgDomain[]) => void;
}

export default function StudentDomains(props: StudentDomainsProps) {
  const { domains = [], editable, setDomains, orgEditMode } = props;

  const [editing, setEditing] = useState(false);

  return (
    <div className="student-domains">
      <h2 className="student-domains__title">
        User domains
        {editable ? (
          <button
            onClick={() => setEditing(true)}
            type="button"
            className="student-domains__add">
            {domains.length > 0 ? "Edit" : "Add"}
          </button>
        ) : null}
      </h2>
      <div className="student-domains__section">
        <>
          {domains.length > 0 ? (
            <div className="student-domains__list">
              {domains
                .sort((a, b) => a.domain.localeCompare(b.domain))
                .map(item => (
                  <DomainItem {...item} readonly />
                ))}
            </div>
          ) : (
            <div className="student-domains__empty">No domains added</div>
          )}
        </>
        {editing ? (
          <DomainEditor
            orgEditMode={orgEditMode}
            domains={domains}
            onSave={setDomains}
            onClose={() => setEditing(false)}
            addMode={domains.length === 0}
          />
        ) : null}
      </div>
    </div>
  );
}
