import React, { Component } from "react";
import classNames from "classnames";
import { closestParent } from "../../../../utils";
import "./index.scss";

class MiniMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      timeout: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const timeout = this.props.pinned ? 0 : 50;
    setTimeout(() => {
      if (this._isMounted) {
        this.setState({
          visible: true,
        });
      }
    }, timeout);
    // if(isTouch){
    // 	document.addEventListener("touchstart", this.handleMouseDown);
    // }else{
    document.addEventListener("mousedown", this.handleMouseDown);
    // }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.clearTimeout(this.state.timeout);
    document.removeEventListener("mousedown", this.handleMouseDown);
    document.removeEventListener("touchstart", this.handleMouseDown);
  }

  handleMouseDown = (e) => {
    if (
      !closestParent(e.target, ".mini-menu") &&
      !closestParent(e.target, ".button-menu--active", true) &&
      !closestParent(e.target, ".published-badge", true) &&
      !closestParent(e.target, ".page-control", true) &&
      !closestParent(e.target, ".analytics-settings", true) &&
      !closestParent(e.target, ".mini-account__toggle", true) &&
      !this.props.dontHide
    ) {
      this.props.onClose();
    }
  };

  handleMouseLeave = () => {
    if (
      !this.state.timeout &&
      !this.props.dontHide &&
      this.props.hideAfterMouseLeave
    ) {
      this.setState({
        timeout: window.setTimeout(() => {
          if (this._isMounted) {
            this.props.onClose();
          }
        }, 2000),
      });
    }
  };

  handleMouseEnter = () => {
    window.clearTimeout(this.state.timeout);
    this.setState({
      timeout: null,
    });
  };

  getPosition() {
    const { top, right, bottom, left, inside, position, dropdown } = this.props;

    let p;

    if (dropdown) {
      p = {
        top: "100%",
        left: "0",
        marginTop: 4,
      };
    } else {
      p = {
        top: position
          ? position.y
          : top
          ? inside
            ? 0
            : "100%"
          : bottom
          ? null
          : 0,
        right: position
          ? null
          : inside
          ? right
            ? 0
            : null
          : right
          ? null
          : "100%",
        bottom: position ? null : bottom ? "100%" : null,
        left: position
          ? position.x
          : inside
          ? left
            ? 0
            : null
          : left
          ? null
          : "100%",
        marginLeft: inside || position ? null : right ? 14 : left ? -14 : null,
        marginBottom: bottom && !position ? 10 : 0,
        marginTop: top && !position && !inside ? 10 : 0,
      };
    }

    // Don't let menu show off screen
    if (this._isMounted && position) {
      const MENU_MARGIN_FROM_EDGE = 5;
      const height = this._container.offsetHeight;
      const width = this._container.offsetWidth;
      const screenBottom = window.innerHeight;
      const screenRight = window.innerWidth;
      if (p.top + height > screenBottom) {
        p.top = screenBottom - height - MENU_MARGIN_FROM_EDGE;
      }
      if (p.left + width > screenRight) {
        p.left = screenRight - width - MENU_MARGIN_FROM_EDGE;
      }
    }

    return p;
  }

  render() {
    const {
      children,
      top,
      right,
      bottom,
      left,
      onItemClick,
      width,
      beak,
      inside,
      center,
      compact,
      textonly,
      position,
      origin,
      dropdown,
    } = this.props;

    const { visible } = this.state;

    const cssClass = classNames("mini-menu", {
      "mini-menu--visible": visible,
      "mini-menu--compact": compact,
      "mini-menu--textonly": textonly,
      "mini-menu--dropdown": dropdown,
    });

    const menuStyle = {
      ...this.getPosition(),
      width: width ? width : null,
    };

    let frameOrigin;

    if (origin) {
      frameOrigin = origin;
    } else {
      frameOrigin =
        (inside && left) || (!inside && right) || position
          ? "0"
          : center
          ? "50%"
          : "100%";
      if (bottom) {
        frameOrigin += " 100%";
      } else {
        frameOrigin += " 0";
      }
    }

    const frameStyle = {
      top: top ? 0 : bottom ? null : 0,
      right: right ? 0 : null,
      bottom: bottom ? 0 : null,
      left: left ? 0 : null,
      transformOrigin: frameOrigin,
      WebkitTransformOrigin: frameOrigin,
      width: width ? width : null,
    };

    return (
      <div
        className={cssClass}
        style={menuStyle}
        onClick={(e) => {
          e.stopPropagation();
          if (onItemClick) {
            onItemClick(e);
          }
        }}
        onMouseLeave={this.handleMouseLeave.bind(this)}
        onMouseEnter={this.handleMouseEnter.bind(this)}
        ref={(c) => (this._container = c)}
      >
        <div className="mini-menu__frame" style={frameStyle}></div>
        <div className="mini-menu__content">
          {beak ? <div className="mini-menu__beak" /> : null}
          {children}
        </div>
      </div>
    );
  }
}

export default MiniMenu;
