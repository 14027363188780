import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ANALYTICS_DOMAIN } from "../../../../../config";
import Loader from "../../../ui/loader";
import { nFormatter } from "../../../../../utils/number";
import classNames from "classnames";
import SvgIcon from "../../../ui/svg-icon";
import useFetch from "use-http";
import "./index.scss";

const Stat = props => {
  const {
    domain,
    endpoint,
    icon,
    iconName,
    loading,
    title,
    value,
    auth,
  } = props;

  const [endpointLoading, setEndpointLoading] = useState(
    endpoint !== undefined
  );
  const [endpointResult, setEndpointResult] = useState();

  const { get, response, error } = useFetch(
    `${ANALYTICS_DOMAIN}/users/domains/${encodeURIComponent(domain)}`,
    {
      credentials: "include",
    }
  );

  useEffect(() => {
    const { expiresAt, userUrls, getAuth } = auth;
    const resourceAuthExpired = expiresAt <= Date.now();
    const isAuthenticated = expiresAt && !resourceAuthExpired;

    const getData = async () => {
      const data = await get(`${endpoint}?${userUrls}`);
      setEndpointLoading(false);
      if (response.ok) {
        setEndpointResult(data);
      }
    };
    if (!isAuthenticated) {
      return getAuth();
    }
    if (endpoint) {
      getData();
    }
  }, [endpoint, get, response, auth]);

  const isLoading = endpoint ? endpointLoading : loading;
  const _value = endpoint ? endpointResult : value;

  const isTBC = value === "TBC";

  const className = classNames("stat", {
    "stat--tbc": isTBC,
  });

  return (
    <div className={className}>
      <div className="stat__header">
        <div className="stat__icon">
          <SvgIcon icon={icon} name={iconName} />
        </div>
        <span className="stat__title">{title}</span>
      </div>
      {!error ? (
        <span className="stat__value">
          {isLoading ? <Loader tiny /> : <span>{nFormatter(_value, 1)}</span>}
        </span>
      ) : (
        <span className="stat__error">Loading error, please try again</span>
      )}
    </div>
  );
};

Stat.propTypes = {
  icon: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Stat.defaultProps = {
  value: 0,
};

export default Stat;
