import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./index.scss";

function Message(props) {
  const { type, center, large, children, message, style } = props;

  const cssClass = classNames("message", {
    "message--error": type === "error",
    "message--center": center,
    "message--large": large,
  });

  return (
    <div className={cssClass} style={style}>
      <p className="message__content">{message}</p>
      {children}
    </div>
  );
}

Message.propTypes = {
  type: PropTypes.string,
  center: PropTypes.bool,
  large: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  message: PropTypes.string,
  style: PropTypes.object,
};

export default Message;
