import React, { Fragment, useState } from "react";
import InlineButton from "../../../../../ui/inline-button";
import Extend from "./extend";
import moment from "moment";
import "./index.scss";
const DATE_FORMAT = "Do MMM YYYY";
const COLLABORATION_TRIAL_LENGTH_DAYS = 90;

function Collaboration(props) {
  const {
    addErrorMessage,
    ended,
    ends,
    serverTime,
    started,
    uid,
    updateUserValueInStore,
  } = props;

  const [showExtend, setShowExtend] = useState(false);

  let timeLeftInMS;
  let timeLeftInDays;
  let trialEnds;
  let hasEnded;

  const currentTime = moment(serverTime).set({
    hour: 23,
    minute: 59,
    second: 58,
    millisecond: 0,
  });

  if (ends) {
    timeLeftInMS = ends - serverTime;
    timeLeftInDays = Math.floor(timeLeftInMS / 1000 / 60 / 60 / 24);
    trialEnds = moment(ends).set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 0,
    });

    hasEnded = trialEnds && trialEnds.isBefore(currentTime);
  } else if (started) {
    const endDate =
      started + COLLABORATION_TRIAL_LENGTH_DAYS * 24 * 60 * 60 * 1000;
    timeLeftInMS = endDate - serverTime;
    timeLeftInDays = Math.floor(timeLeftInMS / 1000 / 60 / 60 / 24);
    hasEnded = ended;
  }

  return (
    <Fragment>
      <dt>Collaboration trial:</dt>
      <dd className="collaboration-value">
        {showExtend ? (
          <Extend
            addErrorMessage={addErrorMessage}
            onClose={() => setShowExtend(false)}
            trialLength={COLLABORATION_TRIAL_LENGTH_DAYS}
            serverTime={serverTime}
            uid={uid}
            updateUserValueInStore={updateUserValueInStore}
          />
        ) : null}
        {!ends && !started ? <span>None</span> : null}
        {hasEnded ? (
          <span>Ended {moment(trialEnds).format(DATE_FORMAT)}</span>
        ) : null}
        {(ends || started) && !hasEnded ? (
          <span>{timeLeftInDays} days left</span>
        ) : null}
        <InlineButton onClick={() => setShowExtend(true)}>Change</InlineButton>
      </dd>
    </Fragment>
  );
}

export default Collaboration;
