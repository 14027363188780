import React, { Component } from "react";
import Input from "./input";
import uuid from "node-uuid";

class TextArea extends Component {
  state = {
    id: uuid.v4(),
  };

  componentDidMount() {
    const { autoFocus } = this.props;
    if (autoFocus) {
      this.focus();
    }
  }

  focus = () => {
    document.getElementById(this.id).focus();
  };

  get id() {
    return this.props.id ? this.props.id : this.state.id;
  }

  render() {
    const {
      onFocus,
      onBlur,
      onChange,
      onPaste,
      value,
      name,
      maxLength,
      height,
      innerRef,
      disabled,
    } = this.props;

    const style = {
      height,
    };

    const opts = {
      onFocus,
      onBlur,
      onChange,
      onPaste,
      id: this.id,
      style,
    };

    return (
      <textarea
        {...opts}
        className="input__textarea"
        ref={innerRef ? innerRef : c => (this._input = c)}
        value={value}
        name={name}
        maxLength={maxLength}
        onChange={e => onChange(e.target.value)}
        disabled={disabled}
      />
    );
  }
}

export default Input(TextArea);
