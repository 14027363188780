import React, { Component } from "react";
import "./index.scss";

export default class UserToDelete extends Component {
  render() {
    const { userToDelete } = this.props;

    const { errorMessage, errors } = userToDelete;

    return (
      <div className="user-to-delete">
        Are you sure you want to delete this account? This is permanent and
        can't be undone.
        {errorMessage ? (
          <p className="user-to-delete__message">{errorMessage}</p>
        ) : null}
        {errors ? (
          <ul className="user-to-delete__errors">
            {errors.map((error, i) => {
              return <li key={i}>{error.message}</li>;
            })}
          </ul>
        ) : null}
      </div>
    );
  }
}
