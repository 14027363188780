import React from "react";
import classNames from "classnames";
import "./index.scss";

function Table(props) {
  const { title, rows, striped } = props;
  const className = classNames("data-table", {
    "data-table--striped": striped,
  });
  return (
    <div className={className}>
      {title ? <h2 className="data-table__title">{title}</h2> : null}
      <div className="data-table__content">
        {rows.map((row, i) => {
          if (row.title) {
            return (
              <div className="data-table__row" key={i}>
                <div className="data-table__row-title">{row.title}</div>
                <div className="data-table__row-data">
                  {typeof row.data === "function" ? row.data() : row.data}
                </div>
              </div>
            );
          } else {
            return (
              <div className="data-table__row data-table__row--custom" key={i}>
                {row}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default Table;
