import { replaceAll } from "../../../../utils/string";

export const getDomainArray = domains => {
  let processed = replaceAll(domains, ",", "\n");
  processed = replaceAll(processed, ";", "\n");
  processed = replaceAll(processed, "@", "");
  processed = processed.split("\n");
  let domainArray = processed
    .filter(domain => domain.trim().length)
    .map(domain => domain.trim());
  domainArray = domainArray
    .map(domain => {
      return domain.trim();
    })
    .filter(domain => {
      return domain; // TODO: isValidDomain(domain);
    })
    .map(domain => {
      return domain.toLowerCase();
    });
  return domainArray;
};

export const isValidDomain = domain => {
  const re = new RegExp(
    /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/gi
  );
  return domain.match(re);
};

export const isSameDomain = (domainA, domainB) => {
  return (
    domainA?.domain.toLowerCase() === domainB?.domain.toLowerCase() &&
    (domainA.local === domainB.local || (!domainB.local && !domainA.local))
  );
};
