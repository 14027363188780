import React from "react";
import { firebaseDbApps } from "../../../../../../core/firebase";
import Result from "./result";
import "./index.scss";

function NounProjectSearchResults(props) {
  const { blockedIDs, blockedSlugs, results } = props;

  const handleToggleSlug = ({ id, value }) => {
    if (value) {
      firebaseDbApps.ref(`/blocking/tags`).push(value);
    } else {
      firebaseDbApps.ref(`/blocking/tags/${id}`).set(null);
    }
  };

  const handleToggleId = ({ id, value }) => {
    if (value) {
      firebaseDbApps.ref(`/blocking/g/noun/ids`).push(value);
    } else {
      firebaseDbApps.ref(`/blocking/g/noun/ids/${id}`).set(null);
    }
  };

  return (
    <div className="noun-search-results">
      {results.map(result => {
        return (
          <Result
            {...result}
            blockedIDs={blockedIDs}
            blockedSlugs={blockedSlugs}
            onToggleSlug={handleToggleSlug}
            onToggleId={handleToggleId}
            key={result.id}
          />
        );
      })}
    </div>
  );
}

export default NounProjectSearchResults;
