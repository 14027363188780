import React, { Component } from "react";
import "./success.scss";

export default class DowngradeSuccess extends Component {
  render() {
    return (
      <div className="downgrade-success">
        <h1 className="downgrade-success__title">Downgrade successful</h1>
      </div>
    );
  }
}
