import React from "react";
import { BOOKCREATOR_ASSETS_URL } from "../../../config";
import NavItem from "./item";
import AvatarIcon from "../../../images/svg/user.svg";
import SvgIcon from "../../components/ui/svg-icon";
import routes from "./routes";
import "./index.scss";

export default function Nav(props) {
  const { hasEngineerPrivileges, signOut, auth, location } = props;

  const displayName = auth.get("displayName");
  const email = auth.get("email");
  const uid = auth.get("id");
  const avatar = `${BOOKCREATOR_ASSETS_URL}/${uid}/avatar`;

  const navItems = routes
    .filter(route => !route.engineerOnly || hasEngineerPrivileges)
    .map((route, i) => {
      return (
        <NavItem
          to={route.route}
          icon={route.icon}
          iconName={route.iconName}
          active={location.pathname.indexOf(route.route) > -1}
          childRoutes={route.childRoutes}
          location={location}
          disabled={route.disabled}
          key={i}>
          {route.name}
        </NavItem>
      );
    });

  const avatarStyle = {
    backgroundImage: avatar ? `url(${avatar})` : null,
  };

  return (
    <nav className="nav">
      <div className="nav__header">
        <div className="nav__avatar" style={avatarStyle}>
          {!avatar ? <SvgIcon icon={AvatarIcon} iconName="avatar" /> : null}
        </div>
        <div className="nav__user-details">
          <span className="nav__username">{displayName}</span>
          <span className="nav__email">{email}</span>
        </div>
      </div>
      {navItems}
      <button className="nav__item" type="button" onClick={signOut}>
        <span className="nav__item-button">Sign out</span>
      </button>
    </nav>
  );
}
