import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";

function Group(props) {
  const { name, users } = props;

  return (
    <div className="group">
      <span className="group__name">{name}</span>
      <div className="group__users">
        {users && users.length
          ? users.map((user) => {
              if (!user) {
                return "None";
              }
              return (
                <span className="group__user" key={user.id}>
                  <Link to={`/users/${user.id}`}>{user.displayName}</Link>
                  <small>
                    ({user.bookCount || 0}{" "}
                    {user.bookCount === 1 ? "book" : "books"})
                  </small>
                </span>
              );
            })
          : "None"}
      </div>
    </div>
  );
}

export default Group;
