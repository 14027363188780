import React, { Fragment, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { firebaseDbApps } from "../../../../core/firebase";
import { unwrap } from "../../../../utils/firebase";
import Helmet from "react-helmet";
import Header from "../../header";
import Content from "../../content";
import ListEditor from "../../list-editor";

const textSort = (a, b) => {
  if (a.text < b.text) {
    return -1;
  }
  if (a.text > b.text) {
    return 1;
  }
  return 0;
};

function BlockedImageTags(props) {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    firebaseDbApps.ref("/blocking/tags").on("value", snapshot => {
      setTags(unwrap(snapshot));
    });
    return () => {
      firebaseDbApps.ref("/blocking/tags").off();
    };
  }, []);

  const handleDeleteTag = key => {
    firebaseDbApps.ref(`/blocking/tags/${key}`).set(null);
  };

  const handleAddTag = tag => {
    const existingTag = tags.find(
      t => t.value.toLowerCase() === tag.toLowerCase()
    );
    if (!existingTag) {
      firebaseDbApps.ref(`/blocking/tags`).push(tag.toLowerCase());
    }
  };

  return (
    <Fragment>
      <Helmet title="Blocked Image Tags" />
      <Header title="Blocked Image Tags" />
      <Content>
        <ListEditor
          items={tags
            .map(tag => ({ id: tag.key, text: tag.value }))
            .sort(textSort)}
          onItemDelete={handleDeleteTag}
          onAddItem={handleAddTag}
        />
      </Content>
    </Fragment>
  );
}

export default withRouter(BlockedImageTags);
