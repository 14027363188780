import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { notificationsActions } from "../../../core/notifications";
import {
  analyticsActions,
  getAnalyticsResourceAuthExpiry,
  getAnalyticsSignedURLs,
  getUserAnalyticsSignedURLs,
} from "../../../core/analytics";
import Stats from "./stats";
import Header, { HeaderActions } from "../header";
import HeaderButton from "../button/header";
import Charts from "./charts";
import PrintView from "./print-view";
import "./index.scss";

function Analytics(props) {
  const {
    domain,
    analyticsResourceAuthExpiry,
    setResourceAuth,
    analyticsSignedURLs,
    userAnalyticsSignedURLs,
    resetResourceAuth,
  } = props;

  useEffect(() => {
    if (domain) {
      resetResourceAuth();
    }
  }, [domain, resetResourceAuth]);

  const [showPrintView, setShowPrintView] = useState(false);
  const [hiddenCharts, setHiddenCharts] = useState([]);

  const handlePrintClick = e => setShowPrintView(true);

  const handleSettingChange = chart => {
    if (hiddenCharts.includes(chart)) {
      const hc = hiddenCharts.filter(k => k !== chart);
      setHiddenCharts(hc);
    } else {
      setHiddenCharts([...hiddenCharts, chart]);
    }
  };

  return (
    <div className="analytics">
      {showPrintView ? (
        <PrintView
          auth={{
            expiresAt: analyticsResourceAuthExpiry,
            getAuth: setResourceAuth,
            urls: analyticsSignedURLs,
            userUrls: userAnalyticsSignedURLs,
          }}
          domain={domain}
          hiddenCharts={hiddenCharts}
          onClose={() => setShowPrintView(false)}
          onSettingChange={handleSettingChange}
        />
      ) : null}
      <Header title="Analytics" fixed>
        <HeaderActions right>
          <HeaderButton onClick={handlePrintClick}>Save to PDF</HeaderButton>
        </HeaderActions>
      </Header>
      <div className="analytics__main">
        <Stats
          domain={domain}
          auth={{
            expiresAt: analyticsResourceAuthExpiry,
            getAuth: setResourceAuth,
            userUrls: userAnalyticsSignedURLs,
          }}
        />
        <Charts
          domain={domain}
          auth={{
            expiresAt: analyticsResourceAuthExpiry,
            getAuth: setResourceAuth,
            urls: analyticsSignedURLs,
            userUrls: userAnalyticsSignedURLs,
          }}
        />
      </div>
    </div>
  );
}

const mapStateToProps = createSelector(
  getAnalyticsResourceAuthExpiry,
  getAnalyticsSignedURLs,
  getUserAnalyticsSignedURLs,
  (
    analyticsResourceAuthExpiry,
    analyticsSignedURLs,
    userAnalyticsSignedURLs
  ) => ({
    analyticsResourceAuthExpiry,
    analyticsSignedURLs,
    userAnalyticsSignedURLs,
  })
);

const mapDispatchToProps = {
  ...notificationsActions,
  ...analyticsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
