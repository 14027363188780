import React, { Component } from "react";
import Loader from "../../ui/loader";
import "./index.scss";

export default class FormLoading extends Component {
  render() {
    return (
      <div className="form-loading">
        <Loader />
      </div>
    );
  }
}
