import React, { Component } from "react";
import Code from "./code";
import "./index.scss";

export default class PromoCodeResults extends Component {
  render() {
    const { codes, onCodeError, onCodeLoad } = this.props;
    let codeComponents = [];
    codes.forEach((code, i) => {
      if (!code.failed) {
        codeComponents.push(
          <Code
            key={code.id}
            {...code}
            onError={onCodeError.bind(this, i)}
            onLoad={onCodeLoad.bind(this, i)}
          />
        );
      }
    });
    return <div className="promo-code-results">{codeComponents}</div>;
  }
}
