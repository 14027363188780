export function closestParent(el, selector, includeSelf) {
  var parent = el.parentNode;

  if (includeSelf && el.matches(selector)) {
    return el;
  }

  while (parent && parent !== document.body) {
    if (parent.matches && parent.matches(selector)) {
      return parent;
    } else if (parent.parentNode) {
      parent = parent.parentNode;
    } else {
      return null;
    }
  }
  return null;
}

export function getInitialsFromName(name) {
  if (!name) {
    return "";
  }
  const names = name.split(" ");
  let initials = [];
  if (names.length) {
    initials.push(names[0][0]);
  }
  if (names.length > 1) {
    initials.push(names[names.length - 1][0]);
  }
  return initials.join("");
}

let timer;
export function debounce(func, delay) {
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
