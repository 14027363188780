import React, { useState } from "react";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import Modal from "../../../../../modal";
import { firebaseDb } from "../../../../../../../core/firebase";
import { BOOKCREATOR_API_URL } from "../../../../../../../config";

function VerifyEmailOverlay(props) {
  const {
    uid,
    //
    addErrorMessage,
    onClose,
    onComplete,
  } = props;

  const [saving, setSaving] = useState(false);

  const unlistenForUpdates = () => {
    firebaseDb.ref(`/updateUsers/${uid}`).off();
  };

  const listenForUpdates = () => {
    firebaseDb.ref(`/updateUsers/${uid}`).on("value", snapshot => {
      if (!snapshot.val()) {
        unlistenForUpdates();
        onComplete();
        onClose();
        setSaving(false);
      } else if (snapshot.val().failed) {
        unlistenForUpdates();
        addErrorMessage(snapshot.val().failed.error.message);
        setSaving(false);
      }
    });
  };

  const handleConfirm = async evt => {
    setSaving(true);
    listenForUpdates();
    try {
      const response = await fetch(
        `${BOOKCREATOR_API_URL}/admin-tools/v2/${uid}/users/email/verify`,
        {
          method: "POST",
          credentials: "include",
        }
      );
      if (response.status !== 200) {
        throw new Error();
      }
    } catch (err) {
      unlistenForUpdates();
    }
  };

  return createPortal(
    <Modal
      title="Verify user's email?"
      modal
      onCancel={onClose}
      onConfirm={handleConfirm}
      confirmButtonDisabled={saving}
      confirmText="Verify">
      <p>Are you sure this email has been verified?</p>
    </Modal>,
    document.getElementsByTagName("body")[0]
  );
}

VerifyEmailOverlay.propTypes = {
  uid: PropTypes.string.isRequired,
  addErrorMessage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default VerifyEmailOverlay;
