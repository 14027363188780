import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InlineButton from "../../../../../ui/inline-button";
import Loader from "../../../../../ui/loader";
import { firebaseDb } from "../../../../../../../core/firebase";
import "./index.scss";

function ManagedStudents(props) {
  const { addErrorMessage, uid, updateUserValueInStore, students } = props;

  const [originalStudents, setOriginalStudents] = useState(null);
  const [hovered, setHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const visibleStudents = Object.values(students).filter(value => !value);
  const hiddenStudents = Object.values(students).filter(value => value);

  useEffect(() => {
    if (!originalStudents) {
      setOriginalStudents(students);
    }
  }, [students, originalStudents]);

  const flipStudents = async nextValue => {
    setLoading(true);
    const studentUpdate = [];
    Object.entries(students).forEach(([key, value]) => {
      if (value !== nextValue) studentUpdate.push(key);
    });
    do {
      const up = Object.fromEntries(
        studentUpdate.splice(0, 100).map(s => [s, nextValue])
      );
      try {
        await firebaseDb.ref(`/users/${uid}/managedStudents`).update(up);
      } catch (err) {
        addErrorMessage(
          err?.message || "Something went wrong updating the user"
        );
        setLoading(false);
        return;
      }
    } while (studentUpdate.length > 0);
    let storeUpdate = Object.fromEntries(
      Object.keys(students).map(key => [key, nextValue])
    );
    setHasChanged(true);
    updateUserValueInStore({
      uid,
      location: "managedStudents",
      value: storeUpdate,
    });
    setLoading(false);
  };

  const showAll = () => flipStudents(false);
  const hideAll = () => flipStudents(true);

  const revert = async () => {
    setLoading(true);
    const studentUpdate = [];
    Object.entries(originalStudents).forEach(([key, value]) => {
      studentUpdate.push([key, value]);
    });
    do {
      const up = Object.fromEntries(studentUpdate.splice(0, 100));
      try {
        await firebaseDb.ref(`/users/${uid}/managedStudents`).update(up);
      } catch (err) {
        addErrorMessage(
          err?.message || "Something went wrong updating the user"
        );
        setLoading(false);
        return;
      }
    } while (studentUpdate.length > 0);
    setHasChanged(false);
    updateUserValueInStore({
      uid,
      location: "managedStudents",
      value: originalStudents,
    });
    setLoading(false);
  };

  const getStudentLabel = studentCount =>
    studentCount === 1 ? "student" : "students";

  return (
    <div
      className="result-card-managed-students"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      {loading ? (
        <div className="result-card-managed-students__loading">
          <Loader center small />
        </div>
      ) : null}
      <div className="result-card-managed-students__counts">
        <span className="result-card-managed-students__count">
          {visibleStudents.length} visible{" "}
          {getStudentLabel(visibleStudents.length)}{" "}
        </span>
        <span className="">
          {hiddenStudents.length} hidden{" "}
          {getStudentLabel(hiddenStudents.length)}{" "}
        </span>
      </div>
      {hovered ? (
        <div className="result-card-managed-students__actions">
          {hiddenStudents.length > 0 ? (
            <InlineButton onClick={showAll}>Show all</InlineButton>
          ) : null}
          {visibleStudents.length > 0 ? (
            <InlineButton onClick={hideAll}>Hide all</InlineButton>
          ) : null}
          {hasChanged ? (
            <InlineButton onClick={revert}>Revert</InlineButton>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

ManagedStudents.propTypes = {
  students: PropTypes.object.isRequired,
  addErrorMessage: PropTypes.func.isRequired,
  uid: PropTypes.string.isRequired,
  updateUserValueInStore: PropTypes.func.isRequired,
};

export default ManagedStudents;
