import React, { Component } from "react";
import { connect } from "react-redux";
import flow from "lodash.flow";
import { withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import { Redirect } from "react-router-dom";
import {
  isAuthenticated,
  getAuth,
  authActions,
  getIsAdmin,
} from "../../core/auth";
import {
  getNotifications,
  notificationsActions,
} from "../../core/notifications";
import { NotificationStack } from "react-notification";
import Main from "./main";
import Nav from "../components/nav/container";
import "./index.scss";

class App extends Component {
  componentWillReceiveProps(nextProps) {
    const {
      auth,
      organisationLoaded,
      isAdmin,
      signOut,
      signInError,
    } = nextProps;
    if (auth.initialised && organisationLoaded && !isAdmin) {
      signOut({ preserveError: true });
      signInError({ message: "You do not have an account on this site" });
      const { router } = this.context;
      router.history.replace(`/sign-in`);
    }
  }

  render() {
    const {
      authenticated,
      auth,
      store,
      allNotifications,
      removeNotification,
    } = this.props;

    if (!authenticated) {
      return <Redirect to="/sign-in" />;
    }

    const notifications = allNotifications.map(notification => {
      notification.onClick = removeNotification.bind(this, notification.key);
      return notification;
    });

    return (
      <div className="book-creator-mission-control" data-id={auth.id}>
        <Nav auth={auth} />
        <Main store={store} />
        <NotificationStack
          notifications={notifications.toArray()}
          onDismiss={notification => {}}
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  isAuthenticated,
  getAuth,
  getNotifications,
  getIsAdmin,
  (authenticated, auth, allNotifications, isAdmin) => ({
    authenticated,
    auth,
    allNotifications,
    isAdmin,
  })
);

const mapDispatchToProps = {
  ...notificationsActions,
  ...authActions,
};

const decorators = flow([
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
]);

export default decorators(App);
