import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/performance";
import "firebase/storage";
import {
  FIREBASE_CONFIG,
  FIREBASE_DATABASE_APPS_URL,
  FIREBASE_DATABASE_BLOCKS_URL,
  FIREBASE_DATABASE_DELETED_ORGS_URL,
} from "../../config";
export const firebaseApp = firebase.initializeApp(FIREBASE_CONFIG);
export const firebaseAuth = firebaseApp.auth();
export const firebaseDb = firebaseApp.database();
export const firebaseFirestore = firebaseApp.firestore();
export const firebaseDbApps = firebaseApp.database(FIREBASE_DATABASE_APPS_URL);
export const firebaseDbBlocks = firebaseApp.database(
  FIREBASE_DATABASE_BLOCKS_URL
);
export const firebaseDbDeletedOrgs = firebaseApp.database(
  FIREBASE_DATABASE_DELETED_ORGS_URL
);
export const firebaseDbCertification = firebaseApp.database();
export const firebaseStorage = firebaseApp.storage();
