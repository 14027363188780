import React, { Fragment } from "react";
import classNames from "classnames";
import URL from "./url";
import "./index.scss";

function GoogleImageSearchResult(props) {
  const { blockedURLs, link, onToggleURL, obscureblocked } = props;

  const blockedUrl = blockedURLs.find((blockedURL) => blockedURL.link === link);

  const className = classNames("image-search-result", {
    "image-search-result--blocked": blockedUrl && obscureblocked,
  });

  return (
    <div className={className}>
      <div className="image-search-result__preview">
        <img className="image-search-result__image" src={link} alt="" />
        {blockedUrl && obscureblocked ? (
          <Fragment>
            <img
              src="https://storage.bookcreator.com/static/emoji/1f6ab.svg"
              alt=""
              className="image-search-result__emoji"
            />
          </Fragment>
        ) : null}
      </div>
      <URL
        blocked={blockedUrl}
        url={link}
        fbKey={blockedUrl ? blockedUrl.key : null}
        onToggle={onToggleURL}
      />
    </div>
  );
}

GoogleImageSearchResult.defaultProps = {
  blockedURLs: [],
};

export default GoogleImageSearchResult;
