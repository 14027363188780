import React, { useState } from "react";
import { BOOKCREATOR_ASSETS_URL } from "../../../../../config";
import { downgradeActions } from "../../../../../core/downgrade";
import { notificationsActions } from "../../../../../core/notifications";
import { usersActions } from "../../../../../core/users";
import { connect } from "react-redux";
import FlatButton from "../../../button/flat";
import AllUserData from "../all-data";
import Header from "./header";
import Body from "./body";
import ConvertToTeacherModal from "./convert-to-teacher";
import "./index.scss";

function ResultCard(props) {
  const {
    addErrorMessage,
    addNotification,
    checkUserToDelete,
    refreshUser,
    result,
    serverTime,
    setShowDeleteUserModal,
    setShowDowngradeModal,
    setShowImpersonateUserModal,
    setUserToDowngrade,
    updateUser,
    updateUserValueInStore,
  } = props;

  const [showAllData, setShowAllData] = useState(false);
  const [showUpgrade, setShowUpgrade] = useState(false);

  const handleDowngradeClick = evt => {
    const { id } = result;
    setShowDowngradeModal(true);
    setUserToDowngrade(id);
    evt.preventDefault();
  };

  const handleDeleteClick = evt => {
    const { uid, email } = result.auth;
    setShowDeleteUserModal(true);
    checkUserToDelete({ uid, email });
  };

  const handleExpertChange = ({ key, value }) => {
    const { auth, expert = {} } = result;
    const { uid } = auth;
    let update = {
      uid,
      location: "expert",
      value: {
        ...expert,
        [key]: value || null,
      },
    };
    // Update expert/ambassador and isAmbassador until all properties are
    // reading from newer expert/ambassador
    if (key === "ambassador") {
      updateUser({
        uid,
        location: "isAmbassador",
        value: value || null,
      });
    }
    updateUser(update);
  };

  if (!result) {
    return null;
  }
  const {
    auth,
    adminOnly,
    badges,
    displayName,
    email,
    expert,
    isAmbassador,
    isTeacher,
    libraries,
    loginProvider,
    collaborationTrialEnded,
    collaborationTrialEnds,
    collaborationTrialStarted,
    organisations,
    bookQuota,
    lastTeacherToStudentConversion,
    libraryQuota,
    managedStudents,
    preferences,
    stripe,
  } = result;

  const { disabled, emailVerified, metadata, providerData, uid } = auth;

  const avatarUrl = `${BOOKCREATOR_ASSETS_URL}/${uid}/avatar`;
  const isSkoletubeOrBornetubeUser =
    loginProvider &&
    typeof loginProvider === "object" &&
    (loginProvider["skoletube:dk"] || loginProvider["bornetube:dk"]);

  return (
    <div className="result-card">
      <Header
        addErrorMessage={addErrorMessage}
        avatarUrl={avatarUrl}
        disabled={disabled}
        displayName={displayName || auth.displayName}
        email={email}
        uid={uid}
        setShowImpersonateUserModal={setShowImpersonateUserModal}
        updateUserValueInStore={updateUserValueInStore}
      />
      <Body
        adminOnly={adminOnly}
        auth={auth}
        badges={badges}
        bookQuota={bookQuota}
        collaborationTrialEnded={collaborationTrialEnded}
        collaborationTrialEnds={collaborationTrialEnds}
        collaborationTrialStarted={collaborationTrialStarted}
        email={email}
        emailVerified={emailVerified}
        expert={expert}
        isAmbassador={isAmbassador}
        isTeacher={isTeacher}
        lastTeacherToStudentConversion={lastTeacherToStudentConversion}
        libraries={libraries}
        libraryQuota={libraryQuota}
        loginProvider={loginProvider}
        metadata={metadata}
        organisations={organisations}
        providerData={providerData}
        refreshUser={refreshUser}
        uid={uid}
        addErrorMessage={addErrorMessage}
        addNotification={addNotification}
        handleExpertChange={handleExpertChange}
        serverTime={serverTime}
        preferences={preferences}
        onShowAllData={() => setShowAllData(true)}
        updateUserValueInStore={updateUserValueInStore}
        managedStudents={managedStudents}
        stripe={stripe}
      />
      <div className="result-card__actions">
        {isTeacher ? (
          <FlatButton onClick={handleDowngradeClick}>
            Downgrade to student
          </FlatButton>
        ) : null}
        {!isTeacher && isSkoletubeOrBornetubeUser ? (
          <FlatButton onClick={() => setShowUpgrade(true)}>
            Convert to teacher
          </FlatButton>
        ) : null}
        <FlatButton onClick={handleDeleteClick} type="negative">
          Delete
        </FlatButton>
      </div>
      {showAllData ? (
        <AllUserData data={result} onClose={() => setShowAllData(false)} />
      ) : null}

      {showUpgrade ? (
        <ConvertToTeacherModal
          onClose={() => setShowUpgrade(false)}
          email={auth.email}
          userId={uid}
          updateUserValueInStore={updateUserValueInStore}
        />
      ) : null}
    </div>
  );
}

const mapDispatchToProps = {
  ...downgradeActions,
  ...notificationsActions,
  ...usersActions,
};

export default connect(null, mapDispatchToProps)(ResultCard);
