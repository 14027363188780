import { Record, List, Map } from "immutable";

import {
  GET_ORGANISATIONS_RESULT,
  SET_SORT_BY,
  RESET_ORGANISATIONS,
  ORG_ADDED,
  ORG_CHANGED,
  ORG_REMOVED,
  SET_EXISTING_TEACHER_LICENSE_ERROR,
  SET_ORG_SAVING,
  SET_NEW_ORG_ERROR,
  SET_DOMAIN_LICENSE_RETRY,
  SET_DOMAIN_CONFIRMATIONS,
} from "./action-types";

import { SIGN_OUT_SUCCESS } from "../auth";

const defaultSortBy = "created_at.DESC";

export const OrganisationsState = new Record({
  organisations: new List(),
  initialised: false,
  totalCount: 0,
  saving: false,
  addNewOrgError: false,
  existingTeacherLicenseError: false,
  sortBy: defaultSortBy,
  defaultSortBy,
  domainLicenseRetry: null,
  domainConfirmations: null,
});

export function organisationsReducer(
  state = new OrganisationsState(),
  { payload, type, totalCount }
) {
  switch (type) {
    case GET_ORGANISATIONS_RESULT:
      return state.merge({
        organisations: payload,
        totalCount,
        initialised: true,
      });
    case ORG_ADDED:
      return state.merge({
        organisations: state.organisations.unshift(new Map(payload)),
      });
    case ORG_REMOVED:
      return state.merge({
        organisations: state.organisations.filter(
          org => org.get("id") !== payload
        ),
      });
    case ORG_CHANGED:
      return state.merge({
        organisations: state.organisations.map(org => {
          return org.get("id") === payload.id ? new Map(payload) : org;
        }),
      });
    case SET_EXISTING_TEACHER_LICENSE_ERROR:
      return state.merge({
        existingTeacherLicenseError: payload,
        saving: false,
      });
    case SET_DOMAIN_CONFIRMATIONS:
      return state.merge({
        domainConfirmations: payload,
      });
    case SET_SORT_BY:
      return state.merge({ sortBy: payload });
    case SET_ORG_SAVING:
      return state.merge({ saving: payload });
    case SET_NEW_ORG_ERROR:
      return state.merge({ addNewOrgError: payload });
    case SET_DOMAIN_LICENSE_RETRY:
      return state.merge({ domainLicenseRetry: payload });
    case RESET_ORGANISATIONS:
    case SIGN_OUT_SUCCESS:
      return new OrganisationsState();
    default:
      return state;
  }
}
