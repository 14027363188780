import React, { useState } from "react";
import classNames from "classnames";
import "./index.scss";
import { isValidEmail } from "../../../../../../../../utils/validators";
import Button from "../../../../../../button";
import SvgIcon from "../../../../../../ui/svg-icon";
import TeacherIcon from "../../../../../../../../images/svg/teacher-class.svg";
import StudentIcon from "../../../../../../../../images/svg/student-class.svg";
import TickIcon from "../../../../../../../../images/svg/tick.svg";
import CrossIcon from "../../../../../../../../images/svg/cross.svg";
import { OrgDomainTestCase, OrgUserType } from "../../..";

interface DomainTestCaseProps {
  existingCaseEmails?: string[];
  editing?: boolean;
  domain?: string;
  domainType?: OrgUserType;
  local: string;
  email?: string;
  expectedType?: OrgUserType;
  pass?: boolean;
  onCancel?: () => void;
  onEdit?: (testCase: OrgDomainTestCase) => void;
  onSave?: (testCase: OrgDomainTestCase) => void;
}

export default function DomainTestCase(props: DomainTestCaseProps) {
  const { existingCaseEmails, onSave, onCancel, onEdit, pass, editing } = props;

  const addOrEdit = !props.email || editing;

  const [email, setEmail] = useState(props.email || "");
  const [hasBlurred, setHasBlurred] = useState(false);
  const [expectedType, setExpectedType] = useState(
    props.expectedType || OrgUserType.STUDENT
  );

  const className = classNames("domain-test-case", {
    "domain-test-case--editing": addOrEdit,
    "domain-test-case--read": !addOrEdit,
    "domain-test-case--pass": pass,
    "domain-test-case--fail": pass === false,
  });

  const handleSave = () => {
    if (editing) {
      return onEdit({ email, expectedType });
    }
    onSave({ email, expectedType });
  };

  const emailExists = existingCaseEmails?.includes(email);

  const isValid = isValidEmail(email) && !emailExists;

  let errorMessage: string | undefined = undefined;

  if (!isValidEmail(email)) {
    errorMessage = email.length === 0 ? "Add an email" : "Invalid email";
  }

  if (emailExists) {
    errorMessage = "Case exists";
  }

  if (addOrEdit) {
    return (
      <div className={className}>
        <div className="domain-test-case__row">
          <input
            className="domain-test-case__input"
            type="text"
            // biome-ignore lint/a11y/noAutofocus: Internal use only
            autoFocus
            placeholder="Email address"
            onBlur={() => setTimeout(() => setHasBlurred(true), 0)}
            style={{
              borderColor: !isValid && hasBlurred ? "red" : undefined,
              marginRight: 10,
            }}
            value={email}
            onChange={(evt: React.ChangeEvent) =>
              setEmail((evt.target as HTMLInputElement).value)
            }
          />
          <select
            onChange={evt => setExpectedType(evt.target.value as OrgUserType)}
            value={expectedType}
            className="domain-test-case__select">
            <option value={OrgUserType.STUDENT}>Student</option>
            <option value={OrgUserType.TEACHER}>Teacher</option>
            <option value={OrgUserType.ALL}>All</option>
          </select>
        </div>
        <div className="domain-test-case__actions">
          <Button onClick={handleSave} type="button" tiny disabled={!isValid}>
            {editing ? "Update" : "Add"}
          </Button>
          <Button onClick={onCancel} text tiny>
            Cancel
          </Button>
          {!isValid && hasBlurred ? (
            <span className="domain-test-case__error">{errorMessage}</span>
          ) : null}
        </div>
      </div>
    );
  }

  const resultClassName = classNames("domain-test-case__result", {
    "domain-test-case__result--pass": pass,
    "domain-test-case__result--fail": pass === false,
  });

  return (
    <div className={className}>
      <SvgIcon
        icon={
          props.expectedType === OrgUserType.STUDENT ? StudentIcon : TeacherIcon
        }
        cssClass="domain-test-case__icon"
      />
      <span className="domain-test-case__email">{props.email}</span>
      <span className={resultClassName}>
        <SvgIcon
          icon={pass ? TickIcon : CrossIcon}
          cssClass="domain-test-case__result-icon"
        />
      </span>
    </div>
  );
}
