import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FIREBASE_CONFIG } from "../../../../config";
import moment from "moment";
import SettingStatus from "./status";
import InlineButton from "../../ui/inline-button";
import Users from "./users";
import "./index.scss";
import ChangeLibraryOwner from "./change-owner";

function LibraryResult(props) {
  const {
    addErrorMessage,
    archived,
    name,
    bookCount,
    createdAt,
    deletedBookCount,
    id,
    org,
    owner,
    settings,
    userCount,
    users,
    getUsers,
    searchLibraries,
  } = props;

  const [showChangeLibraryOwner, setShowChangeLibraryOwner] = useState(false);

  const {
    allowStudentPublishing,
    disallowComments,
    disallowImageSearch,
    disallowStudentCollabControl,
    disallowStudentEditing,
    disallowReadOtherStudentsBooks,
  } = settings;

  const firebaseConsoleLink = `https://console.firebase.google.com/u/0/project/${FIREBASE_CONFIG.projectName}/database/${FIREBASE_CONFIG.projectName}/data/libraries/${id}`;

  return (
    <div className="library-result">
      <dl>
        <dt>Library name</dt>
        <dd>
          {name}{" "}
          <a
            href={firebaseConsoleLink}
            target="_blank"
            style={{ fontSize: 10, marginLeft: 10 }}
            rel="noreferrer">
            [Firebase node]
          </a>
        </dd>
        <dt>Status</dt>
        <dd>{archived ? "Archived" : "Active"}</dd>
        <dt>Created</dt>
        <dd>
          {createdAt ? moment(createdAt).format("Do MMM YYYY h:mma") : null}
        </dd>
        <dt>Books</dt>
        <dd>
          {bookCount || 0} active, {deletedBookCount || 0} deleted
        </dd>
        {org ? (
          <>
            <dt>Organisation</dt>
            <dd>
              <Link to={`/organisations/${org.id}/edit`}>{org.id}</Link>
            </dd>
            <dt>Created by</dt>
            <dd>
              <Link to={`/users/${org.createdBy}`}>{org.createdBy}</Link>
            </dd>
            <dt>Visibility</dt>
            <dd>{org.hidden ? "Hidden" : "Visible"}</dd>
          </>
        ) : (
          <>
            <dt>Owner </dt>
            <dd>
              {!owner.displayName ? "No owner" : null} {owner.displayName}{" "}
              &nbsp;&nbsp;{" "}
              <small>
                <Link to={`/users/${owner.userId}`}>{owner.userId}</Link>
                <InlineButton onClick={() => setShowChangeLibraryOwner(true)}>
                  Change
                </InlineButton>
              </small>
              {showChangeLibraryOwner ? (
                <ChangeLibraryOwner
                  addErrorMessage={addErrorMessage}
                  onClose={() => setShowChangeLibraryOwner(false)}
                  libraryId={id}
                  libraryName={name}
                  searchLibraries={searchLibraries}
                />
              ) : null}
            </dd>
            <dt>Users</dt>
            <dd>
              {users ? (
                <Users users={users} />
              ) : (
                <span>
                  {userCount}
                  <InlineButton onClick={getUsers}>Show</InlineButton>
                </span>
              )}
            </dd>
            <dt>Image Search</dt>
            <dd>
              <SettingStatus
                value={
                  disallowImageSearch === "google"
                    ? "Limited"
                    : !disallowImageSearch
                }
              />
            </dd>
            <dt>Student editing</dt>
            <dd>
              <SettingStatus value={!disallowStudentEditing} />
            </dd>
            <dt>Read others books</dt>
            <dd>
              <SettingStatus value={!disallowReadOtherStudentsBooks} />
            </dd>
            <dt>Student collaboration control</dt>
            <dd>
              <SettingStatus value={!disallowStudentCollabControl} />
            </dd>
            <dt>Student publishing</dt>
            <dd>
              <SettingStatus value={allowStudentPublishing} />
            </dd>
            <dt>Student commenting</dt>
            <dd>
              <SettingStatus value={!disallowComments} />
            </dd>
          </>
        )}
      </dl>
    </div>
  );
}

LibraryResult.defaultProps = {
  settings: {},
  owner: {},
};

export default LibraryResult;
