import React, { Component } from "react";
import EyeIcon from "../../../images/svg/eye.svg";
import SvgIcon from "../ui/svg-icon";
import Input from "./input";

class Password extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plainText: false,
      isPassword: true,
    };
  }

  componentDidMount() {
    const { autoFocus } = this.props;
    if (autoFocus) {
      this._input.focus();
    }
  }

  togglePlainText(e) {
    this.setState({
      plainText: !this.state.plainText,
    });
    this._input.focus();
    e.preventDefault();
  }

  render() {
    const { plainText } = this.state;

    const { onFocus, onBlur, onChange, id, name } = this.props;

    const opts = {
      onFocus,
      onBlur,
      onChange,
      id,
    };

    return (
      <div>
        <input
          {...opts}
          className="input__input input__input--has-toggle"
          type={plainText ? "text" : "password"}
          name={name}
          ref={(c) => {
            this._input = c;
          }}
          onChange={(e) => onChange(e.target.value)}
        />
        <button
          onClick={this.togglePlainText.bind(this)}
          type="button"
          className="input__toggle"
        >
          <SvgIcon icon={EyeIcon} name="eye" />
        </button>
      </div>
    );
  }
}

export default Input(Password);
