import React, { useEffect, useState } from "react";
import moment from "moment";
import Header, { HeaderActions } from "../../header";
import { Link } from "react-router-dom";
import FlatButton from "../../button/flat";
import Content from "../../content";
import Table from "../../table";
import Licenses from "../organisations-table/licenses";
import { List } from "immutable";
import { getDeletedOrganisations } from "../../../../core/organisations/orgs-api";

const DATE_FORMAT = "Do MMM YYYY";

function DeletedOrganisations(props) {
  const tableData = {
    headings: [
      {
        name: "Name",
      },
      {
        name: "Start Date",
      },
      {
        name: "End Date",
      },
      {
        name: "Closed Date",
      },
      {
        name: "Deal amount",
      },
      {
        name: "Licenses",
      },
    ],
    body: null,
  };

  const [orgs, setOrgs] = useState([]);
  const [orgsLoaded, setOrgsLoaded] = useState(false);

  useEffect(() => {
    const getDeletedOrgs = async () => {
      const orgsResponse = await getDeletedOrganisations();

      if (orgsResponse.ok) {
        setOrgs(orgsResponse.data);
      }
      setOrgsLoaded(true);
    };
    getDeletedOrgs();
  }, []);

  const getOrganisationRows = () => {
    return new List(
      orgs.map(org => {
        const {
          dealAmount,
          id,
          name,
          startDate,
          endDate,
          licenses,
          deletedAt,
          deletedBy,
        } = org;
        return {
          ...org,
          selected: false,
          rowId: id,
          cells: [
            <span>{name}</span>,
            <span>
              {startDate ? moment(startDate).format(DATE_FORMAT) : null}
            </span>,
            <span>{endDate ? moment(endDate).format(DATE_FORMAT) : null}</span>,
            <span>
              {deletedAt ? (
                <div>
                  {`${moment(deletedAt).format(DATE_FORMAT)} by`}
                  <Link
                    to={`/users/${deletedBy}`}
                    style={{ display: "block", fontSize: 12 }}>
                    {deletedBy}
                  </Link>
                </div>
              ) : null}
            </span>,
            <span>
              {dealAmount
                ? dealAmount
                    .toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                    .split(".")[0]
                : `$0`}
            </span>,
            <Licenses licenses={licenses} />,
          ],
        };
      })
    );
  };

  const td = {
    ...tableData,
    body: getOrganisationRows(),
  };

  return (
    <div>
      <Header title="Deleted Organisations">
        <HeaderActions>
          <FlatButton link="/organisations">Back</FlatButton>
        </HeaderActions>
      </Header>
      <Content>
        {orgsLoaded ? <Table data={td} rowHeight={58} /> : null}
      </Content>
    </div>
  );
}

export default DeletedOrganisations;
