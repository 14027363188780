import React from "react";
import classNames from "classnames";
import SvgIcon from "../ui/svg-icon";
import TickIcon from "../../../images/svg/tick.svg";
import "./checkbox.scss";

const Checkbox = props => {
  const { checked, onClick } = props;

  const cssClass = classNames("checkbox", {
    "checkbox--checked": checked,
  });

  return (
    <button className={cssClass} onClick={onClick} type="button">
      {checked ? <SvgIcon icon={TickIcon} name="tick" /> : null}
    </button>
  );
};

export default Checkbox;
