import React, { Component } from "react";
import PropTypes from "prop-types";
import "./index.scss";

export default class DisabledSection extends Component {
  static propTypes = {
    style: PropTypes.object,
    title: PropTypes.string,
  };

  render() {
    const { children, style, title } = this.props;
    return (
      <div className="disabled-section" style={style}>
        {title ? (
          <span className="disabled-section__title">{title}</span>
        ) : null}
        {children}
      </div>
    );
  }
}
