import React, { useState } from "react";
import PropTypes from "prop-types";
import Avatar from "../../../../avatar";
import Name from "./name";
import SvgIcon from "../../../../ui/svg-icon";
import DeleteIcon from "../../../../../../images/svg/delete.svg";
import { FIREBASE_CONFIG } from "../../../../../../config";
import DeleteAvatarConfirmation from "./delete-avatar-confirmation";

function ResultCardHeader(props) {
  const {
    addErrorMessage,
    avatarUrl,
    disabled,
    displayName,
    email,
    uid,
    // -
    setShowImpersonateUserModal,
    updateUserValueInStore,
  } = props;

  const [
    showDeleteAvatarConfirmation,
    setShowDeleteAvatarConfirmation,
  ] = useState(false);

  const [avatarDeleted, setAvatarDeleted] = useState(false);

  let firebaseConsoleLink, firebaseConsoleDataLink;

  if (uid) {
    firebaseConsoleLink = `https://console.firebase.google.com/u/0/project/${FIREBASE_CONFIG.projectName}/database/${FIREBASE_CONFIG.projectName}/data/users/${uid}`;
    firebaseConsoleDataLink = `https://console.firebase.google.com/u/0/project/${FIREBASE_CONFIG.projectName}/database/${FIREBASE_CONFIG.projectName}/data/data/${uid}`;
  }

  return (
    <div className="result-card__header">
      <div className="result-card__avatar">
        <Avatar
          name={displayName}
          url={!avatarDeleted ? avatarUrl : null}
          size={70}
        />
        {avatarUrl && !avatarDeleted ? (
          <button
            className="result-card__avatar-delete"
            onClick={() => setShowDeleteAvatarConfirmation(true)}>
            <SvgIcon icon={DeleteIcon} />
          </button>
        ) : null}
      </div>
      <div className="result-card__name">
        <Name
          addErrorMessage={addErrorMessage}
          displayName={displayName}
          uid={uid}
          updateUserValueInStore={updateUserValueInStore}
        />
        <span className="result-card__uid">
          {uid}
          &nbsp;
          <a href={firebaseConsoleLink} target="_blank" rel="noreferrer">
            [user node]
          </a>
          &nbsp;
          <a href={firebaseConsoleDataLink} target="_blank" rel="noreferrer">
            [data node]
          </a>
        </span>
        {disabled ? (
          <span className="result-card__disabled">Disabled</span>
        ) : null}
        <button
          className="result-card__impersonate"
          onClick={e =>
            setShowImpersonateUserModal({ displayName, uid, email })
          }>
          Sign in to web app as this user
        </button>
      </div>
      {showDeleteAvatarConfirmation ? (
        <DeleteAvatarConfirmation
          addErrorMessage={addErrorMessage}
          userId={uid}
          onComplete={() => setAvatarDeleted(true)}
          onClose={() => setShowDeleteAvatarConfirmation(false)}
        />
      ) : null}
    </div>
  );
}

ResultCardHeader.propTypes = {
  avatarUrl: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  displayName: PropTypes.string.isRequired,
  email: PropTypes.string,
  emailVerified: PropTypes.bool,
  uid: PropTypes.string.isRequired,
  // -
  setShowImpersonateUserModal: PropTypes.func.isRequired,
};

export default ResultCardHeader;
