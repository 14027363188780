import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  usersActions,
  getUsersSearching,
  getUsersSearchResult,
  getUsersSearchTerm,
  getUsersSearchResultMultiple,
  getDomainSearching,
  getDomainSearchResults,
  getDomainSearchTerm,
  getDomainSearchComplete,
} from "../../../../core/users";
import { getServerTime } from "../../../../core/auth";
import Loader from "../../ui/loader";
import NoResults from "./no-results";
import Result from "./result";
import DomainSearchResults from "./domain-search-results";
import MultipleSearchResults from "./multiple-results";
// import './index.scss';

class UserSearchResults extends Component {
  get content() {
    const {
      searchResult,
      usersSearchResultMultiple,
      searchTerm,
      usersSearching,
      domainSearching,
      domainSearchResults,
      domainSearchTerm,
      domainSearchComplete,
      searchByDomain,
      serverTime,
    } = this.props;
    if (usersSearching || domainSearching) {
      return <Loader />;
    } else if (
      usersSearchResultMultiple &&
      usersSearchResultMultiple.length &&
      searchTerm
    ) {
      return (
        <MultipleSearchResults
          results={usersSearchResultMultiple}
          searchTerm={searchTerm}
        />
      );
    } else if (!searchResult && searchTerm) {
      return <NoResults searchTerm={searchTerm} />;
    } else if (searchResult) {
      return <Result result={searchResult} serverTime={serverTime} />;
    } else if (domainSearchTerm) {
      return (
        <DomainSearchResults
          results={domainSearchResults}
          searchTerm={domainSearchTerm}
          domainSearchComplete={domainSearchComplete}
          searchByDomain={searchByDomain}
        />
      );
    }
    return null;
  }

  render() {
    const {
      searchTerm,
      usersSearching,
      domainSearchTerm,
      domainSearching,
    } = this.props;

    if (
      !searchTerm &&
      !usersSearching &&
      !domainSearchTerm &&
      !domainSearching
    ) {
      return null;
    }

    return <div className="user-search-results">{this.content}</div>;
  }
}

const mapStateToProps = createSelector(
  getUsersSearching,
  getUsersSearchResult,
  getUsersSearchResultMultiple,
  getUsersSearchTerm,
  getDomainSearching,
  getDomainSearchResults,
  getDomainSearchTerm,
  getDomainSearchComplete,
  getServerTime,
  (
    usersSearching,
    searchResult,
    usersSearchResultMultiple,
    searchTerm,
    domainSearching,
    domainSearchResults,
    domainSearchTerm,
    domainSearchComplete,
    serverTime
  ) => ({
    usersSearching,
    searchResult,
    usersSearchResultMultiple,
    searchTerm,
    domainSearching,
    domainSearchResults,
    domainSearchTerm,
    domainSearchComplete,
    serverTime,
  })
);

const mapDispatchToProps = {
  ...usersActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSearchResults);
