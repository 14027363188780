import React, { Component, Fragment } from "react";
import classNames from "classnames";
import SvgIcon from "../../ui/svg-icon";
import { Link } from "react-router-dom";
import ChevronIcon from "../../../../images/svg/chevron.svg";
import "./index.scss";

export default class MiniMenuItem extends Component {
  get contents() {
    const { children, icon, iconName, hasSubMenu } = this.props;
    return (
      <Fragment>
        {icon ? <SvgIcon icon={icon} name={iconName} /> : null}
        {children}
        {hasSubMenu ? <SvgIcon icon={ChevronIcon} name="sub-menu" /> : null}
      </Fragment>
    );
  }

  render() {
    const {
      onClick,
      type,
      compact,
      disabled,
      href,
      target,
      iconHighlight,
      hasSubMenu,
      to,
    } = this.props;

    const cssClass = classNames("mini-menu__action", {
      [`mini-menu__action--${type}`]: type,
      "mini-menu__action--compact": compact,
      "mini-menu__action--highlight": iconHighlight,
      "mini-menu__action--has-submenu": hasSubMenu,
    });

    let props = {
      className: cssClass,
      disabled,
      onClick,
      target,
      onContextMenu: (e) => e.stopPropagation(),
    };
    if (href) {
      return (
        <a href={href} {...props}>
          {this.contents}
        </a>
      );
    } else if (to) {
      return (
        <Link to={to} {...props}>
          {this.contents}
        </Link>
      );
    } else {
      return <button {...props}>{this.contents}</button>;
    }
  }
}
