import React, { Component } from "react";
import classNames from "classnames";
import "./form.scss";

class Form extends Component {
  render() {
    const {
      header,
      children,
      onSubmit,
      autocomplete,
      nofooter,
      style,
      width,
    } = this.props;

    const cssClass = classNames("form", {
      "form--no-header": !header,
      "form--no-footer": nofooter,
    });

    const formStyle = {
      width: width ? width : null,
      maxWidth: width ? "100%" : null,
      ...style,
    };

    return (
      <form
        className={cssClass}
        style={formStyle}
        noValidate
        autoComplete={autocomplete === false ? "off" : null}
        onSubmit={onSubmit}>
        {header ? <h1 className="form__header">{header}</h1> : null}
        {children}
      </form>
    );
  }
}

export default Form;
