import React from "react";
import classNames from "classnames";
import "./index.scss";

function SettingStatus(props) {
  const { value } = props;

  const className = classNames("setting-status", {
    "setting-status--enabled": value,
    "setting-status--disabled": !value,
  });

  return (
    <span className={className}>
      {value ? (value === "Limited" ? "Limited" : "Enabled") : "Disabled"}
    </span>
  );
}

export default SettingStatus;
