import React from "react";
import Dropdown from "../../forms/dropdown";
import "./index.scss";

function TableFilter({ label, options, value, onChange }) {
  let mappedOptions = options.map(o => ({
    value: o.value,
    label: o.label || o.value,
  }));
  return (
    <div className="table-filter">
      <label className="table-filter__label">{label}</label>
      <Dropdown
        options={mappedOptions}
        value={mappedOptions.find(o => value === o.value)}
        onChange={v => onChange({ value: v.value, label })}
      />
    </div>
  );
}

export default TableFilter;
