import React, { useState } from "react";
import PropTypes from "prop-types";
import superagent from "superagent";
import { createPortal } from "react-dom";
import Modal from "../../../../../modal";
import {
  BOOKCREATOR_API_URL,
  BOOKCREATOR_APP_URL,
} from "../../../../../../../config";

function ChangeEmailOverlay(props) {
  const {
    email,
    uid,
    //
    addErrorMessage,
    onClose,
    onComplete,
  } = props;

  const [saving, setSaving] = useState(false);
  const [complete, setComplete] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = (e) => {
    setSaving(true);
    superagent
      .post(`${BOOKCREATOR_API_URL}/admin-tools/v2/${uid}/users/password/setup`)
      .withCredentials()
      .end((err, res) => {
        setSaving(false);
        if (err) {
          addErrorMessage(
            res?.body?.message || "Something went wrong converting the user"
          );
        } else {
          setComplete(true);
        }
      });
  };

  const handleComplete = () => {
    onComplete();
    onClose();
  };

  return createPortal(
    complete ? (
      <Modal
        title="User converted to password login"
        modal
        onCancel={handleClose}
        onConfirm={handleComplete}
        confirmText="Done"
      >
        <p>Send them this link to reset their password:</p>
        <p style={{ fontSize: 12 }}>
          {BOOKCREATOR_APP_URL}/sign-in/forgotten-password/{email}
        </p>
      </Modal>
    ) : (
      <Modal
        title="Convert to password login"
        modal
        onCancel={handleClose}
        onConfirm={handleConfirm}
        confirmText="Convert"
        confirmButtonLoading={saving}
      >
        <p>Are you sure you want to convert this user to a password login?</p>
      </Modal>
    ),
    document.getElementsByTagName("body")[0]
  );
}

ChangeEmailOverlay.propTypes = {
  email: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  addErrorMessage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default ChangeEmailOverlay;
