import React, { Component } from "react";
import Helmet from "react-helmet";
import Form from "../forms/form";
import SignInButton from "./sign-in-button";
import Message from "../ui/message";
import Loader from "../ui/loader";

class SignIn extends Component {
  componentWillUnmount() {
    this.props.clearAuthError();
  }

  render() {
    const { signInWithGoogle, auth } = this.props;

    const { error } = auth.toJS();

    const title = "Sign in";

    let errorMessage;
    // Show friendlier message
    if (error) {
      switch (error.message) {
        case "The popup has been closed by the user before finalizing the operation.":
        case "User did not grant your application the permissions it requested.":
          errorMessage =
            "You need to allow Book Creator access to your Google account to sign in with Google";
          break;
        default:
          errorMessage = error.message;
      }
    }

    if (!auth.initialised) {
      return <Loader colour="white" />;
    }

    return (
      <Form header={title}>
        <Helmet title={title} />
        <div className="form__content">
          <SignInButton
            text="Sign in with Google"
            provider="google"
            onClick={(e) => {
              signInWithGoogle();
              e.preventDefault();
            }}
          />
          {error ? (
            <Message type="error" center>
              {errorMessage}
            </Message>
          ) : null}
        </div>
      </Form>
    );
  }
}

export default SignIn;
