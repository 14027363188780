import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { firebaseDb } from "../../../../../core/firebase";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { notificationsActions } from "../../../../../core/notifications";
import { getUserId } from "../../../../../core/auth";
import DropDown from "../../../forms/dropdown";
import Loader from "../../../ui/loader";
import InlineButton from "../../../ui/inline-button";
import SvgIcon from "../../../ui/svg-icon";
import TickIcon from "../../../../../images/svg/tick.svg";
import "./index.scss";

const plans = [
  {
    bookQuota: 40,
    libraryQuota: 1,
    name: "Free plan",
  },
  {
    bookQuota: 1000,
    libraryQuota: "UNLIMITED",
  },
  {
    bookQuota: 60,
    libraryQuota: 3,
    hidden: true,
  },
];

const getPlanDisplayName = (plan) => {
  if (plan.name) {
    return plan.name;
  } else if (plan.libraryQuota === "UNLIMITED") {
    return `${plan.bookQuota} book plan`;
  } else {
    return `${plan.bookQuota * plan.libraryQuota} book plan`;
  }
};

class Plan extends Component {
  static propTypes = {
    bookQuota: PropTypes.number.isRequired,
  };

  static defaultProps = {
    bookQuota: 40,
    libraryQuota: 1,
  };

  state = {
    selectedPlan: null,
    saving: false,
    editing: false,
    succeeded: null,
  };

  componentWillMount() {
    const currentPlan = this.getPlanIndex(this.props.bookQuota);
    this.setState({
      selectedPlan: currentPlan,
      previousPlan: currentPlan,
    });
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handlePlanChange = (dropdownValue) => {
    const { uid, addErrorMessage, currentUserId } = this.props;
    const { previousPlan } = this.state;
    clearTimeout(this._successTimeout);
    this.setState({
      saving: true,
      editing: false,
      selectedPlan: dropdownValue.value,
    });
    const plan = plans[dropdownValue.value];
    const oldPlan = plans[previousPlan];
    const updates = {};
    const oldPlanString = `${oldPlan.libraryQuota}x${oldPlan.bookQuota}`;
    const newPlanString = `${plan.libraryQuota}x${plan.bookQuota}`;
    let historyEntry = {
      date: Date.now(),
      from: oldPlanString,
      to: newPlanString,
      userId: currentUserId,
    };
    updates[`/users/${uid}/bookQuota`] = plan.bookQuota;
    updates[`/users/${uid}/libraryQuota`] = plan.libraryQuota;
    updates[`/users/${uid}/collaborationTrialStarted`] = null;
    updates[`/users/${uid}/collaborationTrialEnded`] = null;
    updates[`/users/${uid}/collaborationTrialEnds`] = null;
    updates[
      `/missionControlPlanChangeHistory/${uid}/${historyEntry.date}`
    ] = historyEntry;
    firebaseDb
      .ref(`/`)
      .update(updates)
      .then(() => {
        this.setState({
          saving: false,
          succeeded: true,
          previousPlan: dropdownValue.value,
        });
        this._successTimeout = setTimeout(() => {
          if (this._isMounted) {
            this.setState({
              succeeded: null,
            });
          }
        }, 3000);
      })
      .catch((error) => {
        this.setState({ saving: false });
        console.error(error);
        addErrorMessage(error.message);
      });
  };

  getPlanIndex = (bookQuota) => {
    return plans.map((plan) => plan.bookQuota).indexOf(bookQuota);
  };

  edit = () => {
    this.setState({
      editing: true,
    });
  };

  handleEditClick = (e) => {
    this.edit();
    e.preventDefault();
  };

  render() {
    const { saving, editing, succeeded, selectedPlan } = this.state;
    const { bookQuota, libraryQuota } = this.props;
    let currentPlanData = plans[selectedPlan] || {};
    if (!currentPlanData.bookQuota) {
      currentPlanData.bookQuota = bookQuota;
      currentPlanData.libraryQuota = libraryQuota;
    }

    return (
      <div className="result-plan">
        {editing ? (
          <Fragment>
            <DropDown
              value={{
                value: selectedPlan,
                label: getPlanDisplayName(currentPlanData),
              }}
              width={150}
              open
              onChange={this.handlePlanChange}
              options={plans
                .filter((plan) => !plan.hidden)
                .map((plan, i) => {
                  return {
                    value: i,
                    label: getPlanDisplayName(plan),
                  };
                })}
            />
            <InlineButton onClick={() => this.setState({ editing: false })}>
              Cancel
            </InlineButton>
          </Fragment>
        ) : (
          <span className="result-plan__read">
            {getPlanDisplayName(currentPlanData)}
          </span>
        )}
        {saving ? <Loader tiny /> : null}
        {succeeded && !saving ? <SvgIcon icon={TickIcon} name="tick" /> : null}
        {!editing && !saving && !succeeded ? (
          <InlineButton onClick={this.handleEditClick}>Change</InlineButton>
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = {
  ...notificationsActions,
};

const mapStateToProps = createSelector(getUserId, (currentUserId) => ({
  currentUserId,
}));
export default connect(mapStateToProps, mapDispatchToProps)(Plan);
