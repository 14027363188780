import React, { useState } from "react";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import Modal from "../../../../../modal";
import TextBox from "../../../../../forms/text-box";
import { isValidEmail } from "../../../../../../../utils/validators";
import { firebaseDb } from "../../../../../../../core/firebase";

function ChangeEmailOverlay(props) {
  const {
    email,
    uid,
    //
    addErrorMessage,
    onClose,
    onComplete,
  } = props;

  const [newEmail, setNewEmail] = useState("");
  const [saving, setSaving] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [newEmailBlurred, setNewEmailBlurred] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const unlistenForUpdates = () => {
    firebaseDb.ref(`/updateUsers/${uid}`).off();
  };

  const clearUpdateNode = () => {
    const updates = {
      [`/updateUsers/${uid}`]: null,
    };
    firebaseDb
      .ref("/")
      .update(updates)
      .then(() => {
        unlistenForUpdates();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleClose = () => {
    clearUpdateNode();
    onClose();
  };

  const setEmailNode = () => {
    const updates = {
      [`/users/${uid}/email`]: newEmail,
    };
    firebaseDb
      .ref("/")
      .update(updates)
      .then(() => {
        onComplete();
        handleClose();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const listenForUpdates = () => {
    firebaseDb.ref(`/updateUsers/${uid}`).on("value", (snapshot) => {
      if (!snapshot.val()) {
        unlistenForUpdates();
        setEmailNode();
      } else if (snapshot.val().failed) {
        unlistenForUpdates();
        setHasError(true);
        addErrorMessage(snapshot.val().failed.error.message);
        setSaving(false);
      }
    });
  };

  const handleConfirm = (e) => {
    setSaving(true);
    listenForUpdates();
    const updates = {
      [`/updateUsers/${uid}/email`]: newEmail,
      [`/updateUsers/${uid}/emailVerified`]: false,
    };
    firebaseDb
      .ref("/")
      .update(updates)
      .then(() => {})
      .catch((err) => {
        console.error(err);
      });
  };

  return createPortal(
    showConfirmation ? (
      <Modal
        title="Confirm email change"
        modal
        onCancel={handleClose}
        onConfirm={!hasError ? handleConfirm : null}
        confirmText="Confirm"
        confirmButtonLoading={saving}
      >
        <p>
          Are you sure you want to change the email address from{" "}
          <strong>{email}</strong> to <strong>{newEmail}</strong>?
        </p>
      </Modal>
    ) : (
      <Modal
        title="Change email"
        modal
        onCancel={handleClose}
        onConfirm={() => setShowConfirmation(true)}
        confirmButtonDisabled={!isValidEmail(newEmail)}
        confirmText="Save"
      >
        <TextBox label="Old email" value={email} disabled />
        <TextBox
          autoFocus
          label="New email"
          value={newEmail}
          onBlur={() => {
            setNewEmailBlurred(true);
          }}
          error={
            newEmailBlurred && !isValidEmail(newEmail) ? "Invalid email" : null
          }
          onChange={(value) => setNewEmail(value)}
        />
      </Modal>
    ),
    document.getElementsByTagName("body")[0]
  );
}

ChangeEmailOverlay.propTypes = {
  email: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  addErrorMessage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default ChangeEmailOverlay;
