import { getUserId } from "../auth";
import { linkToHash } from "../../utils/string";
import { firebaseFirestore } from "../firebase";

export function blockImageByUrl({ link }) {
  return (dispatch, getState) => {
    const userId = getUserId(getState());
    firebaseFirestore
      .collection("imageSearchBlocks")
      .doc(linkToHash(link))
      .set({
        link,
        needsCacheInvalidation: true,
        userId,
        from: "missioncontrol",
      });
  };
}

export function unBlockImageById({ id }) {
  return (dispatch, getState) => {
    firebaseFirestore.collection("imageSearchBlocks").doc(id).delete();
  };
}
