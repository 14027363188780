import React, { useState } from "react";
import Item from "./item";
import TextBox from "../forms/text-box";
import Button from "../button";
import "./index.scss";

function ListEditor(props) {
  const { items, onItemDelete, onAddItem } = props;

  const [newTag, setNewTag] = useState("");

  const handleAddSubmit = evt => {
    if (newTag.length > 0) {
      onAddItem(newTag);
      setNewTag("");
    }
    evt.preventDefault();
  };

  return (
    <div className="list-editor">
      <div className="list-editor__list">
        {items.map(item => (
          <Item key={item.id} {...item} onDelete={onItemDelete} />
        ))}
      </div>
      <form onSubmit={handleAddSubmit} className="list-editor__add">
        <TextBox
          value={newTag}
          onChange={t => setNewTag(t)}
          placeholder="Add tag"
        />
        <Button secondary small type="submit">
          Add
        </Button>
      </form>
    </div>
  );
}

export default ListEditor;
