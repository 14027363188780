import React from "react";
import classNames from "classnames";
import "./index.scss";

function GoogleImageSearchURL(props) {
  const { blocked, url, onToggle, fbKey } = props;

  const className = classNames("image-search-url", {
    "image-search-url--blocked": blocked,
  });

  const handleClick = (evt) => {
    onToggle({ id: fbKey, link: !blocked ? url : null });
    evt.preventDefault();
  };

  return (
    <div className={className}>
      <span className="image-search-url__label">{url}</span>
      <button className="image-search-url__button" onClick={handleClick}>
        {blocked ? "Unblock" : "Block"}
      </button>
    </div>
  );
}

export default GoogleImageSearchURL;
