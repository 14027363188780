import superagent from "superagent";
import { firebaseDb } from "../firebase";
import { encodeDomain, decodeDomain } from "../../utils/string";
import { BOOKCREATOR_API_URL } from "../../config";
import {
  SEARCH_USERS_ATTEMPT,
  SEARCH_USERS_RESULT,
  SEARCH_USERS_MULTIPLE_RESULT,
  SEARCH_USERS_NOT_FOUND,
  SET_SHOW_DELETE_USER_OVERLAY,
  SET_SHOW_IMPERSONATE_USER_OVERLAY,
  SET_USER_TO_DELETE,
  USER_DELETED,
  DELETE_USER_ATTEMPT,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  RECEIVE_ADDITIONAL_USER_INFO,
  CLEAR_USER_TO_DELETE,
  DOMAIN_SEARCH_ATTEMPT,
  DOMAIN_SEARCH_RESULT,
  RESET_USER_SEARCH,
  RESET_USER_STATE,
  UPDATE_USER_VALUE,
} from "./action-types";

import { addErrorMessage } from "../notifications/actions";

export function searchUsers(payload) {
  return (dispatch, getState) => {
    const { searchTerm } = payload;
    dispatch({ type: SEARCH_USERS_ATTEMPT });
    superagent
      .get(
        `${BOOKCREATOR_API_URL}/admin-tools/v2/me/users/search/${searchTerm}`
      )
      .withCredentials()
      .end((err, res) => {
        if (err) {
          if (err.status !== 404) {
            dispatch({ type: RESET_USER_SEARCH });
            dispatch(
              addErrorMessage(
                err.message || "There was a problem connecting to the API"
              )
            );
          } else {
            dispatch({ type: SEARCH_USERS_NOT_FOUND, payload: { searchTerm } });
          }
        } else {
          const results = res.body;
          if (results.length === 1) {
            dispatch(getAdditionalUserInfo(results[0].id, searchTerm));
            dispatch({
              type: SEARCH_USERS_RESULT,
              payload: { result: results[0], searchTerm },
            });
          } else if (results.length > 1) {
            dispatch({
              type: SEARCH_USERS_MULTIPLE_RESULT,
              payload: { result: res.body, searchTerm },
            });
          } else {
            dispatch({ type: SEARCH_USERS_NOT_FOUND, payload: { searchTerm } });
          }
        }
      });
  };
}

export function refreshUser(uid) {
  return (dispatch, getState) => {
    superagent
      .get(`${BOOKCREATOR_API_URL}/admin-tools/v2/me/users/search/${uid}`)
      .withCredentials()
      .end((err, res) => {
        if (err) {
        } else {
          const results = res.body;
          if (results.length === 1) {
            dispatch(getAdditionalUserInfo(results[0].id, uid));
          }
        }
      });
  };
}

export function getAdditionalUserInfo(uid, searchTerm) {
  return (dispatch, getState) => {
    superagent
      .get(`${BOOKCREATOR_API_URL}/admin-tools/v2/me/users/${uid}`)
      .withCredentials()
      .end((err, res) => {
        if (err) {
          dispatch({ type: SEARCH_USERS_NOT_FOUND, payload: { searchTerm } });
        } else {
          dispatch({
            type: RECEIVE_ADDITIONAL_USER_INFO,
            payload: res.body,
          });
        }
      });
  };
}

export function checkUserToDelete(payload) {
  return (dispatch, getState) => {
    const { uid } = payload;
    superagent
      .post(`${BOOKCREATOR_API_URL}/admin-tools/v2/${uid}/users/delete-check`)
      .withCredentials()
      .end((err, res) => {
        let nextPayload = { ...payload };
        if (
          err &&
          err.response &&
          err.response.body &&
          err.response.body.message
        ) {
          nextPayload.errorMessage = err.response.body.message;
        }
        if (
          err &&
          err.response &&
          err.response.body &&
          err.response.body.errors
        ) {
          nextPayload.errors = err.response.body.errors;
        }
        dispatch({
          type: SET_USER_TO_DELETE,
          payload: nextPayload,
        });
        // dispatch({type: SEARCH_USERS_RESULT, payload: {result: users, searchTerm}});
      });
  };
}

export function deleteUser(uid) {
  return (dispatch, getState) => {
    dispatch({ type: DELETE_USER_ATTEMPT });
    superagent
      .delete(`${BOOKCREATOR_API_URL}/admin-tools/v2/${uid}/users`)
      .withCredentials()
      .end((err, res) => {
        if (err) {
          let message;
          if (err.response && err.response.body && err.response.body.message) {
            message = err.response.body.message;
          } else {
            message = "There was an error trying to delete the user";
          }
          dispatch(addErrorMessage(message));
          dispatch(setShowDeleteUserModal(false));
          dispatch(clearUserToDelete());
          dispatch({ type: DELETE_USER_ERROR });
        } else {
          dispatch({ type: DELETE_USER_SUCCESS });
          dispatch({
            type: USER_DELETED,
            payload: uid,
          });
        }
      });
  };
}

export function setShowDeleteUserModal(payload) {
  return {
    type: SET_SHOW_DELETE_USER_OVERLAY,
    payload,
  };
}

export function setShowImpersonateUserModal(payload) {
  return {
    type: SET_SHOW_IMPERSONATE_USER_OVERLAY,
    payload,
  };
}

export function clearUserToDelete() {
  return {
    type: CLEAR_USER_TO_DELETE,
  };
}

export function resetUserSearch() {
  return {
    type: RESET_USER_SEARCH,
  };
}

export function resetUserState() {
  return {
    type: RESET_USER_STATE,
  };
}

const PAGE_SIZE = 100;
let lastKey = null;

export function searchByDomain(payload) {
  return dispatch => {
    const { searchTerm, newSearch } = payload;
    if (newSearch) {
      lastKey = null;
      dispatch({ type: DOMAIN_SEARCH_ATTEMPT });
    }
    const encodedDomain = encodeDomain(searchTerm.replace("@", ""));
    let ref = firebaseDb
      .ref(`/userEmailDomains/${encodedDomain}`)
      .orderByKey()
      .limitToFirst(PAGE_SIZE + 1);
    if (lastKey) {
      ref = ref.startAt(lastKey);
    }
    ref
      .once("value")
      .then(snapshot => {
        let results = snapshot.val();
        if (!results) {
          return dispatch({
            type: DOMAIN_SEARCH_RESULT,
            payload: {
              result: [],
              searchTerm,
              domainSearchComplete: true,
            },
          });
        }
        const keys = Object.keys(results);
        let domainSearchComplete = false;
        if (keys.length > PAGE_SIZE) {
          lastKey = keys[keys.length - 1];
          delete results[lastKey];
        } else {
          domainSearchComplete = true;
        }
        let users = Object.keys(results).map(key => {
          return {
            key: results[key],
            email: `${decodeDomain(key)}${searchTerm}`,
          };
        });

        dispatch({
          type: DOMAIN_SEARCH_RESULT,
          payload: {
            result: users,
            searchTerm,
            domainSearchComplete,
          },
        });
      })
      .catch(e => {
        console.error(e);
      });
  };
}

export function updateUser(payload) {
  return dispatch => {
    const { uid, location, value } = payload;
    if (!uid || !location) {
      return;
    }
    const updates = {};
    updates[`/users/${uid}/${location}`] = value;
    firebaseDb
      .ref(`/`)
      .update(updates)
      .then(() => {
        dispatch({
          type: UPDATE_USER_VALUE,
          payload,
        });
      })
      .catch(error => {
        console.error(error);
        dispatch(addErrorMessage(error.message));
      });
  };
}

export function updateUserValueInStore(payload) {
  return dispatch => {
    // const { uid, location, value } = payload;
    dispatch({
      type: UPDATE_USER_VALUE,
      payload,
    });
  };
}
