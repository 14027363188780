import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { firebaseDb } from "../../../../../../../core/firebase";
import InlineButton from "../../../../../ui/inline-button";
import "./index.scss";

function ResultCardProvider(props) {
  const {
    dateFormat,
    loginProvider,
    providerData,
    isTeacher,
    uid,
    updateUserValueInStore,
  } = props;

  let providers;
  if (!loginProvider || typeof loginProvider === "string") {
    if (providerData && providerData.length) {
      providers = [providerData[0].providerId];
    }
  } else if (loginProvider && typeof loginProvider === "object") {
    providers = Object.keys(loginProvider).map(key => {
      const date =
        loginProvider[key] > -1
          ? moment(loginProvider[key]).format(dateFormat)
          : "-1";
      return (
        <span key={key}>
          {key.replace(/:/g, ".")}
          <small style={{ marginLeft: 10, color: "#999" }}>{date}</small>
        </span>
      );
    });
  }

  const addPasswordProvider = async () => {
    await firebaseDb
      .ref(`/users/${uid}/loginProvider`)
      .update({ password: -1 });
    updateUserValueInStore({
      uid,
      location: "loginProvider",
      value: {
        ...loginProvider,
        password: -1,
      },
    });
  };

  if (!providers) {
    return null;
  }

  return (
    <Fragment>
      <dt>Provider:</dt>
      <dd>
        <div className="result-card-providers">
          <div className="result-card-providers__providers">
            {providers.map((provider, i) => (
              <div key={i}>{provider}</div>
            ))}
          </div>
          {isTeacher && !loginProvider?.password ? (
            <div className="result-card-providers__actions">
              <InlineButton onClick={addPasswordProvider}>
                Add password provider
              </InlineButton>
            </div>
          ) : null}
        </div>
      </dd>
    </Fragment>
  );
}

ResultCardProvider.propTypes = {
  loginProvider: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  providerData: PropTypes.array,
  //
};

export default ResultCardProvider;
