import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  getIsDowngrading,
  getDowngradingError,
  getDowngradingSuccess,
  downgradeActions,
  getUserToDowngrade,
} from "../../../core/downgrade";
import DowngradeForm from "./form";
import Modal from "../modal";

class DowngradeModal extends Component {
  handleCancelClick = (e) => {
    const { setShowDowngradeModal, setUserToDowngrade } = this.props;
    setShowDowngradeModal(false);
    setUserToDowngrade(null);
  };

  render() {
    const {
      isDowngrading,
      doDowngrade,
      downgradingError,
      downgradingSuccess,
      setDowngradingError,
      clearDowngradingSuccess,
      userToDowngrade,
    } = this.props;

    return (
      <Modal
        modal
        hideFooter
        showX={downgradingSuccess}
        onCancel={this.handleCancelClick}
        bodyStyle={{ background: "transparent", padding: 0 }}
      >
        <DowngradeForm
          isDowngrading={isDowngrading}
          doDowngrade={doDowngrade}
          downgradingError={downgradingError}
          userToDowngrade={userToDowngrade}
          setDowngradingError={setDowngradingError}
          downgradingSuccess={downgradingSuccess}
          onCancelClick={!isDowngrading ? this.handleCancelClick : null}
          clearDowngradingSuccess={clearDowngradingSuccess}
        />
      </Modal>
    );
  }
}

const mapStateToProps = createSelector(
  getIsDowngrading,
  getDowngradingError,
  getDowngradingSuccess,
  getUserToDowngrade,
  (isDowngrading, downgradingError, downgradingSuccess, userToDowngrade) => ({
    isDowngrading,
    downgradingError,
    downgradingSuccess,
    userToDowngrade,
  })
);

const mapDispatchToProps = {
  ...downgradeActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(DowngradeModal);
