import AvatarIcon from "../../../images/svg/user.svg";
import KeyIcon from "../../../images/svg/key.svg";
import OrganisationIcon from "../../../images/svg/organisation.svg";
import LibrariesIcon from "../../../images/svg/library.svg";
import AnalyticsIcon from "../../../images/svg/analytics.svg";
import ImagesIcon from "../../../images/svg/images.svg";
import LightBulbIcon from "../../../images/svg/light-bulb.svg";

const routes = [
  {
    name: "Users",
    icon: AvatarIcon,
    iconName: "user",
    route: "/users",
  },
  {
    name: "Organisations",
    icon: OrganisationIcon,
    iconName: "organisation",
    route: "/organisations",
  },
  {
    name: "Libraries",
    icon: LibrariesIcon,
    iconName: "libraries",
    route: "/libraries",
  },
  {
    name: "Analytics",
    icon: AnalyticsIcon,
    iconName: "analytics",
    route: "/analytics",
  },
  {
    name: "Promo codes",
    icon: KeyIcon,
    iconName: "key",
    route: "/promo-codes",
  },
  {
    name: "Image search",
    icon: ImagesIcon,
    iconName: "image",
    childRoutes: [
      {
        name: "Noun Project",
        route: "/image-search/noun-project",
      },
      {
        name: "Pixabay",
        route: "/image-search/pixabay",
      },
      {
        name: "Google",
        route: "/image-search/google",
      },
      {
        name: "Blocked tags",
        route: "/image-search/tags",
      },
    ],
  },
  {
    name: "Intl Coupons",
    icon: LightBulbIcon,
    iconName: "lightbulb",
    route: "/campustore",
  },
];

export default routes;
