import React from "react";
import classNames from "classnames";
import "./index.scss";

function NounProjectSlug(props) {
  const { children, onToggle, selected, blockedId } = props;

  const className = classNames("noun-search-slug", {
    "noun-search-slug--selected": selected,
  });

  const handleClick = (evt) => {
    onToggle({ id: blockedId, value: !selected ? children : null });
    evt.preventDefault();
  };

  return (
    <button className={className} onClick={handleClick}>
      {children}
    </button>
  );
}

export default NounProjectSlug;
