import { useEffect, useRef } from "react";

interface OrgTypeHandlerProps {
  isDomainOrg: boolean;
  isQuotaOrg: boolean;
  onBecameDomainOrg: () => void;
  onBecameQuotaOrg: () => void;
}

export default function OrgTypeHandler(props: OrgTypeHandlerProps) {
  const { isDomainOrg, isQuotaOrg, onBecameDomainOrg, onBecameQuotaOrg } =
    props;

  const isDomainOrgRef = useRef(isDomainOrg);
  const isQuotaOrgRef = useRef(isQuotaOrg);

  useEffect(() => {
    if (isDomainOrgRef.current !== isDomainOrg && isDomainOrg) {
      onBecameDomainOrg();
    }
    isDomainOrgRef.current = isDomainOrg;
  }, [isDomainOrg, onBecameDomainOrg]);

  useEffect(() => {
    if (!isQuotaOrgRef.current && isQuotaOrg) {
      onBecameQuotaOrg();
    }
    isQuotaOrgRef.current = isQuotaOrg;
  }, [isQuotaOrg, onBecameQuotaOrg]);

  return null;
}
