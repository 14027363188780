import React from "react";
import PropTypes from "prop-types";
import Chart from "./chart";
import { TIMES } from "../constants";
import { totalBookCountRenderer, pieChartRenderer } from "./data-renderers";
import "./index.scss";

const Charts = props => {
  const { domain, hiddenCharts, chartWidth, chartHeight, font, auth } = props;

  return (
    <div className="charts">
      {!hiddenCharts.includes("activeUsers") ? (
        <>
          <Chart
            font={font}
            title="Logins"
            legendToggle
            isStacked
            showLegend
            domain={domain}
            timeFilter
            width={chartWidth}
            height={chartHeight}
            endpoint="sessions"
            auth={auth}
            isCustomAnalytics
          />
        </>
      ) : null}
      {!hiddenCharts.includes("totalSessionTime") ? (
        <>
          <Chart
            font={font}
            title="Time using Book Creator (hours)"
            domain={domain}
            timeFilter
            showLegend
            rowFormatter={v => v / 3600}
            width={chartWidth}
            height={chartHeight}
            endpoint="sessions-duration"
            auth={auth}
            isCustomAnalytics
          />
        </>
      ) : null}
      {!hiddenCharts.includes("historicBookCount") ? (
        <Chart
          font={font}
          title="Total books created"
          endpoint="books/count/periodic"
          dataRenderer={totalBookCountRenderer}
          domain={domain}
          timeFilter
          width={chartWidth}
          height={chartHeight}
          auth={auth}
        />
      ) : null}

      <div className="charts__pies">
        {!hiddenCharts.includes("sessionsByOS") ? (
          <>
            <Chart
              font={font}
              halfWidth
              title="Sessions by OS"
              chartType="PieChart"
              domain={domain}
              dataRenderer={pieChartRenderer}
              userFilter
              defaultTimeDimension={TIMES.DAY}
              endpoint="sessions-by-os"
              auth={auth}
              isCustomAnalytics
            />
          </>
        ) : null}
        {!hiddenCharts.includes("loginsByProvider") ? (
          <>
            <Chart
              font={font}
              halfWidth
              title="Logins by provider"
              chartType="PieChart"
              domain={domain}
              dataRenderer={pieChartRenderer}
              userFilter
              defaultTimeDimension={TIMES.DAY}
              endpoint="sessions-by-provider"
              auth={auth}
              isCustomAnalytics
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

Charts.propTypes = {
  domain: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool,
};

Charts.defaultProps = {
  hiddenCharts: [],
};

export default Charts;
