import { useEffect, useState } from "react";
import { ANALYTICS_DOMAIN } from "../../../../../config";
import { API_TIME_FILTERS, TIMES, TIME_FILTERS } from "../../constants";
import { addErrorMessage } from "../../../../../core/notifications/actions";
import { useDispatch } from "react-redux";
import useFetch from "use-http";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
interface CustomAnalyticsProps {
  domain: string;
  isCustomAnalytics: boolean;
  auth: {
    expiresAt: number;
    getAuth: () => null;
    urls: Map<string, any>;
  };
  timeFilter?: boolean;
  timeDimension: typeof TIMES[keyof typeof TIMES];
}

interface ApiHookResponse {
  data: string[];
  isLoading: boolean;
}

export const useCustomAnalyticsApi = (
  endpoint: string,
  props: CustomAnalyticsProps
): ApiHookResponse => {
  const { isCustomAnalytics, auth, timeFilter, timeDimension, domain } = props;

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const { get, response } = useFetch(
    `${ANALYTICS_DOMAIN}/data/app/email-domains/${domain}`
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isCustomAnalytics) {
      return;
    }
    const { expiresAt, urls, getAuth } = auth;

    const resourceAuthExpired = expiresAt <= Date.now();
    const isAuthenticated = expiresAt && !resourceAuthExpired;

    const getData = async () => {
      try {
        setIsLoading(true);
        const timeParam = timeFilter ? `/${TIME_FILTERS[timeDimension]}` : "";
        const allData = await get(`${endpoint}${timeParam}.json?${urls}`);
        if (response.ok) {
          setData(allData);
        }
      } catch (error) {
        dispatch(addErrorMessage("Error fetching custom analytics"));
      }
      setIsLoading(false);
    };
    if (!isAuthenticated) {
      return getAuth();
    }
    getData();
  }, [
    dispatch,
    get,
    endpoint,
    isCustomAnalytics,
    auth,
    response,
    timeDimension,
    timeFilter,
  ]);

  return { data, isLoading };
};

interface DbAnalyticsProps {
  domain: string;
  isDbAnalytics: boolean;
  auth: {
    expiresAt: number;
    getAuth: () => null;
    userUrls: Map<string, any>;
  };
  timeDimension: typeof TIMES[keyof typeof TIMES];
}

const moment = extendMoment(Moment);
const thirtyDaysAgo = moment().subtract(30, "days");
const eighteenMonthsAgo = moment().subtract(18, "months");

export const useDbAnalyticsApi = (
  endpoint: string,
  props: DbAnalyticsProps
): ApiHookResponse => {
  const { isDbAnalytics, timeDimension, domain, auth } = props;
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [requested, setRequested] = useState(false);

  const { get, response } = useFetch(
    `${ANALYTICS_DOMAIN}/users/domains/${encodeURIComponent(domain)}`,
    {
      credentials: "include",
    }
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setRequested(false);
  }, [timeDimension, setRequested]);

  useEffect(() => {
    if (!isDbAnalytics) {
      return;
    }

    const { expiresAt, userUrls, getAuth } = auth;

    const resourceAuthExpired = expiresAt <= Date.now();
    const isAuthenticated = expiresAt && !resourceAuthExpired;

    const getData = async () => {
      try {
        setIsLoading(true);
        setRequested(true);
        const startDate =
          timeDimension === TIMES.DAY
            ? thirtyDaysAgo.format("YYYYMMDD")
            : eighteenMonthsAgo.format("YYYYMMDD");
        const yesterday = moment().subtract(1, "days").format("YYYYMMDD");
        const data = await get(
          `${endpoint}?period=${API_TIME_FILTERS[timeDimension]}&from=${startDate}&to=${yesterday}&${userUrls}`
        );
        if (response.ok) {
          setData(data);
        }
      } catch (error) {
        dispatch(addErrorMessage("Error fetching DB analytics"));
      }
      setIsLoading(false);
    };
    if (!isAuthenticated) {
      return getAuth();
    }
    if (!requested) {
      getData();
    }
  }, [
    dispatch,
    get,
    endpoint,
    auth,
    isDbAnalytics,
    timeDimension,
    response,
    requested,
  ]);

  return { data, isLoading };
};
