import React, { Component } from "react";
import { connect } from "react-redux";
import flow from "lodash.flow";
import { createSelector } from "reselect";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { isAuthenticated, authActions, getAuth } from "../../../core/auth";
import BookCreatorLogoColour from "../../../images/book-creator-logo-colour.png";
import SignIn from "../../components/sign-in/sign-in";
import SignInEmail from "../../components/sign-in/sign-in-with-email";
import Register from "../../components/sign-in/register";
import ForgottenPassword from "../../components/sign-in/forgotten-password";
import "./index.scss";

class SignInPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectTo:
        props.location.state && props.location.state.from
          ? `${props.location.state.from.pathname}${props.location.state.from.search}`
          : "/",
    };
  }

  componentWillReceiveProps(nextProps) {
    this.checkAuth(nextProps);
  }

  componentWillMount() {
    this.checkAuth(this.props);
  }

  checkAuth(props) {
    const { history } = this.props;
    if (props.authenticated) {
      history.push("/");
    }
  }

  render() {
    const { authenticated } = this.props;
    const { redirectTo } = this.state;

    if (authenticated) {
      return <Redirect to={redirectTo} />;
    }

    return (
      <div className="sign-in">
        <div className="sign-in__content">
          <h1 className="sign-in__logo">
            <img src={BookCreatorLogoColour} alt="Book Creator" />
          </h1>
          <Switch>
            <PropsRoute
              exact
              path="/sign-in"
              component={SignIn}
              {...this.props}
            />
            <PropsRoute
              exact
              path="/sign-in/register"
              component={Register}
              {...this.props}
            />
            <PropsRoute
              exact
              path="/sign-in/register/:email"
              component={Register}
              {...this.props}
            />
            <PropsRoute
              path="/sign-in/email"
              component={SignInEmail}
              {...this.props}
            />
            <PropsRoute
              path="/sign-in/email/:email"
              component={SignInEmail}
              {...this.props}
            />
            <PropsRoute
              path="/sign-in/forgotten-password"
              component={ForgottenPassword}
              {...this.props}
            />
            <PropsRoute
              path="/sign-in/forgotten-password/:email"
              component={ForgottenPassword}
              {...this.props}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  isAuthenticated,
  getAuth,
  (authenticated, auth) => ({
    authenticated,
    auth,
  })
);

const mapDispatchToProps = {
  ...authActions,
};

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
};

const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        return renderMergedProps(component, routeProps, rest);
      }}
    />
  );
};

const decorators = flow([
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
]);

export default decorators(SignInPage);
