import React from "react";
import SvgIcon from "../../ui/svg-icon";
import DeleteIcon from "../../../../images/svg/delete.svg";
import "./index.scss";

function ListEditorItem(props) {
  const { text, id, onDelete } = props;

  const handleDelete = evt => {
    onDelete(id);
    evt.preventDefault();
  };

  return (
    <div className="list-editor-item">
      {text}
      <button className="list-editor-item__delete" onClick={handleDelete}>
        <SvgIcon icon={DeleteIcon} name="delete" />
      </button>
    </div>
  );
}

export default ListEditorItem;
