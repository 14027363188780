export const SEARCH_USERS_ATTEMPT = "SEARCH_USERS_ATTEMPT";
export const SEARCH_USERS_RESULT = "SEARCH_USERS_RESULT";
export const SEARCH_USERS_MULTIPLE_RESULT = "SEARCH_USERS_MULTIPLE_RESULT";
export const SEARCH_USERS_NOT_FOUND = "SEARCH_USERS_NOT_FOUND";

export const SET_SHOW_DELETE_USER_OVERLAY = "SET_SHOW_DELETE_USER_OVERLAY";
export const SET_SHOW_IMPERSONATE_USER_OVERLAY =
  "SET_SHOW_IMPERSONATE_USER_OVERLAY";
export const SET_SIGNING_IN_AS_USER = "SET_SIGNING_IN_AS_USER";

export const SET_USER_TO_DELETE = "SET_USER_TO_DELETE";
export const CLEAR_USER_TO_DELETE = "CLEAR_USER_TO_DELETE";

export const USER_DELETED = "USER_DELETED";

export const DELETE_USER_ATTEMPT = "DELETE_USER_ATTEMPT";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

export const RECEIVE_ADDITIONAL_USER_INFO = "RECEIVE_ADDITIONAL_USER_INFO";

export const DOMAIN_SEARCH_ATTEMPT = "DOMAIN_SEARCH_ATTEMPT";
export const DOMAIN_SEARCH_RESULT = "DOMAIN_SEARCH_RESULT";
export const DOMAIN_SEARCH_NO_RESULT = "DOMAIN_SEARCH_NO_RESULT";

export const RESET_USER_SEARCH = "RESET_USER_SEARCH";
export const RESET_USER_STATE = "RESET_USER_STATE";

export const UPDATE_USER_VALUE = "UPDATE_USER_VALUE";
