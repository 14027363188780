import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./index.scss";

class SvgIcon extends Component {
  static propTypes = {
    icon: PropTypes.string,
  };

  render() {
    const { style, name, icon, cssClass, className } = this.props;

    const cssClasses = classNames("icon", {
      [`icon--${name}`]: name,
      [`${cssClass}`]: cssClass,
      className,
    });
    const iconStyle = style ? style : null;

    return (
      <span
        className={cssClasses}
        dangerouslySetInnerHTML={{ __html: icon }}
        style={iconStyle}
      />
    );
  }
}

export default SvgIcon;
