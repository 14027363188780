import React, { Fragment, useEffect, useState } from "react";
import { firebaseFirestore as fs } from "../../../../core/firebase";
import firebase from "firebase/app";
import { withRouter } from "react-router";
import Helmet from "react-helmet";
import Header from "../../header";
import Content from "../../content";
import Search from "./search";

function PixabayImageSearch(props) {
  const [blockedIDs, setBlockedIDs] = useState([]);
  const [blockedTags, setBlockedTags] = useState([]);

  const unBlockById = (id) => {
    fs.doc("pixabayBlocks/images").update({
      ids: firebase.firestore.FieldValue.arrayRemove(id),
    });
  };

  const blockById = (id) => {
    fs.doc("pixabayBlocks/images").update({
      ids: firebase.firestore.FieldValue.arrayUnion(id),
    });
  };

  const unBlockByTag = (tag) => {
    fs.doc("pixabayBlocks/images").update({
      tags: firebase.firestore.FieldValue.arrayRemove(tag),
    });
  };

  const blockByTag = (tag) => {
    fs.doc("pixabayBlocks/images").update({
      tags: firebase.firestore.FieldValue.arrayUnion(tag),
    });
  };

  useEffect(() => {
    const unsubscribe = fs
      .collection("pixabayBlocks")
      .doc("images")
      .onSnapshot((snapshot) => {
        const { ids, tags } = snapshot.data();
        setBlockedIDs(ids || []);
        setBlockedTags(tags || []);
      });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Fragment>
      <Helmet title="Pixabay Image Search" />
      <Header title="Pixabay Image Search" />
      <Content>
        <Search
          {...props}
          blockedIDs={blockedIDs}
          blockedTags={blockedTags}
          blockById={blockById}
          unBlockById={unBlockById}
          blockByTag={blockByTag}
          unBlockByTag={unBlockByTag}
        />
      </Content>
    </Fragment>
  );
}

export default withRouter(PixabayImageSearch);
