const blocklist = [
  "skoletube.dk",
  "borntube.dk",
  "gmail.com",
  "googlemail.com",
  "stavangerskolen.no",
  "hotmail",
  "outlook.com",
  "yahoo.",
  "icloud.com",
  "free.fr",
  "libero.it",
];

export default blocklist;
