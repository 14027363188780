import React, { Component } from "react";
import JSONFormatter from "json-formatter-js";
import Modal from "../../../modal";
import "./index.scss";

export default class AllUserData extends Component {
  componentDidMount() {
    const settings = {
      animateOpen: false,
      animateClose: false,
    };
    this._formatter = new JSONFormatter(this.props.data, 1, settings);
    this._container.appendChild(this._formatter.render());
  }

  render() {
    const { onClose } = this.props;

    return (
      <Modal width={1000} showX onCancel={onClose} cancelText="Done">
        <div
          className="all-user-data"
          ref={(c) => {
            this._container = c;
          }}
        ></div>
      </Modal>
    );
  }
}
