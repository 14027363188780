import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "../../../modal";
import { organisationsActions } from "../../../../../core/organisations";
import { connect, useDispatch } from "react-redux";
import { notificationsActions } from "../../../../../core/notifications";
import { addErrorMessage } from "../../../../../core/notifications/actions";
import {
  addDomain,
  previewDomain,
} from "../../../../../core/organisations/orgs-api";
import { getOrganisationById } from "../../../../../core/organisations/actions";

function DomainPreview(props) {
  const { domains, orgId, setDomainConfirmations, setOrgSaving } = props;

  const [domainsToPreview, setDomainsToPreview] = useState(domains);
  const [saving, setSaving] = useState(false);
  const [preview, setPreview] = useState(null);
  const requestedRef = useRef();
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    setDomainConfirmations(null);
    setOrgSaving(false);
  }, [setDomainConfirmations, setOrgSaving]);

  useEffect(() => {
    if (requestedRef.current) return;
    requestedRef.current = true;
    const getPreview = async () => {
      const previewResponse = await previewDomain({
        orgId,
        domain: domainsToPreview[0],
      });

      if (!previewResponse.ok) {
        dispatch(addErrorMessage(previewResponse.message));
        handleClose();
      } else {
        setPreview(previewResponse.data);
      }
    };
    getPreview();
  }, [orgId, domainsToPreview, dispatch, handleClose]);

  const confirm = async () => {
    setSaving(true);
    await addDomain({
      orgId,
      domain: domainsToPreview[0],
    });
    setSaving(false);
    // TODO: remove when we update straight via orgs api
    dispatch(getOrganisationById(orgId));
    requestedRef.current = false;
    if (domainsToPreview.length === 1) {
      handleClose();
    } else {
      let d = [...domainsToPreview];
      d.shift();
      setDomainsToPreview(d);
    }
  };

  return (
    <Modal
      title="Domain preview"
      modal
      loadingMessage={
        !preview ? "Loading preview" : saving ? "Saving domain" : null
      }
      onCancel={handleClose}
      onConfirm={confirm}
      confirmText="Confirm">
      {preview ? (
        <div>
          Adding the domain {domainsToPreview[0]} will affect the following
          users:
          <ul>
            <li style={{ marginBottom: 10 }}>
              {preview.alreadyMembers} users are already in this org with this
              domain
            </li>
            <li style={{ marginBottom: 10 }}>
              {preview.addedMembers} users with this domain will be added
            </li>
            <li style={{ marginBottom: 10 }}>
              {preview.invitedMembersFromOtherOrgs} users with this domain will
              be sent an invite as they are already a member of another
              organisation
            </li>
            <li style={{ marginBottom: 10 }}>
              {preview.invitedMembersWithPersonalSubs} users with this domain
              that will be sent an invite as they have a personal subscription
            </li>
          </ul>
        </div>
      ) : null}
    </Modal>
  );
}

const mapDispatchToProps = {
  ...organisationsActions,
  ...notificationsActions,
};

export default connect(null, mapDispatchToProps)(DomainPreview);
