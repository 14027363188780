import React from "react";
import Section from "../section";
import Switch from "src/views/components/forms/switch";

interface OrganisationSettingsProps {
  demo?: boolean;
  onDemoChange: (value: boolean) => void;
  isPilot?: boolean;
  onPilotChange: (value: boolean) => void;
  reseller?: boolean;
  onResellerChange: (value: boolean) => void;
}

export default function OrganisationSettingsPicker(
  props: OrganisationSettingsProps
) {
  const {
    demo,
    onDemoChange,
    isPilot,
    onPilotChange,
    reseller,
    onResellerChange,
  } = props;

  return (
    <Section title="Settings">
      <div className="organisation-features">
        <Switch
          label="Pilot"
          value={isPilot}
          onChange={onPilotChange}
          flush
          notopborder
        />
        <Switch label="Demo" value={demo} onChange={onDemoChange} flush />
        <Switch
          label="Reseller"
          value={reseller}
          onChange={onResellerChange}
          flush
          nobottomborder
        />
      </div>
    </Section>
  );
}
