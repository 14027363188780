import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";
import SvgIcon from "../svg-icon";
import "./index.scss";

export default class PageControl extends Component {
  static propTypes = {
    active: PropTypes.bool,
    activeStyle: PropTypes.object,
    alignMenuToPage: PropTypes.bool,
    color: PropTypes.string,
    customChild: PropTypes.object,
    css: PropTypes.string,
    disabled: PropTypes.bool,
    elevate: PropTypes.bool,
    hardLink: PropTypes.bool,
    highlight: PropTypes.bool,
    icon: PropTypes.string,
    iconName: PropTypes.string,
    initials: PropTypes.string,
    isFlatIconButton: PropTypes.bool,
    link: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    styleActive: PropTypes.object,
    text: PropTypes.string,
    title: PropTypes.string,
    wrapperCss: PropTypes.string,
  };

  render() {
    const {
      active,
      activeStyle,
      alignMenuToPage,
      children,
      color,
      css,
      customChild,
      disabled,
      elevate,
      hardLink,
      highlight,
      icon,
      iconName,
      img,
      initials,
      isFlatIconButton,
      link,
      onClick,
      text,
      title,
      wrapperCss,
      style,
    } = this.props;

    const menu = children;

    const cssClass = classNames("page-control", {
      [`${css}`]: css,
      "page-control--icon": icon,
      "page-control--disabled": disabled,
      "page-control--elevate": elevate,
      "page-control--text": text,
      "page-control--active": active,
      "page-control--highlight": highlight,
      "page-control--has-menu": menu,
      "page-control--flat-icon": isFlatIconButton,
    });

    const wrapperClass = classNames("page-control-wrapper", {
      "page-control-wrapper--passive": alignMenuToPage,
      [`${wrapperCss}`]: wrapperCss,
    });

    let styleProp = {
      ...style,
    };

    if (active && activeStyle) {
      styleProp = {
        ...style,
        ...activeStyle,
      };
    }

    const iconItem = icon ? (
      <SvgIcon style={{ fill: color }} icon={icon} name={iconName} ariaHidden />
    ) : null;
    const textItem = text ? (
      <span className="page-control__label">{text}</span>
    ) : null;
    const initialsItem = initials ? (
      <span className="page-control__initials">{initials}</span>
    ) : null;
    const imgItem = img ? (
      <img src={img} className="page-control__img" alt="" />
    ) : null;

    const sharedProps = {
      className: cssClass,
      disabled,
      title,
      ref: (c) => {
        this._link = c;
      },
      style: styleProp,
    };

    const contents = (
      <Fragment>
        {iconItem}
        {textItem}
        {initialsItem}
        {imgItem}
        {customChild}
      </Fragment>
    );

    const control = link ? (
      hardLink ? (
        <a {...sharedProps} href={link} onClick={onClick}>
          {contents}
        </a>
      ) : (
        <Link {...sharedProps} to={link} onClick={onClick}>
          {contents}
        </Link>
      )
    ) : (
      <button
        {...sharedProps}
        onClick={(e) => {
          e.preventDefault();
          if (!disabled) {
            onClick(e);
          }
        }}
      >
        {contents}
      </button>
    );

    if (menu) {
      return (
        <div className={wrapperClass}>
          {control}
          {active ? menu : null}
        </div>
      );
    } else {
      return control;
    }
  }
}
