import React from "react";
import ReactDOM from "react-dom";
import { initAuth } from "./core/auth";
import { createBrowserHistory } from "history";
import configureStore from "./core/store";
import Root from "./views/root";

const store = configureStore();
const history = createBrowserHistory();
const rootElement = document.getElementById("book-creator-mission-control");

function render(Root) {
  ReactDOM.render(<Root history={history} store={store} />, rootElement);
}

initAuth(store.dispatch)
  .then(() => render(Root))
  .catch((error) => console.error(error)); // eslint-disable-line no-console
