import React, { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import Input from "../input";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";

class DatePicker extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    dateFormat: "do MMMM yyyy",
  };

  render() {
    const { value, onChange, dateFormat, onFocus, onBlur } = this.props;
    return (
      <ReactDatePicker
        selected={value ? value.toDate() : null}
        onChange={(change) => onChange(moment(change))}
        onFocus={onFocus}
        onBlur={onBlur}
        className="input__input"
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        dateFormat={dateFormat}
      />
    );
  }
}

export default Input(DatePicker);
