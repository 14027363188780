import React, { Component } from "react";
import "./index.scss";

export default class NoSearchResults extends Component {
  render() {
    const { searchTerm } = this.props;

    return (
      <div className="no-search-results ">No user found for "{searchTerm}"</div>
    );
  }
}
