import firebase from "firebase";
import { firebaseAuth, firebaseDb } from "../firebase";
import Cookies from "js-cookie";
import { AUTH_COOKIE_NAME, AUTH_COOKIE_DOMAIN } from "../../config";
import * as OrgsAPI from "../organisations/orgs-api";

import {
  INIT_AUTH,
  SIGN_IN_ERROR,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  CREATE_USER_ATTEMPT,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  CLEAR_AUTH_ERROR,
  CACHE_AUTH_TOKEN,
  SET_USER_ORG_ROLES,
  SET_SERVER_TIME,
} from "./action-types";

import { getServerTime } from "./selectors";

const cookieAttributes = {
  secure: true,
  domain: AUTH_COOKIE_DOMAIN,
  expires: 1 / 24,
};

export function initAuth(user) {
  return dispatch => {
    if (user) {
      dispatch(onSignIn({ user }));
    } else {
      dispatch({ type: INIT_AUTH });
      // dispatch(doServerAuth());
    }
    dispatch(initServerTime());
    // Check token when returning from background tab
    document.addEventListener("visibilitychange", () => {
      if (!document.hidden) {
        dispatch(checkAuthCookie());
      }
    });
  };
}

function authenticate(provider) {
  return dispatch => {
    firebaseAuth
      .signInWithPopup(provider)
      .then(result => {
        dispatch(onSignIn({ user: result.user }));
      })
      .catch(error => {
        console.error(error);
        dispatch(signInError(error));
      });
  };
}

export function signInError(error) {
  return {
    type: SIGN_IN_ERROR,
    payload: error,
  };
}

export function onSignIn(payload) {
  return dispatch => {
    const { user } = payload;
    const { uid, photoURL } = user;
    firebaseDb
      .ref(`/users/${uid}/`)
      .once("value")
      .then(snapshot => {
        const userData = snapshot && snapshot.val();
        const { bcAdminRole } = userData;
        if (!bcAdminRole || bcAdminRole > 100) {
          return dispatch(handleUnauthorisedUser());
        }
        dispatch(
          signInSuccess({
            ...user,
            bcAdminRole,
            email: userData ? userData.email : user ? user.email : null,
            displayName: userData
              ? userData.displayName
              : user
              ? user.displayName
              : null,
            photoURL,
          })
        );
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function signInSuccess(user) {
  return dispatch => {
    // Manually refresh the token after 1 hour
    if (user.email.indexOf("@bookcreator.com") < 0) {
      dispatch(handleUnauthorisedUser());
    } else {
      dispatch(getAuthToken(true));
      setInterval(getAuthToken, 1000 * 60 * 45);
    }
    // TODO: refactor the api as hooks instead of single functions and pull the userId from the store
    OrgsAPI.setAuthedUser(user.uid);
    dispatch({
      type: SIGN_IN_SUCCESS,
      payload: user,
    });
  };
}

function handleUnauthorisedUser() {
  return dispatch => {
    dispatch(
      signInError({ message: "You are not authorised to access this site" })
    );
    dispatch(signOut({ preserveError: true }));
  };
}

export function createUserAttempt(user) {
  return {
    type: CREATE_USER_ATTEMPT,
    payload: user,
  };
}

export function createUserSuccess(user) {
  return {
    type: CREATE_USER_SUCCESS,
    payload: user,
  };
}

export function createUserError(error) {
  return {
    type: CREATE_USER_ERROR,
    payload: error,
  };
}

export function signInWithEmailAndPassword(payload) {
  return dispatch => {
    const { email, password } = payload;
    firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(user => {
        dispatch(onSignIn({ user }));
      })
      .catch(error => {
        dispatch(signInError(error));
      });
  };
}

export function createUserWithEmailAndPassword(payload) {
  const { email, password, displayName } = payload;
  return dispatch => {
    dispatch(createUserAttempt(payload));
    firebaseAuth
      .createUserWithEmailAndPassword(email, password)
      .then(user => {
        dispatch(onSignIn({ user: { ...user, displayName } }));
      })
      .catch(error => {
        dispatch(createUserError(error));
      });
  };
}

export function checkAuthCookie() {
  return dispatch => {
    const authCookie = Cookies.get(AUTH_COOKIE_NAME);
    if (!authCookie) {
      dispatch(getAuthToken());
    }
  };
}

export function getAuthToken(changeListener) {
  return async dispatch => {
    const token = await firebaseAuth.currentUser.getIdToken(true);
    dispatch(cacheAuthToken(token));
    if (changeListener) {
      firebaseAuth.onIdTokenChanged(async user => {
        if (user) {
          const token = await firebaseAuth.currentUser.getIdToken();
          dispatch(cacheAuthToken(token));
        }
      });
    }
  };
}

function cacheAuthToken(token) {
  return dispatch => {
    if (token) {
      Cookies.set(AUTH_COOKIE_NAME, token, cookieAttributes);
      const payload = JSON.parse(atob(token.split(".")[1]));
      dispatch({
        type: SET_USER_ORG_ROLES,
        payload: payload["bc_orgs"],
      });
      dispatch({
        type: CACHE_AUTH_TOKEN,
        payload: token,
      });
    }
  };
}

export function signInWithGoogle() {
  let provider = new firebase.auth.GoogleAuthProvider();
  return authenticate(provider);
}

export function initServerTime() {
  return (dispatch, getState) => {
    const interval = 10000;
    // Manually increment server time every 10 seconds to prevent spamming the server
    setInterval(() => {
      const time = getServerTime(getState());
      dispatch({ type: SET_SERVER_TIME, payload: time + interval });
    }, interval);
    // Sync server time every 15 minutes
    setInterval(() => {
      dispatch(setServerTime());
    }, 1000 * 60 * 15);
    dispatch(setServerTime());
  };
}

export function setServerTime() {
  return dispatch => {
    firebaseDb
      .ref("/.info/serverTimeOffset")
      .once("value")
      .then(data => {
        dispatch({ type: SET_SERVER_TIME, payload: data.val() + Date.now() });
      });
  };
}

export function signOut(payload) {
  return dispatch => {
    firebaseAuth.signOut().then(() => {
      dispatch(signOutSuccess(payload));
    });
  };
}

export function clearAuthError() {
  return {
    type: CLEAR_AUTH_ERROR,
  };
}

export function signOutSuccess(payload) {
  Cookies.remove(AUTH_COOKIE_NAME, cookieAttributes);
  return {
    type: SIGN_OUT_SUCCESS,
    payload,
  };
}
