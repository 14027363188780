import React, { Component } from "react";
import classNames from "classnames";
import "./index.scss";

class Loader extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  render() {
    const cssClass = classNames("loader", {
      [`loader--${this.props.css}`]: this.props.css,
      "loader--large": this.props.large,
      "loader--small": this.props.small,
      "loader--tiny": this.props.tiny,
      "loader--center": this.props.center,
      [`loader--${this.props.colour}`]: this.props.colour,
    });

    return (
      <div className={cssClass} style={this.props.style}>
        <div className="loader-inner ball-pulse-sync">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}

export default Loader;
