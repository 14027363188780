import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import {
  Route,
  BrowserRouter as Router,
  Redirect,
  Switch,
} from "react-router-dom";
import { isAuthenticated } from "../core/auth";
import App from "./app";
import SignIn from "./pages/sign-in";

export default function Root({ history, store }) {
  return (
    <Provider store={store}>
      {/* ConnectedRouter will use the store from Provider automatically */}
      <Router store={store}>
        <Switch>
          <Route path="/sign-in" component={SignIn} />
          <PrivateRoute path="/" component={App} store={store} />
        </Switch>
      </Router>
    </Provider>
  );
}

Root.propTypes = {
  history: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated(rest.store.getState()) ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect
          to={{
            pathname: "/sign-in",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);
