import React from "react";
import { useDispatch } from "react-redux";
import {
  blockImageByUrl,
  unBlockImageById,
} from "../../../../../../core/image-search/actions";
import Result from "./result";
import "./index.scss";

function GoogleImageSearchResults(props) {
  const { blockedURLs, results, obscureblocked } = props;
  const dispatch = useDispatch();

  const handleToggleURL = ({ id, link }) => {
    if (link) {
      dispatch(blockImageByUrl({ link }));
    } else {
      dispatch(unBlockImageById({ id }));
    }
  };

  return (
    <div className="image-search-results">
      {results.map((result, i) => {
        return (
          <Result
            {...result}
            blockedURLs={blockedURLs}
            obscureblocked={obscureblocked}
            onToggleURL={handleToggleURL}
            key={`${result.link}-${i}`}
          />
        );
      })}
    </div>
  );
}

export default GoogleImageSearchResults;
