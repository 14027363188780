import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import flow from "lodash.flow";
import { getHasEngineerPrivileges } from "../../../core/auth";
import { authActions } from "../../../core/auth";
import { withRouter } from "react-router";
import Nav from "./";

function NavContainer(props) {
  return <Nav {...props} />;
}

const mapDispatchToProps = {
  ...authActions,
};

const mapStateToProps = createSelector(
  getHasEngineerPrivileges,
  hasEngineerPrivileges => ({
    hasEngineerPrivileges,
  })
);

const decorators = flow([
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
]);

export default decorators(NavContainer);
