import React, { useEffect, useRef, useState } from "react";
import { firebaseDbApps } from "../../../../../core/firebase";
import { unwrap } from "../../../../../utils/firebase";
import Search from "../search";

function NounProjectMain(props) {
  const [blockedIDs, setBlockedIDs] = useState([]);
  const [blockedSlugs, setBlockedSlugs] = useState([]);
  const containerRef = useRef();

  useEffect(() => {
    firebaseDbApps.ref("/blocking/g/noun/ids").on("value", snapshot => {
      if (containerRef.current) {
        setBlockedIDs(unwrap(snapshot));
      }
    });
    firebaseDbApps.ref("/blocking/tags").on("value", snapshot => {
      if (containerRef.current) {
        setBlockedSlugs(unwrap(snapshot));
      }
    });
    return () => {
      firebaseDbApps.ref("/blocking/g/noun/ids").off();
      firebaseDbApps.ref("/blocking/tags").off();
    };
  }, []);

  return (
    <div ref={containerRef}>
      <Search blockedIDs={blockedIDs} blockedSlugs={blockedSlugs} />
    </div>
  );
}

export default NounProjectMain;
