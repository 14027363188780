import React, { Component } from "react";
import classNames from "classnames";
import InlineError from "./inline-error";
import uuid from "node-uuid";
import "./input.scss";

const Input = Input =>
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isFocused: false,
      };
      this.focus = this.focus.bind(this);
    }

    componentDidMount() {
      const { autoFocus } = this.props;
      this._isMounted = true;
      if (autoFocus) {
        this.focus();
      }
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    handleFocus = e => {
      const { onFocus } = this.props;
      clearTimeout(this._timeout);
      this.setState({
        isFocused: true,
      });
      if (onFocus) {
        onFocus(e);
      }
    };

    handleBlur = e => {
      const { onBlur } = this.props;
      this._timeout = setTimeout(() => {
        if (this._isMounted) {
          this.setState({
            isFocused: false,
          });
        }
      }, 20);
      if (onBlur) {
        onBlur(e);
      }
    };

    focus() {
      this._input.focus();
    }

    render() {
      const { isFocused } = this.state;

      const {
        disabled,
        label,
        labelHelper,
        value,
        error,
        shaded,
        optional,
        prefix,
      } = this.props;

      const id = this.props.id ? this.props.id : uuid.v4();

      const isFilled =
        (value !== undefined && value !== null && typeof value !== "string") ||
        (typeof value === "string" && value.length);
      const isInvalid = error && error.length;

      const inputClass = classNames("input", {
        "input--disabled": disabled,
        "input--focused": isFocused,
        "input--filled": isFilled,
        "input--error": isInvalid,
        "input--shaded": shaded,
        "input--prefixed": prefix,
      });

      return (
        <div className={inputClass}>
          <label className="input__label" htmlFor={id}>
            {label} {labelHelper ? <small>{labelHelper}</small> : null}
            {optional ? <small>(optional)</small> : null}
          </label>
          {prefix ? <span className="input__prefix">{prefix}</span> : null}
          <Input
            {...this.props}
            {...this.state}
            ref={c => {
              this._input = c;
            }}
            onFocus={this.handleFocus.bind(this)}
            onBlur={this.handleBlur.bind(this)}
            id={id}
          />
          {isInvalid ? <InlineError message={error} /> : null}
        </div>
      );
    }
  };

export default Input;
