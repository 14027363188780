import React, { Component } from "react";
import Helmet from "react-helmet";
import {
  getEmailError,
  getPasswordError,
  getFullNameError,
} from "../../../utils/validators";
import { TERMS_OF_SERVICE_URL } from "../../../constants";
import Form from "../forms/form";
import FormButton from "../forms/form-button";
import TextBox from "../forms/text-box";
import Password from "../forms/password";
import Message from "../ui/message";

class Register extends Component {
  constructor(props) {
    super(props);

    const { email } = props.computedMatch.params;

    this.state = {
      error: null,
      emailAddress: email ? email : "",
      fullName: "",
      submitted: false,
      submitting: false,
    };
  }

  componentWillUnmount() {
    this.props.clearAuthError();
  }

  updateEmail(nextEmail) {
    this.setState({
      emailAddress: nextEmail,
    });
  }

  updateName(nextName) {
    this.setState({
      fullName: nextName,
    });
  }

  updatePassword(nextPassword) {
    this.setState({
      password: nextPassword,
    });
  }

  handleSubmit(e) {
    const { emailAddress, fullName, password } = this.state;

    const { createUserWithEmailAndPassword } = this.props;

    this.setState({
      submitted: true,
    });

    if (this.isValid()) {
      this.setState({
        submitting: true,
      });
      createUserWithEmailAndPassword({
        email: emailAddress,
        displayName: fullName,
        password: password,
      });
    }
    e.preventDefault();
  }

  isValid() {
    const { emailAddress, fullName, password } = this.state;
    return (
      !getEmailError(emailAddress) &&
      !getPasswordError(password) &&
      !getFullNameError(fullName)
    );
  }

  render() {
    const {
      emailAddress,
      fullName,
      password,
      submitted,
      submitting,
    } = this.state;

    const { error, computedMatch } = this.props;

    const { email } = computedMatch.params;

    const emailError = submitted && getEmailError(email);
    const fullNameError = submitted && getFullNameError(fullName);
    const passwordError = submitted && getPasswordError(password);

    const accountExists = error && error.code === "auth/email-already-in-use";

    return (
      <Form
        header="Create account"
        onSubmit={this.handleSubmit.bind(this)}
        autocomplete={false}
      >
        <Helmet title="Register an account to use Book Creator" />
        <div className="form__content">
          <TextBox
            label="Email"
            type="email"
            onChange={this.updateEmail.bind(this)}
            value={emailAddress}
            autoFocus={!email}
            error={emailError}
          />

          <TextBox
            label="First and last name"
            onChange={this.updateName.bind(this)}
            value={fullName}
            autoFocus={email}
            error={fullNameError}
          />

          <Password
            label="Password"
            onChange={this.updatePassword.bind(this)}
            value={password}
            error={passwordError}
          />
        </div>
        <div className="form__actions">
          <small className="form__actions-smallprint">
            <span>
              By submitting this form, you are indicating that you agree to the{" "}
            </span>
            <a href={TERMS_OF_SERVICE_URL} target="_blank" rel="noreferrer">
              Terms of Service
            </a>
            .
          </small>
          {error ? (
            <Message type="error" center>
              {error.message}
            </Message>
          ) : null}
          <FormButton flat href="/sign-in" text="Cancel" />
          {accountExists ? (
            <FormButton flat href="/sign-in/email" text="Sign in" />
          ) : null}
          <FormButton
            type="submit"
            text="Submit"
            loading={submitting && !error}
          />
        </div>
      </Form>
    );
  }
}

export default Register;
