export const INIT_AUTH = "INIT_AUTH";

export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";

export const CREATE_USER_ATTEMPT = "CREATE_USER_ATTEMPT";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";
export const CLEAR_AUTH_ERROR = "CLEAR_AUTH_ERROR";

export const CACHE_AUTH_TOKEN = "CACHE_AUTH_TOKEN";
export const SET_USER_ORG_ROLES = "SET_USER_ORG_ROLES";
export const SET_SERVER_TIME = "SET_SERVER_TIME";
