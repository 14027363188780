import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

function InlineButton(props) {
  const { children, onClick, style } = props;

  return (
    <button
      type="button"
      className="inline-button"
      onClick={onClick}
      style={style}
    >
      {children}
    </button>
  );
}

InlineButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default InlineButton;
