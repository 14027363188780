import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import InlineButton from "../../../../../ui/inline-button";
import { firebaseDb } from "../../../../../../../core/firebase";
import "./index.scss";

function ResultCardName(props) {
  const { addErrorMessage, displayName, uid, updateUserValueInStore } = props;

  const [hovered, setHovered] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editName, setEditName] = useState("");
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, editing]);

  const startEditing = () => {
    setEditName(displayName);
    setEditing(true);
  };

  const handleEditClick = () => {
    startEditing();
  };

  const reset = () => {
    setEditName("");
    setEditing(false);
  };

  const cancel = () => {
    reset();
  };

  const save = () => {
    if (editName.length === 0) {
      addErrorMessage("Names can't be empty");
      return;
    }
    firebaseDb
      .ref(`/users/${uid}`)
      .update({ displayName: editName })
      .then(() => {
        updateUserValueInStore({
          uid,
          location: "displayName",
          value: editName,
        });
        reset();
      })
      .catch(err => {
        addErrorMessage(err?.message || "Something went wrong saving the name");
        console.error(err);
      });
  };

  const handleKeyDown = evt => {
    if (evt.key === "Escape") cancel();
    if (evt.key === "Enter") save();
  };

  const missingName = !displayName || displayName.length === 0;

  return (
    <div
      className="result-car-display-name"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      {editing ? (
        <div className="result-card-display-name__edit">
          <input
            type="text"
            value={editName}
            onChange={evt => setEditName(evt.target.value)}
            onKeyDown={handleKeyDown}
            className="result-card-display-name__input"
            ref={inputRef}
          />
          <InlineButton
            style={{ marginLeft: 10, marginBottom: -2 }}
            onClick={save}>
            Save
          </InlineButton>
          <InlineButton
            style={{ marginLeft: 10, marginBottom: -2 }}
            onClick={cancel}>
            Cancel
          </InlineButton>
        </div>
      ) : (
        <h1 className="result-card-display-name__read">
          <span className="result-card-display-name__value">{displayName}</span>{" "}
          {hovered || missingName ? (
            <InlineButton
              style={{ marginLeft: missingName ? -4 : 10, marginBottom: -2 }}
              onClick={handleEditClick}>
              {missingName ? "Add name" : "Change"}
            </InlineButton>
          ) : null}
        </h1>
      )}
    </div>
  );
}

ResultCardName.propTypes = {
  displayName: PropTypes.string.isRequired,
  addErrorMessage: PropTypes.func.isRequired,
  uid: PropTypes.string.isRequired,
  updateUserValueInStore: PropTypes.func.isRequired,
};

export default ResultCardName;
