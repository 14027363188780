import superagent from "superagent";
import { BOOKCREATOR_API_URL } from "../../config";
import { firebaseDbBlocks } from "../firebase/firebase";
import {
  DOWNGRADE_ATTEMPT,
  DOWNGRADE_ERROR,
  DOWNGRADE_SUCCESS,
  SET_DOWNGRADING_ERROR,
  CLEAR_DOWNGRADING_SUCCESS,
  SET_SHOW_DOWNGRADE_MODAL,
  SET_USER_TO_DOWNGRADE,
} from "./action-types";

import { resetUserSearch, searchUsers } from "../users/actions";

export function doDowngrade(userId, blockUpgrade) {
  return (dispatch, getState) => {
    dispatch({ type: DOWNGRADE_ATTEMPT });
    superagent
      .post(`${BOOKCREATOR_API_URL}/users/v2/${userId}/convert-to-student`)
      .withCredentials()
      .end(async (err, res) => {
        if (err) {
          const errorMessage =
            err.response && err.response.body && err.response.body.message
              ? err.response.body.message
              : "Something went wrong downgrading the account";
          dispatch({ type: DOWNGRADE_ERROR, payload: errorMessage });
        } else {
          dispatch(resetUserSearch());
          dispatch({
            type: DOWNGRADE_SUCCESS,
          });
          if (blockUpgrade) {
            await firebaseDbBlocks.ref(`/user/${userId}`).set(Date.now());
          }
          setTimeout(() => dispatch(searchUsers({ searchTerm: userId })), 0);
        }
      });
  };
}

export function setDowngradingError(payload) {
  return {
    type: SET_DOWNGRADING_ERROR,
    payload,
  };
}
export function clearDowngradingSuccess() {
  return {
    type: CLEAR_DOWNGRADING_SUCCESS,
  };
}

export function setUserToDowngrade(payload) {
  return {
    type: SET_USER_TO_DOWNGRADE,
    payload,
  };
}

export function setShowDowngradeModal(payload) {
  return {
    type: SET_SHOW_DOWNGRADE_MODAL,
    payload,
  };
}
