import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useClipboard } from "use-clipboard-copy";
import Plan from "../../plan";
import VerifiedBadge from "../../../../verified-badge";
import MoreOptionsMenu from "../../../../ui/more-options-menu";
import MiniMenuItem from "../../../../ui/mini-menu/item";
import { Link } from "react-router-dom";
import ChangeEmailOverlay from "./change-email";
import VerifyEmailOverlay from "./verify-email";
import ConvertToPassword from "./convert-to-password";
import Provider from "./provider";
import Collaboration from "./collaboration";
import StripeTransfer from "./stripe-transfer";
import { BOOKCREATOR_APP_URL } from "../../../../../../config";
import FeatureFlags from "./feature-flags";
import ManagedStudents from "./managed-students";
import BlockStatus from "./block-status";

const DATE_FORMAT = "Do MMM YYYY h:mma";

const EXPERT_TYPES = [
  {
    key: "ambassador",
    name: "Ambassador",
  },
  {
    key: "GCI",
  },
  {
    key: "MIEE",
  },
  {
    key: "ADE",
  },
];

function ResultCardBody(props) {
  const {
    adminOnly,
    auth,
    badges,
    bookQuota,
    collaborationTrialEnded,
    collaborationTrialEnds,
    collaborationTrialStarted,
    email,
    emailVerified,
    expert,
    isAmbassador,
    isTeacher,
    lastTeacherToStudentConversion,
    libraries,
    libraryQuota,
    loginProvider,
    managedStudents,
    metadata,
    organisations,
    providerData,
    preferences,
    serverTime,
    stripe,
    uid,
    //
    addErrorMessage,
    addNotification,
    handleExpertChange,
    onShowAllData,
    refreshUser,
    updateUserValueInStore,
  } = props;

  const [showEmailChange, setShowEmailChange] = useState(false);
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [showConvertToPassword, setShowConvertToPassword] = useState(false);
  const clipboard = useClipboard();
  const userEmail = email || auth.email;
  const isSkoleTubeUser = !!(
    loginProvider === "skoletube.dk" ||
    (loginProvider &&
      typeof loginProvider === "object" &&
      Object.keys(loginProvider).indexOf("skoletube:dk") > -1)
  );
  const isBorneTubeUser = !!(
    loginProvider === "bornetube.dk" ||
    (loginProvider &&
      typeof loginProvider === "object" &&
      Object.keys(loginProvider).indexOf("bornetube:dk") > -1)
  );

  let adminOrgs, memberOrgs, libs;
  if (organisations?.adminOf?.length) {
    adminOrgs = organisations.adminOf.map(org => {
      return (
        <span key={org.id} style={{ display: "block" }}>
          <span style={{ display: "block" }}>
            {org.name}&nbsp;
            <span style={{ fontSize: 10, color: "#888" }}>
              (Admin {org.role}
              {org.pending ? " - pending" : ""})
            </span>
          </span>
          <Link
            to={`/organisations/${org.id}/edit`}
            key={org.id}
            style={{ display: "inline-block" }}>
            {org.id}
          </Link>
        </span>
      );
    });
  }

  if (organisations?.memberOf?.length) {
    memberOrgs = organisations.memberOf.map(org => {
      return (
        <span key={org.id} style={{ display: "block" }}>
          <span style={{ display: "block" }}>
            {org.name}&nbsp;
            {org.role ? (
              <span style={{ fontSize: 10, color: "#888" }}>
                ({org.role}
                {org.pending ? " - pending" : ""})
              </span>
            ) : null}
          </span>
          <Link
            to={`/organisations/${org.id}/edit`}
            key={org.id}
            style={{ display: "block" }}>
            {org.id}
          </Link>
        </span>
      );
    });
  }

  if (libraries) {
    libs = libraries.map(lib => {
      const owner = lib.users.filter(user => user.role === 1)[0] || {};
      return (
        <span
          key={lib.id}
          style={{
            display: "block",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}>
          <Link to={`/libraries/${lib.id}`}>{lib.name}</Link> (
          {lib.users.length} {lib.users.length === 1 ? "user" : "users"})&nbsp;
          {lib.archived ? <span>(Archived)&nbsp;</span> : null}
          {owner.userId === uid ? (
            "Owner"
          ) : (
            <Link to={`/users/${owner.userId}`}>{owner.userId}</Link>
          )}
        </span>
      );
    });
  }

  const getLastSignIn = () => {
    if (loginProvider && typeof loginProvider === "object") {
      const t = Math.max.apply(Math, Object.values(loginProvider));
      return moment(t).format(DATE_FORMAT);
    } else if (metadata?.lastSignInTime) {
      return moment(metadata.lastSignInTime).format(DATE_FORMAT);
    }
    return null;
  };

  const handleEmailChangeComplete = e => {
    refreshUser(uid);
  };

  const copyLinkToPasswordReset = () => {
    const passwordResetLink = encodeURI(
      `${BOOKCREATOR_APP_URL}/sign-in/forgotten-password/${email}`
    );
    clipboard.copy(passwordResetLink);
    addNotification("Copied to clipboard");
  };

  const canChangeToPasswordProvider =
    (loginProvider === "google.com" ||
      loginProvider === "office365" ||
      (typeof loginProvider === "object" &&
        (Object.keys(loginProvider).indexOf("google:com") > -1 ||
          Object.keys(loginProvider).indexOf("office365") > -1))) &&
    typeof loginProvider === "object" &&
    Object.keys(loginProvider).indexOf("password") === -1;

  const isPasswordProvider = loginProvider && loginProvider.password;

  const showEmailOptionsMenu =
    !adminOnly &&
    (email ||
      !emailVerified ||
      canChangeToPasswordProvider ||
      isPasswordProvider);

  return (
    <div className="result-card__body">
      <dl className="result-card__details">
        {userEmail ? (
          <Fragment>
            <dt style={{ paddingTop: 12 }}>Email:</dt>
            <dd className="result-card__email">
              <span className="result-card__email-value">{userEmail}</span>
              <VerifiedBadge verified={emailVerified} />
              {showEmailOptionsMenu ? (
                <MoreOptionsMenu style={{ marginLeft: 10 }}>
                  {email ? (
                    <MiniMenuItem onClick={() => setShowEmailChange(true)}>
                      Change email address
                    </MiniMenuItem>
                  ) : null}
                  {!emailVerified ? (
                    <MiniMenuItem onClick={() => setShowVerifyEmail(true)}>
                      Verify
                    </MiniMenuItem>
                  ) : null}
                  {canChangeToPasswordProvider ? (
                    <MiniMenuItem
                      onClick={() => setShowConvertToPassword(true)}>
                      Convert to password
                    </MiniMenuItem>
                  ) : null}
                  {isPasswordProvider ? (
                    <MiniMenuItem onClick={copyLinkToPasswordReset}>
                      Copy link to password reset
                    </MiniMenuItem>
                  ) : null}
                </MoreOptionsMenu>
              ) : null}
            </dd>
          </Fragment>
        ) : null}
        <dt style={{ paddingTop: 14 }}>User type:</dt>
        <dd className="result-card__plan">
          {adminOnly ? "Admin only" : isTeacher ? "Teacher" : "Student"}
          {!isTeacher && !adminOnly && userEmail ? (
            <BlockStatus email={userEmail} userId={uid} />
          ) : null}
          {isTeacher && !isSkoleTubeUser && !isBorneTubeUser ? (
            <Plan uid={uid} bookQuota={bookQuota} libraryQuota={libraryQuota} />
          ) : null}
          {isSkoleTubeUser ? " (Skoletube)" : null}
          {isBorneTubeUser ? " (Bornetube)" : null}
        </dd>
        {lastTeacherToStudentConversion ? (
          <Fragment>
            <dt>Last converted from teacher to student at:</dt>
            <dd>
              {metadata
                ? moment(lastTeacherToStudentConversion.at).format(DATE_FORMAT)
                : null}{" "}
              by{" "}
              {lastTeacherToStudentConversion.convertedBy === uid ? (
                " themselves"
              ) : (
                <Link
                  to={`/users/${lastTeacherToStudentConversion.convertedBy}`}>
                  {lastTeacherToStudentConversion.convertedBy}{" "}
                </Link>
              )}
              {lastTeacherToStudentConversion.restoredAt ? (
                <div>
                  <br />
                  Restored their data at{" "}
                  {moment(lastTeacherToStudentConversion.restoredAt).format(
                    DATE_FORMAT
                  )}
                </div>
              ) : null}
            </dd>
          </Fragment>
        ) : null}
        {isTeacher ? (
          <Fragment>
            <dt>Expert:</dt>
            <dd>
              {EXPERT_TYPES.map(expertType => {
                const { key, name } = expertType;
                const checked =
                  !!(expert && expert[key]) ||
                  (key === "ambassador" && !!isAmbassador);
                return (
                  <label className="result-card__expert" key={key}>
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={() =>
                        handleExpertChange({ key, value: !checked })
                      }
                    />
                    {name || key}
                  </label>
                );
              })}
            </dd>
          </Fragment>
        ) : null}
        <FeatureFlags
          uid={uid}
          updateUserValueInStore={updateUserValueInStore}
          preferences={preferences}
        />
        <dt>Signed up:</dt>
        <dd>
          {metadata ? moment(metadata.creationTime).format(DATE_FORMAT) : null}
        </dd>
        <dt>Last sign in:</dt>
        <dd>{getLastSignIn()}</dd>
        {badges ? (
          <Fragment>
            <dt>Certified</dt>
            <dd>{moment(badges.calevel1).format(DATE_FORMAT)}</dd>
          </Fragment>
        ) : null}
        {isTeacher &&
        !isSkoleTubeUser &&
        !isBorneTubeUser &&
        (!bookQuota || bookQuota < 41) ? (
          <Collaboration
            addErrorMessage={addErrorMessage}
            serverTime={serverTime}
            ended={collaborationTrialEnded}
            ends={collaborationTrialEnds}
            started={collaborationTrialStarted}
            updateUserValueInStore={updateUserValueInStore}
            uid={uid}
          />
        ) : null}
        <Provider
          providerData={providerData}
          dateFormat={DATE_FORMAT}
          isTeacher={isTeacher}
          uid={uid}
          updateUserValueInStore={updateUserValueInStore}
          loginProvider={loginProvider}
        />
        {managedStudents ? <dt>Managed Students:</dt> : null}
        {managedStudents ? (
          <dd>
            <ManagedStudents
              students={managedStudents}
              addErrorMessage={addErrorMessage}
              updateUserValueInStore={updateUserValueInStore}
              uid={uid}
            />
          </dd>
        ) : null}
        {adminOrgs || memberOrgs ? (
          <>
            <dt>Orgs:</dt>
            <dd className="result-card__meta">
              {memberOrgs}
              {adminOrgs}
            </dd>
          </>
        ) : null}
        {libraries && libraries.length ? <dt>Libaries:</dt> : null}
        {libraries && libraries.length ? <dd>{libs}</dd> : null}
        {stripe?.subscriptionId ? (
          <StripeTransfer
            {...stripe}
            addErrorMessage={addErrorMessage}
            addNotification={addNotification}
            refreshUser={refreshUser}
            uid={uid}
          />
        ) : null}
        <dt></dt>
        <dd>
          <button onClick={onShowAllData} className="result-card__action">
            Show all data
          </button>
        </dd>
      </dl>
      {showEmailChange ? (
        <ChangeEmailOverlay
          addErrorMessage={addErrorMessage}
          email={email}
          uid={uid}
          onComplete={handleEmailChangeComplete}
          onClose={() => setShowEmailChange(false)}
        />
      ) : null}
      {showVerifyEmail ? (
        <VerifyEmailOverlay
          addErrorMessage={addErrorMessage}
          uid={uid}
          onComplete={handleEmailChangeComplete}
          onClose={() => setShowVerifyEmail(false)}
        />
      ) : null}
      {showConvertToPassword ? (
        <ConvertToPassword
          addErrorMessage={addErrorMessage}
          uid={uid}
          email={email}
          onComplete={handleEmailChangeComplete}
          onClose={() => setShowConvertToPassword(false)}
        />
      ) : null}
    </div>
  );
}

ResultCardBody.propTypes = {
  adminOnly: PropTypes.bool,
  bookQuota: PropTypes.number,
  collaborationTrialEnded: PropTypes.number,
  collaborationTrialStarted: PropTypes.number,
  email: PropTypes.string,
  isAmbassador: PropTypes.bool,
  isTeacher: PropTypes.bool,
  libraries: PropTypes.array.isRequired,
  libraryQuota: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loginProvider: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  metadata: PropTypes.object.isRequired,
  organisations: PropTypes.array,
  providerData: PropTypes.array,
  uid: PropTypes.string.isRequired,
  //
  addErrorMessage: PropTypes.func.isRequired,
  onShowAllData: PropTypes.func.isRequired,
  refreshUser: PropTypes.func.isRequired,
};

export default ResultCardBody;
