import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import SvgIcon from "../ui/svg-icon";
import EmailIcon from "../../../images/svg/email.svg";
import GoogleIcon from "../../../images/svg/google-colour.svg";
import "./sign-in-button.scss";

class SignInButton extends Component {
  render() {
    const { href, text, onClick, provider, children } = this.props;

    const cssClass = classNames("sign-in-button", {
      "sign-in-button--default": !provider,
      "sign-in-button--google": provider === "google",
      "sign-in-button--email": provider === "email",
    });

    let svg;

    switch (provider) {
      case "google":
        svg = GoogleIcon;
        break;
      case "email":
        svg = EmailIcon;
        break;
      default:
        svg = null;
    }

    const buttonText = text ? text : children;

    if (href) {
      return (
        <Link to={href} className={cssClass}>
          {svg ? <SvgIcon icon={svg} name={provider} /> : null}
          <span className="sign-in-button__text">{buttonText}</span>
        </Link>
      );
    } else {
      return (
        <button className={cssClass} onClick={onClick}>
          {svg ? <SvgIcon icon={svg} name={provider} /> : null}
          <span className="sign-in-button__text">{buttonText}</span>
        </button>
      );
    }
  }
}

export default SignInButton;
