import React from "react";
import SvgIcon from "../../ui/svg-icon";
import ChevronIcon from "../../../../images/svg/chevron.svg";
import Dropdown from "../../forms/dropdown";
import "./index.scss";

const PAGE_SIZES = [10, 20, 50, 100];

function Pagination(props) {
  const {
    currentPage,
    itemCount,
    pageSize,
    // -
    setPage,
    setPageSize,
  } = props;

  const nextPage = () => {
    setPage(currentPage + 1);
  };

  const prevPage = () => {
    setPage(currentPage - 1);
  };

  const morePagesToCome = itemCount > pageSize * (currentPage + 1);
  const pageCount = Math.ceil(itemCount / pageSize);

  const currentPageMax = Math.min((currentPage + 1) * pageSize, itemCount);
  const currentRange = `${
    currentPage * pageSize + 1
  } - ${currentPageMax} of ${itemCount}`;

  let pageOptions = [];
  for (let i = 0; i < pageCount; i++) {
    pageOptions.push({ value: i, label: i + 1 });
  }

  return (
    <div className="pagination">
      <span className="pagination__item-label">Page:</span>
      <div style={{ padding: "0 5px", width: currentPage > 9 ? 70 : 60 }}>
        <Dropdown
          value={{ value: currentPage, label: currentPage + 1 }}
          triangle
          onChange={e => setPage(e.value)}
          options={pageOptions}
        />
      </div>
      <span className="pagination__item-label">Rows per page:</span>
      <div style={{ padding: "0 5px", width: pageSize > 99 ? 79 : 71 }}>
        <Dropdown
          value={{ value: pageSize, label: pageSize }}
          triangle
          onChange={e => {
            setPageSize(e.value);
          }}
          options={PAGE_SIZES.map(pageSize => {
            return { value: pageSize, label: pageSize };
          })}
        />
      </div>
      <span className="pagination__page-range">{currentRange}</span>
      <button
        className="pagination__previous"
        onClick={prevPage}
        disabled={currentPage === 0}>
        <span>
          <SvgIcon icon={ChevronIcon} name="chevron" />
        </span>
      </button>
      <button
        className="pagination__next"
        onClick={nextPage}
        disabled={!morePagesToCome}>
        <span>
          <SvgIcon icon={ChevronIcon} name="chevron" />
        </span>
      </button>
    </div>
  );
}

export default Pagination;
