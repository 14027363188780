import React, { Component } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { getShowDowngradeModal } from "../../../core/downgrade";
import {
  getShowDeleteUserModal,
  getUserToImpersonate,
} from "../../../core/users";
import Header from "../../components/header";
import Content from "../../components/content";
import UserSearch from "../../components/users/search";
import UserSearchResults from "../../components/users/search-results";
import DowngradeModal from "../../components/downgrade/modal";
import DeleteUserModal from "../../components/users/delete-modal";
import UserToImpersonateModal from "../../components/users/user-to-impersonate-modal";
import PageControl from "../../components/ui/page-control";
import { HeaderActions } from "../../components/header";
import "./index.scss";

class Users extends Component {
  render() {
    const {
      showDowngradeModal,
      showDeleteUserModal,
      userToImpersonate,
    } = this.props;

    return (
      <div>
        <Helmet title="Manage users" />
        <Header title="Manage users">
          <HeaderActions right>
            <PageControl link="/users/diagnostics" text="Diagnostics" />
          </HeaderActions>
        </Header>
        <Content>
          <UserSearch />
          <UserSearchResults />
        </Content>
        {showDowngradeModal ? <DowngradeModal /> : null}
        {showDeleteUserModal ? <DeleteUserModal /> : null}
        {userToImpersonate ? (
          <UserToImpersonateModal userToImpersonate={userToImpersonate} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  getShowDowngradeModal,
  getShowDeleteUserModal,
  getUserToImpersonate,
  (showDowngradeModal, showDeleteUserModal, userToImpersonate) => ({
    showDowngradeModal,
    showDeleteUserModal,
    userToImpersonate,
  })
);

export default connect(mapStateToProps, null)(Users);
