import { Record } from "immutable";

import {
  INIT_AUTH,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  SIGN_IN_ERROR,
  CACHE_AUTH_TOKEN,
  CLEAR_AUTH_ERROR,
  SET_SERVER_TIME,
  SET_USER_ORG_ROLES,
} from "./action-types";

export const AuthState = new Record({
  initialised: false,
  authenticated: false,
  authToken: null,
  bcAdminRole: null,
  id: null,
  avatar: null,
  displayName: null,
  lang: "en",
  email: null,
  error: null,
  ready: false,
  organisationRoles: {},
  serverTime: null,
});

export function authReducer(state = new AuthState(), { payload, type }) {
  switch (type) {
    case INIT_AUTH:
    case SIGN_IN_SUCCESS:
      return state.merge({
        initialised: true,
        bcAdminRole: payload?.bcAdminRole ?? null,
        authenticated: !!payload,
        id: payload ? payload.uid : null,
        avatar: payload && payload.photoURL ? payload.photoURL : null,
        displayName:
          payload && payload.displayName ? payload.displayName : null,
        email: payload && payload.email ? payload.email : null,
      });
    case SIGN_IN_ERROR:
      return state.merge({ error: payload });
    case SIGN_OUT_SUCCESS:
      let nextState = new AuthState();
      if (payload && payload.preserveError) {
        nextState = nextState.set("error", state.error);
      }
      nextState = nextState.set("initialised", true);
      return nextState;
    case CACHE_AUTH_TOKEN:
      return state.merge({
        authToken: payload,
      });
    case SET_USER_ORG_ROLES:
      return state.merge({
        organisationRoles: payload,
      });
    case SET_SERVER_TIME:
      return state.merge({
        serverTime: payload,
      });
    case CLEAR_AUTH_ERROR:
      return state.merge({
        error: null,
      });
    default:
      return state;
  }
}
