import React, { useEffect, useState, Fragment } from "react";
import classNames from "classnames";
import SvgIcon from "../../components/ui/svg-icon";
import TriangleIcon from "../../../images/svg/triangle.svg";
import Checkbox from "../forms/checkbox";
import Pagination from "./pagination";
import TableSearch from "./search";
import Filter from "./filter";
import "./index.scss";

const SortButtons = props => {
  const { onClick, heading, sortBy } = props;

  const sort = sortBy ? sortBy.split(".") : [];
  const ascClasses = classNames(
    "table__heading-sort",
    "table__heading-sort--asc",
    {
      active: heading.sortable === sort[0] && sort[1] === "ASC",
    }
  );
  const descClasses = classNames(
    "table__heading-sort",
    "table__heading-sort--desc",
    {
      active: heading.sortable === sort[0] && sort[1] === "DESC",
    }
  );

  return (
    <div className="table__heading-controls">
      <button
        className={ascClasses}
        onClick={e => {
          onClick(`${heading.sortable}.ASC`);
          e.stopPropagation();
        }}>
        <SvgIcon icon={TriangleIcon} name="triangle" />
      </button>
      <button
        className={descClasses}
        onClick={e => {
          onClick(`${heading.sortable}.DESC`);
          e.stopPropagation();
        }}>
        <SvgIcon icon={TriangleIcon} name="triangle" />
      </button>
    </div>
  );
};

export default function Table(props) {
  const {
    data,
    filters,
    search,
    onFilterChange,
    onHeadingClick,
    onRowSelect,
    onDeselectAll,
    onSelectAll,
    rowHeight,
    selectable,
    selectedRows,
    setSearch,
    sortBy,
    externalPagination,
  } = props;

  const {
    totalCount,
    onPageSizeChange,
    onPageChange,
    pageSize: extPageSize,
  } = externalPagination;

  const [pageSize, setPageSize] = useState(extPageSize || 10);
  const [currentPage, setCurrentPage] = useState(0);
  const itemCount = externalPagination ? totalCount : data.body.size;

  const updatePageSize = size => {
    const nextPageSize = parseInt(size, 10);
    const nextPageIndex =
      currentPage * nextPageSize > itemCount ? 0 : currentPage;
    localStorage.tablePageSize = nextPageSize;
    setPageSize(nextPageSize);
    setCurrentPage(nextPageIndex);
    //update external pagination if enabled
    if (onPageSizeChange) {
      onPageSizeChange(nextPageSize);
    }
  };

  const handlePaginationChange = page => {
    setCurrentPage(page);
    if (onPageChange) {
      onPageChange(page);
    }
  };

  const handleSearchChange = search => {
    setSearch(search);
    resetPages();
  };

  const handleFilterChange = filters => {
    onFilterChange(filters);
    if (externalPagination) {
      resetPages();
    }
  };

  useEffect(() => {
    const nextPageSize = localStorage.tablePageSize
      ? parseInt(localStorage.tablePageSize, 10)
      : 0;
    if (nextPageSize > 0 && nextPageSize !== pageSize) {
      setPageSize(nextPageSize);
    }
  }, [pageSize]);

  const sortRows = () => {
    const skip = pageSize * currentPage;
    let t = data.body;
    if (externalPagination) return t;

    if (sortBy) {
      const sort = sortBy.split(".");
      const sortKey = sort[0];
      const sortDirection = sort[1];
      t = t.sort((a, b) => {
        const aValue =
          a.sortData[sortKey] === true
            ? 1
            : typeof a.sortData[sortKey] !== "string" && !a.sortData[sortKey]
            ? 0
            : a.sortData[sortKey];
        const bValue =
          b.sortData[sortKey] === true
            ? 1
            : typeof b.sortData[sortKey] !== "string" && !b.sortData[sortKey]
            ? 0
            : b.sortData[sortKey];
        if (aValue < bValue) {
          return -1;
        } else if (aValue > bValue) {
          return 1;
        } else {
          return 0;
        }
      });
      if (sortDirection === "DESC") {
        t = t.reverse();
      }
    }

    t = t.skip(skip).take(pageSize);
    return t;
  };

  const rows = sortRows();

  const renderHeadings = () => {
    return data.headings.map((heading, i) => {
      const sort = sortBy ? sortBy.split(".") : null;
      const nextSortBy = sortBy
        ? sort[0] === heading.sortable
          ? sort[1] === "ASC"
            ? `${heading.sortable}.DESC`
            : `${heading.sortable}.ASC`
          : `${heading.sortable}.ASC`
        : null;
      return (
        <th
          className={heading.sortable ? "table__sortable-heading" : null}
          key={i}
          style={{
            width: heading.width ? heading.width : null,
            textAlign: heading.align,
          }}
          onClick={e => {
            onHeadingClick(nextSortBy);
            resetPages();
            deselectAll();
          }}>
          <span>{heading.name}</span>
          {heading.sortable ? (
            <SortButtons
              onClick={payload => {
                onHeadingClick(payload);
                resetPages();
                deselectAll();
              }}
              sortBy={sortBy}
              heading={heading}
            />
          ) : null}
        </th>
      );
    });
  };

  const resetPages = () => {
    // setCurrentPage at 0 and notify external pagination if present;
    handlePaginationChange(0);
  };

  const renderBody = () => {
    return rows.map((row, i) => {
      const rowClass = classNames({
        "table__row--selected": row.selected,
        "table__row--disabled": row.disabled,
        [`table__row--${row.align}`]: row.align,
      });
      return (
        <tr
          key={i}
          data-id={row.rowId}
          className={rowClass}
          style={{
            height: rowHeight,
          }}
          onClick={
            onRowSelect
              ? e => {
                  onRowSelect([row.rowId]);
                }
              : null
          }>
          {selectable ? (
            <td className="table__checker">
              <Checkbox checked={row.selected} />
            </td>
          ) : null}
          {row.cells.map((cell, j) => {
            const alignment = data.headings[j].align;
            return (
              <td key={j} style={{ textAlign: alignment }}>
                {cell}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  const allSelected = selectedRows && selectedRows.size === rows.size;

  const selectAll = () => {
    if (allSelected) {
      deselectAll();
    } else {
      const ids = rows.map(row => {
        return row.rowId;
      });
      onSelectAll(ids);
    }
  };

  const deselectAll = () => {
    const ids = rows.map(row => {
      return row.rowId;
    });
    if (onDeselectAll) {
      onDeselectAll(ids);
    }
  };

  const pageCount = Math.ceil(itemCount / pageSize);

  let pageOptions = [];
  for (let i = 0; i < pageCount; i++) {
    pageOptions.push({ value: i, label: i + 1 });
  }

  const cssClass = classNames("table", {
    "table--selectable": selectable,
  });

  return (
    <Fragment>
      <div className="table__filters">
        {search !== undefined ? (
          <TableSearch search={search} setSearch={handleSearchChange} />
        ) : null}
        {filters && filters.length > 0 ? (
          <div className="table-filters__filters">
            {filters.map(f => (
              <Filter {...f} onChange={handleFilterChange} />
            ))}
          </div>
        ) : null}
      </div>
      <div className={cssClass}>
        <table>
          <thead>
            <tr>
              {selectable ? (
                <th className="table__checker">
                  <Checkbox checked={allSelected} onClick={selectAll} />
                </th>
              ) : null}
              {renderHeadings()}
            </tr>
          </thead>
          <tbody>{renderBody()}</tbody>
        </table>
        {itemCount > 10 ? (
          <Pagination
            currentPage={currentPage}
            itemCount={itemCount}
            pageSize={pageSize}
            setPageSize={updatePageSize}
            setPage={handlePaginationChange}
          />
        ) : null}
      </div>
    </Fragment>
  );
}
