import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Loader from "../ui/loader";
import SvgIcon from "../ui/svg-icon";
import ExternalLinkIcon from "../../../images/svg/external-link.svg";
import "./index.scss";

const Button = props => {
  const {
    // icon,
    children,
    className,
    type,
    style,
    secondary,
    text,
    href,
    onClick,
    disabled,
    minWidth,
    loading,
    small,
    target,
    tiny,
  } = props;

  const cssClass = classNames("button", {
    [`button--${type}`]: type,
    [className]: className,
    "button--primary": !secondary && !text,
    "button--secondary": secondary,
    "button--loading": loading,
    "button--small": small,
    "button--tiny": tiny,
    "button--text": text,
  });

  const buttonProps = {
    className: cssClass,
    onClick: onClick,
    style: {
      ...style,
      minWidth,
    },
    type: type ? type : "button",
    disabled,
  };

  const buttonContents = (
    <div>
      <span className="button__label">{children}</span>
      {loading ? <Loader center tiny colour="white" /> : null}
    </div>
  );

  const isExternalLink = href && href.indexOf("http") > -1;
  const isInternalLink = href && !isExternalLink;

  if (isExternalLink) {
    return (
      <a href={href} {...buttonProps} target={target}>
        {buttonContents}
        {
          <SvgIcon
            icon={ExternalLinkIcon}
            style={{
              width: 14,
              height: 14,
              marginLeft: 10,
              fill: "currentColor",
            }}
          />
        }
      </a>
    );
  }
  if (isInternalLink) {
    return (
      <Link to={href} {...buttonProps} target={target}>
        {buttonContents}
      </Link>
    );
  }
  return (
    <button {...buttonProps} type="button">
      {buttonContents}
    </button>
  );
};

export default Button;
