import { createSelector } from "reselect";

export function getAuth(state) {
  return state.auth;
}

export function isAuthenticated(state) {
  return getAuth(state).authenticated;
}

export function getAuthToken(state) {
  return getAuth(state).authToken;
}

export function getUserId(state) {
  return getAuth(state).id;
}

export function getIsAdmin(state) {
  return true;
}

export const getBCAdminRole = createSelector(getAuth, auth => {
  return auth.bcAdminRole;
});

export const getHasEngineerPrivileges = createSelector(getAuth, auth => {
  return auth.bcAdminRole && auth.bcAdminRole <= 50;
});

export const getServerTime = createSelector(getAuth, auth => {
  return auth.serverTime;
});
