import React, { Component } from "react";
import classNames from "classnames";
import "./index.scss";

export default class Header extends Component {
  render() {
    const { title, children, fixed } = this.props;
    const cssClass = classNames("header", {
      "header--fixed": fixed,
    });
    return (
      <div className={cssClass}>
        <h1 className="header__title">{title}</h1>
        {children}
      </div>
    );
  }
}

export class HeaderActions extends Component {
  render() {
    const { children, right } = this.props;
    const cssClass = classNames("header__actions", {
      "header__actions--right": right,
    });
    return (
      <div
        className={cssClass}
        style={{
          justifySelf: right ? "flex-end" : null,
          marginLeft: right ? "auto" : null,
        }}
      >
        {children}
      </div>
    );
  }
}
