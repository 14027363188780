import React from "react";
import classNames from "classnames";
import { useState } from "react";
import { Tooltip } from "react-tooltip";
import "./index.scss";

interface SwitchProps {
  disabled?: boolean;
  flush?: boolean;
  flushleft?: boolean;
  help?: string;
  id?: string;
  label?: string;
  onChange: (value: boolean) => void;
  noborder?: boolean;
  nobottomborder?: boolean;
  notopborder?: boolean;
  standalone?: boolean;
  tabIndex?: number;
  tooltip?: string;
  value: boolean;
}

const Switch = (props: SwitchProps) => {
  const {
    disabled,
    flush,
    flushleft,
    help,
    id,
    label,
    noborder,
    nobottomborder,
    notopborder,
    onChange,
    standalone,
    tabIndex,
    tooltip,
    value,
  } = props;

  const [focused, setFocused] = useState(false);

  const handleChange = () => {
    onChange(!value);
  };

  const switchClass = classNames("switch", {
    "switch--standalone": standalone,
    "switch--no-border": noborder,
    "switch--disabled": disabled,
    "switch--no-bottom-border": nobottomborder,
    "switch--no-top-border": notopborder,
    "switch--flush": flush,
    "switch--flush-left": flushleft,
    "switch--has-help": !!help,
  });

  const controlClassName = classNames("switch__control", {
    "switch__control--on": value,
    "switch__control--focused": focused,
  });

  const tooltipId = `${id}-tooltip`;

  return (
    <div className={switchClass} id={id} onClick={evt => evt.stopPropagation()}>
      <label
        className="switch__label"
        data-tooltip-id={tooltip ? tooltipId : null}>
        {label}
        <span className={controlClassName}>
          <span className="switch__track" />
          <span className="switch__handle" />
          <input
            type="checkbox"
            className="switch__input focusable"
            checked={value}
            onChange={!disabled ? handleChange : () => {}}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            tabIndex={tabIndex || undefined}
            onClick={e => e.stopPropagation()}
          />
        </span>
        {tooltip ? (
          <Tooltip
            id={tooltipId}
            place="top-end"
            delayShow={500}
            noArrow
            content={tooltip}
          />
        ) : null}
      </label>
      {help ? <p className="switch__help">{help}</p> : null}
    </div>
  );
};

export default Switch;
