import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Users from "../pages/users";
import Diagnostics from "../pages/diagnostics";
import Organisations from "../pages/organisations";
import Analytics from "../pages/analytics";
import PromoCodes from "../pages/promo-codes";
import Libraries from "../pages/libraries";
import ImageSearch from "../pages/image-search";
import Campustore from "../pages/campustore";
import "../../shared/scss/colors.css";
import "./main.scss";

export default class Main extends Component {
  render() {
    const { store } = this.props;

    return (
      <div className="main">
        <Switch>
          <Route
            path="/users/diagnostics"
            component={Diagnostics}
            store={store}
          />
          <Route
            path="/users/:userIdOrEmail?"
            component={Users}
            store={store}
          />
          <Route path="/promo-codes" component={PromoCodes} store={store} />
          <Route
            path="/organisations"
            component={Organisations}
            store={store}
          />
          <Route
            path="/libraries/:libraryId?"
            component={Libraries}
            store={store}
          />
          <Route
            path="/analytics/:domain?"
            component={Analytics}
            store={store}
          />
          <Route
            path={["/image-search", "/image-search/*"]}
            component={ImageSearch}
            store={store}
          />
          <Route path="/campustore" component={Campustore} store={store} />
          <Redirect from="/" to="/users" />
        </Switch>
      </div>
    );
  }
}
