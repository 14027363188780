import React, { useState } from "react";
import moment from "moment";
import { firebaseDb } from "../../../../../../../../core/firebase";
import InlineButton from "../../../../../../ui/inline-button";
import DropDown from "../../../../../../forms/dropdown";
import "./index.scss";

const options = [7, 14, 30, 60, 90];

function ExtendCollaboration(props) {
  const {
    addErrorMessage,
    onClose,
    serverTime,
    uid,
    updateUserValueInStore,
  } = props;

  const [days, setDays] = useState(options[1]);

  const extend = () => {
    const endDate = moment(serverTime)
      .add(days, "days")
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 });
    const updates = {
      collaborationTrialEnds: parseInt(endDate.format("x"), 10),
      collaborationTrialEnded: null,
      collaborationTrialStarted: null,
    };
    firebaseDb
      .ref(`/users/${uid}`)
      .update(updates)
      .then(() => {
        updateUserValueInStore({
          uid,
          location: "collaborationTrialEnds",
          value: parseInt(endDate.format("x"), 10),
        });
        onClose();
      })
      .catch((evt) => {
        addErrorMessage("Something went wrong updating the user");
        console.error(evt);
      });
  };

  const handleChange = (evt) => {
    setDays(evt.value);
  };

  return (
    <div className="extend-collaboration">
      <span className="extend-collaboration__label">Change to:</span>
      <DropDown
        value={days}
        width={150}
        onChange={handleChange}
        options={options.map((days) => {
          return {
            value: days,
            label: `${days} days`,
          };
        })}
      />
      <InlineButton onClick={extend}>Save</InlineButton>
      <InlineButton onClick={onClose}>Cancel</InlineButton>
    </div>
  );
}

export default ExtendCollaboration;
