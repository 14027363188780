import React from "react";
import { firebaseFirestore as db } from "../../../../../../core/firebase";
import "./index.scss";

function BlockedImagesTextOnly(props) {
  const { blockedURLs } = props;

  const handleUnblock = (key) => {
    db.collection("imageSearchBlocks").doc(key).delete();
  };

  return (
    <div class="blocked-images-text-only">
      {blockedURLs.map((url) => {
        return (
          <div class="blocked-images-text-only__item">
            <button
              class="blocked-images-text-only__button"
              onClick={() => handleUnblock(url.key)}
            >
              Unblock
            </button>
            <span class="blocked-images-text-only__item">{url.link}</span>
          </div>
        );
      })}
    </div>
  );
}

export default BlockedImagesTextOnly;
