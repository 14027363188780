import React, { useEffect, useState } from "react";
import Search from "./search";
import { firebaseDb } from "../../../core/firebase";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { getAuthToken } from "../../../core/auth";
import NoResults from "./no-results";
import Result from "./result";
import { notificationsActions } from "../../../core/notifications";

function LibrariesLookup(props) {
  const { addErrorMessage } = props;
  const [searchTerm, setSearchTerm] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const [result, setResult] = useState(null);
  const [users, setUsers] = useState(null);

  const { authToken } = props;

  const reset = () => {
    setResult(null);
    setNoResult(false);
  };

  // Reset users when library changes
  useEffect(() => {
    setUsers(null);
  }, [result]);

  const getOrgData = libraryId => {
    return new Promise((resolve, reject) => {
      firebaseDb
        .ref(`libraries/${libraryId}/organisation`)
        .once("value", snapshot => {
          resolve(snapshot.val());
        })
        .catch(e => {
          reject();
        });
    });
  };

  const searchLibraries = ({ libraryId }) => {
    setSearchTerm(libraryId);
    reset();
    firebaseDb
      .ref(`libraries/${libraryId}/info`)
      .once("value", async snapshot => {
        const result = snapshot.val();
        if (result) {
          if (result.owner) {
            setResult(result);
            return;
          }
          // If no owner it should be an org library so fetch organisations node
          try {
            const orgData = await getOrgData(libraryId);
            setResult({ ...result, org: orgData });
          } catch (err) {
            setResult(result);
            console.error(err);
          }
        } else {
          setNoResult(true);
        }
      })
      .catch(e => {
        setNoResult(true);
      });
  };

  const getUsers = () => {
    firebaseDb
      .ref(`libraryUsers/${searchTerm}/users`)
      .once("value", snapshot => {
        const result = snapshot.val();
        if (result) {
          setUsers(result);
        }
      });
  };

  return (
    <div className="library-lookup">
      <Search authToken={authToken} searchLibraries={searchLibraries} />
      {noResult ? <NoResults searchTerm={searchTerm} /> : null}
      {result ? (
        <Result
          {...result}
          getUsers={getUsers}
          id={searchTerm}
          users={users}
          searchLibraries={searchLibraries}
          addErrorMessage={addErrorMessage}
        />
      ) : null}
    </div>
  );
}

const mapDispatchToProps = {
  ...notificationsActions,
};

const mapStateToProps = createSelector(getAuthToken, authToken => ({
  authToken,
}));

export default connect(mapStateToProps, mapDispatchToProps)(LibrariesLookup);
