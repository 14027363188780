import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./index.scss";

export default class DomainSearchResults extends Component {
  get results() {
    const {
      results,
      searchTerm,
      domainSearchComplete,
      searchByDomain,
    } = this.props;
    if (!results) {
      return null;
    }
    if (!results.length) {
      return (
        <p className="domain-results__no-results">
          No users found for '{searchTerm}'
        </p>
      );
    }
    return (
      <div className="domain-results__results">
        {results.map((result) => {
          return (
            <p key={result.key} className="domain-results__result">
              <Link to={`/users/${result.key}`}>{result.key}</Link> -{" "}
              {result.email}
            </p>
          );
        })}
        {!domainSearchComplete ? (
          <button
            className="domain-results__more"
            onClick={() => searchByDomain({ searchTerm })}
          >
            Load more results
          </button>
        ) : null}
      </div>
    );
  }

  render() {
    return <div className="domain-results">{this.results}</div>;
  }
}
