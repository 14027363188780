import React, { useState } from "react";
import { createPortal } from "react-dom";
// import InlineButton from "../../../../../ui/inline-button";
import Modal from "../../../modal";
import TextBox from "../../../forms/text-box";
import { isValidEmail } from "../../../../../utils/validators";
import { BOOKCREATOR_API_URL } from "../../../../../config";
// import PropTypes from "prop-types";

function ChangeLibraryOwner(props) {
  const {
    addErrorMessage,
    libraryId,
    libraryName,
    onClose,
    searchLibraries,
  } = props;
  const [newOwner, setNewOwner] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const getUserIdFromEmail = async email => {
    const res = await fetch(
      `${BOOKCREATOR_API_URL}/admin-tools/v2/me/users/search/${encodeURIComponent(
        email
      )}`,
      {
        credentials: "include",
      }
    );
    const users = await res.json();
    const user = users.find(u => u.email === email);
    if (user) {
      return user.id;
    }
    throw new Error("No user found with that email");
  };

  const handleSubmit = async evt => {
    if (evt) {
      evt.preventDefault();
    }
    setSubmitting(true);
    try {
      let newOwnerUserId;
      if (isValidEmail(newOwner)) {
        newOwnerUserId = await getUserIdFromEmail(newOwner);
      } else {
        newOwnerUserId = newOwner;
      }
      const res = await fetch(
        `${BOOKCREATOR_API_URL}/admin-tools/v2/me/libraries/${encodeURIComponent(
          libraryId
        )}/owner/${newOwnerUserId}`,
        {
          method: "PUT",
          credentials: "include",
        }
      );
      if (res.ok) {
        searchLibraries({ libraryId });
        onClose();
      } else {
        const err = await res.json();
        throw new Error(err.message);
      }
    } catch (err) {
      addErrorMessage(err.message || "Unable to transfer library");
    } finally {
      setSubmitting(false);
    }
  };

  const formDisabled = newOwner.length < 1;

  return createPortal(
    <Modal
      title="Change library owner"
      modal
      onConfirm={handleSubmit}
      confirmButtonDisabled={newOwner.length < 1}
      confirmText="Change"
      confirmButtonLoading={submitting}
      onCancel={onClose}>
      <p>
        You are changing the owner of library <strong>{libraryName}</strong>.
      </p>
      <form onSubmit={!formDisabled ? handleSubmit : null}>
        <TextBox
          label="User to transfer to (email address or user ID)"
          value={newOwner}
          name="email"
          id="email"
          onChange={change => setNewOwner(change.trim())}
        />
      </form>
    </Modal>,
    document.querySelector("body")
  );
}

ChangeLibraryOwner.propTypes = {
  //
};

export default ChangeLibraryOwner;
