import React, { Component } from "react";
import Input from "./input";
import uuid from "node-uuid";

class TextBox extends Component {
  state = {
    id: uuid.v4(),
  };

  componentDidMount() {
    const { autoFocus } = this.props;
    if (autoFocus) {
      this.focus();
    }
  }

  focus = () => {
    document.getElementById(this.id).focus();
  };

  get id() {
    return this.props.id ? this.props.id : this.state.id;
  }

  render() {
    const {
      disabled,
      onFocus,
      onBlur,
      onChange,
      onPaste,
      type,
      value,
      name,
      maxLength,
      placeholder,
      innerRef,
      min,
      max,
    } = this.props;

    const opts = {
      disabled,
      onFocus,
      onBlur,
      onChange,
      onPaste,
      type: type ? type : "text",
      id: this.id,
    };

    return (
      <input
        {...opts}
        className="input__input"
        ref={innerRef ? innerRef : c => (this._input = c)}
        value={value}
        name={name}
        placeholder={placeholder}
        maxLength={maxLength}
        min={min}
        max={max}
        onChange={e => onChange(e.target.value)}
      />
    );
  }
}

export default Input(TextBox);
