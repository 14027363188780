import React, { Component } from "react";
import ResultCard from "../result-card";
import "./index.scss";

export default class Result extends Component {
  render() {
    const { result, serverTime } = this.props;

    return (
      <div className="search-results ">
        <ResultCard result={result} serverTime={serverTime} />
      </div>
    );
  }
}
