import React, { Component } from "react";
import {
  BOOKCREATOR_API_URL,
  BOOKCREATOR_PROMO_CODE_PREFIX,
} from "../../../../../config";
import Loader from "../../../ui/loader";
import superagent from "superagent";
import "./index.scss";

export default class Code extends Component {
  state = {
    loading: true,
  };

  componentWillMount() {
    const { ready } = this.props;
    if (ready) {
      this.getCode();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { ready } = nextProps;
    if (!this.props.ready && ready) {
      this.getCode();
    }
  }

  getCode = () => {
    const { onLoad, onError } = this.props;
    superagent
      .post(`${BOOKCREATOR_API_URL}/admin-tools/v2/me/promo-codes`)
      .withCredentials()
      .end((err, res) => {
        if (err) {
          onError(err);
        } else {
          onLoad(res.body);
          this.setState({
            loading: false,
          });
        }
      });
  };

  render() {
    const { code } = this.props;
    const { loading } = this.state;

    return (
      <div className="promo-code">
        {loading ? <Loader small /> : null}
        {code ? (
          <span className="promo-code__code">
            {BOOKCREATOR_PROMO_CODE_PREFIX}promocode/BC{code}
          </span>
        ) : null}
      </div>
    );
  }
}
