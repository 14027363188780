import React, { Component } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { getEmailError, getPasswordError } from "../../../utils/validators";
import { firebaseAuth } from "../../../core/firebase";
import Form from "../../components/forms/form";
import FormButton from "../../components/forms/form-button";
import SignInButton from "./sign-in-button";
import TextBox from "../../components/forms/text-box";
import Password from "../../components/forms/password";
import Message from "../ui/message";

class SignInWithEmail extends Component {
  constructor(props) {
    super(props);
    const { email } = props.computedMatch.params;
    this.state = {
      error: null,
      email: email ? email : "",
      password: "",
      submitted: false,
      submitting: false,
      alreadyRegistered: null,
      alreadyRegisteredWithGoogle: null,
    };
  }

  componentWillUnmount() {
    this.props.clearAuthError();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.error) {
      this.setState({
        submitting: false,
      });
    }
  }

  updateEmail(nextEmail) {
    this.props.clearAuthError();
    this.setState({
      email: nextEmail,
    });
  }

  updateName(nextName) {
    this.props.clearAuthError();
    this.setState({
      fullName: nextName,
    });
  }

  updatePassword(nextPassword) {
    this.props.clearAuthError();
    this.setState({
      password: nextPassword,
    });
  }

  checkIfUserExists() {
    return new Promise((resolve, reject) => {
      const { email } = this.state;
      firebaseAuth
        .fetchProvidersForEmail(email)
        .catch((error) => {
          resolve(false);
        })
        .then((data) => {
          resolve(data);
        });
    });
  }

  handleSubmit(e) {
    const {
      alreadyRegistered,
      alreadyRegisteredWithGoogle,
      email,
      password,
    } = this.state;
    this.setState({
      submitted: true,
    });
    e.preventDefault();
    if (!this.isValid()) {
      return;
    }
    if (alreadyRegistered) {
      this.setState({
        submitting: true,
      });
      this.props.signInWithEmailAndPassword({
        email,
        password,
      });
    } else if (!alreadyRegisteredWithGoogle) {
      this.checkIfUserExists().then((data) => {
        if (data && data.includes("password")) {
          this.setState({
            alreadyRegistered: true,
            submitted: false,
          });
        } else if (data && data.includes("google.com")) {
          this.setState({
            alreadyRegisteredWithGoogle: true,
          });
        } else {
          const { router } = this.context;
          router.history.replace(`/sign-in/register/${email}`);
        }
      });
    }
  }

  isValid() {
    const { alreadyRegistered, email, password } = this.state;

    const { auth } = this.props;
    const { error } = auth;

    if (alreadyRegistered) {
      return !error && !getEmailError(email) && !getPasswordError(password);
    } else {
      return !error && !getEmailError(email);
    }
  }

  render() {
    const {
      email,
      password,
      submitted,
      submitting,
      alreadyRegistered,
      alreadyRegisteredWithGoogle,
    } = this.state;

    const { signInWithGoogle, auth } = this.props;

    const { error } = auth;

    let emailError = submitted && getEmailError(email);
    let passwordError = submitted && getPasswordError(password);

    if (error && error.code) {
      switch (error.code) {
        case "auth/wrong-password":
          passwordError = "Incorrect password";
          break;
        case "auth/user-not-found":
          emailError = "That email address doesn't match an existing account";
          break;
        case "auth/invalid-email":
          emailError = "Invalid email address";
          break;
        case "auth/user-disabled":
          emailError = "This account has been disabled";
          break;
        case "auth/too-many-requests":
          passwordError =
            "You have entered an incorrect password too many times. Please try again in a few minutes.";
          break;
        default:
          passwordError = "There was a problem signing in, please try again.";
          break;
      }
    }

    const submitText = alreadyRegistered ? "Sign in" : "Next";

    const cancelLink = "/sign-in";
    const forgottenPasswordLink = "/sign-in/forgotten-password";

    return (
      <Form header="Sign in with email" onSubmit={this.handleSubmit.bind(this)}>
        <Helmet title="Sign in with an email address" />
        <div className="form__content">
          {alreadyRegisteredWithGoogle ? (
            <div>
              <p>
                That email address has already been registered with a Google
                account
              </p>
              <SignInButton
                text="Sign in with Google"
                onClick={signInWithGoogle}
                provider="google"
              />
            </div>
          ) : (
            <TextBox
              label="Email"
              type="email"
              name="email"
              onChange={this.updateEmail.bind(this)}
              value={email}
              autoFocus
              error={emailError}
            />
          )}
          {alreadyRegistered ? (
            <Password
              label="Password"
              name="password"
              onChange={this.updatePassword.bind(this)}
              value={password}
              autoFocus
              error={passwordError}
            />
          ) : null}
          {error && error.code === "auth/internal-error" ? (
            <Message type="error" center>
              {error.message}
            </Message>
          ) : null}
        </div>
        <div className="form__actions">
          {alreadyRegistered ? (
            <small className="form__actions-smallprint">
              <Link to={forgottenPasswordLink}>Forgotten password?</Link>
            </small>
          ) : null}
          <FormButton flat href={cancelLink} text="Cancel" />
          {!alreadyRegisteredWithGoogle ? (
            <FormButton type="submit" text={submitText} loading={submitting} />
          ) : null}
        </div>
      </Form>
    );
  }
}

export default SignInWithEmail;
