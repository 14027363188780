import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./index.scss";

export default class MultipleSearchResults extends Component {
  get results() {
    const { results, searchTerm } = this.props;
    if (!results) {
      return null;
    }
    if (!results.length) {
      return (
        <p className="domain-results__no-results">
          No users found for '{searchTerm}'
        </p>
      );
    }
    return (
      <div className="domain-results__results">
        {results.map((result) => {
          return (
            <p key={result.id} className="domain-results__result">
              <Link to={`/users/${result.id}`}>{result.id}</Link> -{" "}
              {result.email}
            </p>
          );
        })}
      </div>
    );
  }

  render() {
    return <div className="domain-results">{this.results}</div>;
  }
}
