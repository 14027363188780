import React, { Component } from "react";
import { getInitialsFromName } from "../../../utils";
import "./index.scss";

export default class Avatar extends Component {
  state = {
    imageFailed: false,
    imageUrl: null,
  };

  componentWillMount() {
    this._isMounted = true;
    const { url } = this.props;
    if (url) {
      let image = new Image();
      image.src = url;
      image.onload = e => {
        if (this._isMounted) {
          this.setState({ imageUrl: url });
        }
      };
      image.onerror = e => {
        if (this._isMounted) {
          this.setState({ imageFailed: true });
        }
      };
    }
  }

  componentDidUpdate() {
    const { url } = this.props;
    const { imageUrl } = this.state;
    if (imageUrl && !url) {
      this.setState({ imageUrl: null });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { name, size } = this.props;
    const { imageFailed, imageUrl } = this.state;

    let avatarIcon;

    const style = {
      width: size,
      height: size,
    };

    const initialsScale = size ? size / 40 : 1;

    const initialsStyle = {
      transform: size ? `scale(${initialsScale})` : null,
    };

    if (imageUrl && !imageFailed) {
      avatarIcon = (
        <img
          src={`${imageUrl}`}
          onError={e => {
            this.setState({ imageFailed: true });
          }}
          alt={name}
        />
      );
    } else {
      avatarIcon = (
        <span className="avatar__initials" style={initialsStyle}>
          {getInitialsFromName(name)}
        </span>
      );
    }

    return (
      <div className="avatar" style={style}>
        {avatarIcon}
      </div>
    );
  }
}
