import React from "react";
import classNames from "classnames";
import "./index.scss";

function PixabayImageTag(props) {
  const { onToggle, blocked, tag } = props;

  const className = classNames("pixabay-image-tag", {
    "pixabay-image-tag--selected": blocked,
  });

  const handleClick = (evt) => {
    onToggle(tag);
    evt.preventDefault();
  };

  return (
    <button className={className} onClick={handleClick}>
      {tag}
    </button>
  );
}

export default PixabayImageTag;
