import React from "react";
import { connect } from "react-redux";
import { firebaseDb } from "../../../../../../core/firebase";
import { usersActions } from "../../../../../../core/users";
import Modal from "../../../../modal";

function ConvertToTeacherModal(props) {
  const { onClose, userId, email, updateUserValueInStore } = props;

  const confirm = () => {
    const updates = {
      bookQuota: 100,
      email,
      isTeacher: true,
      libraryQuota: 50,
    };
    firebaseDb
      .ref(`/users/${userId}/`)
      .update(updates)
      .then(() => {
        Object.entries(updates).forEach(([key, value]) => {
          updateUserValueInStore({
            uid: userId,
            location: key,
            value,
          });
        });
        onClose();
      })
      .catch();
  };

  return (
    <Modal
      modal
      title="Convert to teacher"
      onCancel={onClose}
      onConfirm={confirm}
      confirmText="Convert">
      <p>Are you sure you want to convert this student to a teacher?</p>
    </Modal>
  );
}
const mapDispatchToProps = {
  ...usersActions,
};

export default connect(null, mapDispatchToProps)(ConvertToTeacherModal);
