import { OrgUserType } from "src/views/components/organisations/add-edit/org-domains";
import { BOOKCREATOR_ORGS_API_URL } from "../../config";

let authedUser;

export function setAuthedUser(user) {
  authedUser = user;
}

const orgProps = `id name isPilot startDate endDate schoolSubscriptionUUID tsm xeroId dealAmount quoteId demo reseller features createdAt
        domains { domain }
        licenses { id bookQuota libraryQuota quantity applyToAll }
        membersCount {total joined}
        admins { email }
        `;

export async function graphqlRequest(data, operationName) {
  let apiResponse;
  try {
    const response = await fetch(`${BOOKCREATOR_ORGS_API_URL}/graphql`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "x-bc-expected-user": authedUser,
      },
    });
    apiResponse = await response.json();
  } catch (err) {
    apiResponse = { errors: [err] };
  }

  if (apiResponse.errors) {
    const message =
      apiResponse.errors.map(err => err.message).join("\n") ||
      `Something went wrong with the operation ${operationName}`;
    return { ok: false, message };
  } else {
    const data = apiResponse.data[operationName];
    return { ok: true, data };
  }
}
export async function getOrganisations(params) {
  const { sortBy } = params;
  const query = `query($sortBy: String) {
    getOrganisations(sortBy:$sortBy) {
      ${orgProps} 
    }
  }`;
  const data = { query, variables: { sortBy } };
  return await graphqlRequest(data, "getOrganisations");
}

export async function getOrganisationsPagination(params) {
  const { sortBy, pageSize, offset, search, filters } = params;
  const query = `query($sortBy: String, $pageSize: Int, $offset: Int, $search: String, $filters: [KeyValueInput!]!) {
    getOrganisationsPagination(sortBy:$sortBy, pageSize:$pageSize, offset:$offset, search:$search, filters:$filters) {
      total data{${orgProps}}
    }
  }`;
  const data = {
    query,
    variables: { sortBy, pageSize, offset, search, filters },
  };
  return await graphqlRequest(data, "getOrganisationsPagination");
}

export async function getOrganisationById(orgId) {
  const query = `query($orgId: OrgID!) {
    getOrganisationById(orgId: $orgId) {
      ${orgProps} domainMatchers {id domain local type testCases}
    }
  }`;
  const data = { query, variables: { orgId } };
  return await graphqlRequest(data, "getOrganisationById");
}

export async function saveOrganisation(orgPayload) {
  const query = `mutation($organisation: SaveOrganisationInput!) {
    saveOrganisation(organisation: $organisation) {
      organisation { 
        ${orgProps} domainMatchers {id domain local type testCases}
      }
    }
  }`;
  const variables = { organisation: orgPayload };
  const data = { query, variables };

  return await graphqlRequest(data, "saveOrganisation");
}
export async function deleteOrganisation({ orgId }) {
  const query = `mutation($orgId: OrgID!) {
    deleteOrganisation(orgId: $orgId) {
      orgId
    }
  }`;
  const variables = { orgId };
  const data = { query, variables };

  return await graphqlRequest(data, "deleteOrganisation");
}

export async function getDeletedOrganisations() {
  const query = `query($sortBy: String) {
    getDeletedOrganisations(sortBy: $sortBy) {
      id dealAmount name startDate endDate 
      licenses { id bookQuota libraryQuota quantity applyToAll }
      deletedBy deletedAt
    }
  }`;
  const data = { query, variables: { sortBy: "deleted_at.DESC" } };
  return await graphqlRequest(data, "getDeletedOrganisations");
}

export async function addDomain({ orgId, domain }) {
  const query = `mutation($orgId: OrgID!, $domains: [String!]!) {
    addDomains(orgId: $orgId, domains:$domains ) {
      orgId, domains
    }
  }`;
  const variables = { orgId, domains: [domain] };
  const data = { query, variables };

  return await graphqlRequest(data, "addDomains");
}

export async function removeDomain({ orgId, domain }) {
  const query = `mutation($orgId: OrgID!, $domains: [String!]!) {
    removeDomains(orgId: $orgId, domains:$domains ) {
      orgId, domains
    }
  }`;
  const variables = { orgId, domains: [domain] };
  const data = { query, variables };

  return await graphqlRequest(data, "removeDomains");
}

export async function previewDomain({ orgId, domain }) {
  const query = `query($orgId: OrgID!, $domain: String!) {
    previewDomainUsers(orgId: $orgId, domain:$domain ) {
      alreadyMembers,
      addedMembers,
      invitedMembersWithPersonalSubs,
      invitedMembersFromOtherOrgs
    }
  }`;
  const variables = { orgId, domain };
  const data = { query, variables };

  return await graphqlRequest(data, "previewDomainUsers");
}

export async function applyLicenseToDomain({ orgId, domain }) {
  return await addDomain({ orgId, domain });
}

export async function checkDomainBlocksForEmail(
  email: string,
  expectedType: OrgUserType = OrgUserType.STUDENT
) {
  const endpoint = `domain-matcher/${expectedType}/check/${email}`;
  const response = await restRequest(endpoint, "GET");
  if (response.ok) {
    return response.data.matcher;
  }
  return false;
}

export async function testDomainMatcher(
  expectedType: OrgUserType,
  email: string,
  payload: object
) {
  const endpoint = `domain-matcher/${expectedType}/test/${email}`;
  const response = await restRequest(endpoint, "POST", payload);
  if (response.ok) {
    return response.data.pass;
  }
  return false;
}

export async function restRequest(
  endpoint: string,
  method = "GET",
  data?: any
) {
  let apiResponse;
  try {
    const payload: RequestInit = {
      method,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (data) {
      payload.body = JSON.stringify(data);
    }

    const response = await fetch(
      `${BOOKCREATOR_ORGS_API_URL}/rest/${authedUser}/${endpoint}`,
      payload
    );
    apiResponse = await response.json();
  } catch (err) {
    apiResponse = { errors: [err] };
  }

  if (apiResponse.errors) {
    const message =
      apiResponse.errors.map(err => err.message).join("\n") ||
      `Something went wrong with the operation ${endpoint}`;
    return { ok: false, message };
  } else {
    const data = apiResponse;
    return { ok: true, data };
  }
}
