import React from "react";
import Result from "./result";
import "./index.scss";

function PixabayImageSearchResults(props) {
  const {
    blockedIDs,
    blockedTags,
    results,
    obscureblocked,
    blockById,
    unBlockById,
    blockByTag,
    unBlockByTag,
  } = props;

  return (
    <div className="image-search-results">
      {results.map((result, i) => {
        return (
          <Result
            {...result}
            blockedById={blockedIDs.includes(result.id)}
            blockedByTag={
              !!result.tags.find((tag) => blockedTags.includes(tag))
            }
            blockById={blockById}
            blockedTags={blockedTags}
            unBlockById={unBlockById}
            blockByTag={blockByTag}
            unBlockByTag={unBlockByTag}
            obscureblocked={obscureblocked}
            key={`${result.link}-${i}`}
          />
        );
      })}
    </div>
  );
}

export default PixabayImageSearchResults;
