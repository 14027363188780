import React from "react";
import Helmet from "react-helmet";
import Header from "../../../components/header";
import Content from "../../../components/content";
import NounProjectMain from "../../../components/image-search/noun-project/main";

function NounProjectPage(props) {
  return (
    <div>
      <Helmet title="Noun Project" />
      <Header title="Noun Project" />
      <Content>
        <NounProjectMain />
      </Content>
    </div>
  );
}

export default NounProjectPage;
