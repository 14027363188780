import React, { Component, Fragment } from "react";
import Modal from "../../../modal";
import "./index.scss";

export default class DomainConfirmation extends Component {
  render() {
    const {
      domainsToAdd,
      domainsToIgnore,
      domainsToRemove,
      onCancel,
      onConfirm,
    } = this.props;

    return (
      <Modal
        modal
        title={"Domain licenses"}
        onCancel={onCancel}
        onConfirm={onConfirm}
        confirmText="Save">
        <div className="domain-confirmation">
          {domainsToAdd.length ? (
            <Fragment>
              <span className="domain-confirmation__title">
                You are about to add the licenses for the following domains:
              </span>
              <ul className="domain-confirmation__list">
                {domainsToAdd.map(domain => (
                  <li key={domain}>{domain}</li>
                ))}
              </ul>
            </Fragment>
          ) : null}
          {domainsToRemove.length ? (
            <Fragment>
              <span className="domain-confirmation__title">
                You are about to remove the following domain licenses:
              </span>
              <ul className="domain-confirmation__list domain-confirmation__list--remove">
                {domainsToRemove.map(domain => (
                  <li key={domain}>{domain}</li>
                ))}
              </ul>
            </Fragment>
          ) : null}
          {domainsToIgnore.length ? (
            <Fragment>
              <span className="domain-confirmation__title">
                The following domains will be ignored:
              </span>
              <ul className="domain-confirmation__list">
                {domainsToIgnore.map(domain => (
                  <li key={domain}>{domain}</li>
                ))}
              </ul>
            </Fragment>
          ) : null}
        </div>
      </Modal>
    );
  }
}
