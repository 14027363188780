import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { firebaseDbBlocks } from "../../../../../../../core/firebase/firebase";
import { encodeDomain } from "../../../../../../../utils/string";
import InlineButton from "../../../../../ui/inline-button";
import { checkDomainBlocksForEmail } from "src/core/organisations/orgs-api";
import "./index.scss";

export default function BlockStatus(props) {
  const { email, userId } = props;

  const [blockedByDomain, setBlockedByDomain] = useState(null);
  const [blockedUser, setBlockedUser] = useState(null);

  const domain = email.split(/(.+)@/)[2];

  useEffect(() => {
    const checkDomain = async () => {
      const domainBlocked = await checkDomainBlocksForEmail(email);
      setBlockedByDomain(domainBlocked);
    };
    checkDomain();
  }, [domain, email]);

  useEffect(() => {
    const checkEmail = async () => {
      const emailSnapshot = await firebaseDbBlocks
        .ref(`/user/${encodeDomain(email)}`)
        .once("value");
      const value = emailSnapshot.val();
      if (value) {
        setBlockedUser(true);
      }
      const idSnapshot = await firebaseDbBlocks
        .ref(`/user/${userId}`)
        .once("value");
      const idValue = idSnapshot.val();
      setBlockedUser(!!idValue);
    };
    checkEmail();
  }, [email, userId]);

  const handleUnblockClick = () => {
    firebaseDbBlocks.ref(`/user/${userId}`).set(null);
    firebaseDbBlocks.ref(`/user/${encodeDomain(email)}`).set(null);
    setBlockedUser(false);
  };

  return (
    <>
      {" "}
      {blockedByDomain ? (
        <>
          {"(Locked by "}
          {blockedByDomain.orgId ? (
            <>
              <Link
                to={`/organisations/${blockedByDomain.orgId}/edit`}
                style={{ marginLeft: "3px" }}>
                org student domain
              </Link>
              {")"}
            </>
          ) : (
            " domain)"
          )}
        </>
      ) : null}
      {blockedUser && !blockedByDomain ? "(Locked)" : null}{" "}
      {blockedUser && !blockedByDomain ? (
        <InlineButton onClick={handleUnblockClick} style={{ marginLeft: 5 }}>
          Unlock
        </InlineButton>
      ) : null}
    </>
  );
}
