import React from "react";
import Helmet from "react-helmet";
import ImageSearch from "../../../components/image-search/pixabay/container";

function ImageSearchPage(props) {
  return (
    <div>
      <Helmet title="Pixabay" />
      <ImageSearch />
    </div>
  );
}

export default ImageSearchPage;
