import React from "react";
import { Switch, Route } from "react-router-dom";
import Helmet from "react-helmet";
import GoogleImageSearch from "./google";
import PixabayImageSearch from "./pixabay";
import NounProject from "./noun-project";
import Tags from "./tags";

function ImageSearchPage(props) {
  return (
    <div>
      <Helmet title="Image Search" />

      <Switch>
        <Route path="/image-search/google">
          <GoogleImageSearch />
        </Route>
        <Route path="/image-search/pixabay">
          <PixabayImageSearch />
        </Route>
        <Route path="/image-search/noun-project">
          <NounProject />
        </Route>
        <Route path="/image-search/tags">
          <Tags />
        </Route>
      </Switch>
    </div>
  );
}

export default ImageSearchPage;
