import React, { useState } from "react";
import { connect } from "react-redux";
import Modal from "../../../modal";
import Loader from "../../../ui/loader";
import { notificationsActions } from "../../../../../core/notifications";
import { organisationsActions } from "../../../../../core/organisations";
import { deleteOrganisation } from "../../../../../core/organisations/orgs-api";

function DeleteOrgModal(props) {
  const {
    id,
    onClose,
    name,
    addErrorMessage,
    addNotification,
    onOrgRemoved,
  } = props;
  const [deleting, setDeleting] = useState(false);

  const handleConfirmClick = async e => {
    if (props.domainMatchers?.length) {
      addErrorMessage(
        "Cannot delete organisation as there are student/teacher blocks setup. Please contact the engineering team with the organisation ID"
      );
      onClose();
    } else {
      setDeleting(true);
      const deleteResponse = await deleteOrganisation({ orgId: id });
      if (deleteResponse.ok) {
        addNotification(`${name} has been deleted`);
        onOrgRemoved(id);
        onClose();
      } else {
        addErrorMessage(
          deleteResponse.message || "Organisation wasn't deleted, try again"
        );
        setDeleting(false);
      }
    }
  };

  return (
    <Modal
      modal
      title={`Delete ${name}?`}
      onCancel={!deleting ? onClose : null}
      onConfirm={!deleting ? handleConfirmClick : null}
      confirmButtonType="negative"
      confirmText="Delete">
      {deleting ? (
        <div style={{ height: 100 }}>
          <Loader center />
        </div>
      ) : (
        <p>
          The organisation will be deleted and all teachers will be reset to the
          free plan.
        </p>
      )}
    </Modal>
  );
}

const mapDispatchToProps = {
  ...notificationsActions,
  ...organisationsActions,
};

export default connect(null, mapDispatchToProps)(DeleteOrgModal);
