export function getUsersState(state) {
  return state.users;
}

export function getUsersSearching(state) {
  return getUsersState(state).searching;
}

export function getUsersSearchResult(state) {
  const result = getUsersState(state).searchResult;
  return result && result.toJS ? result.toJS() : result;
}

export function getUsersSearchResultMultiple(state) {
  const result = getUsersState(state).searchResultMultiple;
  return result && result.toJS ? result.toJS() : result;
}

export function getUsersSearchTerm(state) {
  return getUsersState(state).searchTerm;
}

export function getShowDeleteUserModal(state) {
  return getUsersState(state).showDeleteUserModal;
}

export function getUserToImpersonate(state) {
  const user = getUsersState(state).userToImpersonate;
  return user && user.toJS ? user.toJS() : user;
}

export function getUserToDelete(state) {
  const user = getUsersState(state).userToDelete;
  return user && user.toJS ? user.toJS() : user;
}

export function getDeletingUser(state) {
  return getUsersState(state).deletingUser;
}

export function getDomainSearching(state) {
  return getUsersState(state).searchingDomains;
}

export function getDomainSearchResults(state) {
  const results = getUsersState(state).domainSearchResults;
  return results && results.toJS ? results.toJS() : results;
}

export function getDomainSearchTerm(state) {
  return getUsersState(state).domainSearchTerm;
}

export function getDomainSearchComplete(state) {
  return getUsersState(state).domainSearchComplete;
}
