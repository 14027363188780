import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { firebaseFirestore as fs } from "../../../../core/firebase";
import Helmet from "react-helmet";
import ImageSearch from "../../../components/image-search/google/container";
import BlockedImages from "../../../components/image-search/google/blocked/container";

function ImageSearchPage(props) {
  const [blockedURLs, setBlockedURLs] = useState([]);

  const unwrap = (snapshot) => {
    if (!snapshot) return [];
    let output = [];
    snapshot.forEach((doc) => {
      output.push({
        key: doc.id,
        ...doc.data(),
      });
    });
    return output;
  };

  useEffect(() => {
    const unsubscribe = fs
      .collection("imageSearchBlocks")
      .onSnapshot((snapshot) => {
        setBlockedURLs(unwrap(snapshot));
      });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div>
      <Helmet title="Image Search" />

      <Switch>
        <Route path="/image-search/google/blocked">
          <BlockedImages blockedURLs={blockedURLs} />
        </Route>
        <Route path="/image-search/google">
          <ImageSearch blockedURLs={blockedURLs} />
        </Route>
      </Switch>
    </div>
  );
}

export default ImageSearchPage;
