import React, { Fragment } from "react";
import moment from "moment";
import Logo from "../../../../../images/book-creator-logo--dark.png";
import "./index.scss";

function PDFHeader(props) {
  return (
    <Fragment>
      <header className="pdf-header">
        <img src={Logo} className="pdf-header__logo" alt="Book Creator" />
        <h1 className="pdf-header__title">
          <span className="pdf-header__title-intro">Analytics report</span>
          <span className="pdf-header__title-domain">bookcreator.com</span>
          <span className="pdf-header__title-dates">
            {moment().subtract(18, "months").format("Do MMMM YYYY")} -{" "}
            {moment().subtract(1, "days").format("Do MMMM YYYY")}
          </span>
        </h1>
      </header>
      <div className="pdf-intro" contentEditable suppressContentEditableWarning>
        <p>
          This is a snapshot of our analytics dashboard. At the top you can see
          all teachers within your domain who have Book Creator accounts and how
          many libraries they’ve created. You can also see how many students are
          currently within their libraries and how many books they’ve made.
          Scroll down to see some further graphs and charts on how the tool is
          being used by your students.
        </p>
        <p>
          With a district license you would have access to your own admin
          console with live teacher and student analytics
        </p>
      </div>
    </Fragment>
  );
}

export default PDFHeader;
