import React, { Fragment, useState } from "react";
import InlineButton from "../../../../../ui/inline-button";
import Modal from "../../../../../modal";
import TextBox from "../../../../../forms/text-box";
import { isValidEmail } from "../../../../../../../utils/validators";
import {
  BOOKCREATOR_API_URL,
  SUBSCRIPTIONS_DOMAIN,
} from "../../../../../../../config";
// import PropTypes from "prop-types";

function StripeTransfer(props) {
  const { addErrorMessage, addNotification, uid, refreshUser, period } = props;

  const [showOverlay, setShowOverlay] = useState(false);
  const [targetUser, setTargetUser] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const getUserIdFromEmail = async email => {
    const response = await fetch(
      `${BOOKCREATOR_API_URL}/admin-tools/v2/me/users/search/${encodeURIComponent(
        email
      )}`,
      { method: "GET", credentials: "include" }
    );
    const result = await response.json();
    if (result && result.length === 1) {
      return result[0].id;
    } else {
      throw new Error();
    }
  };

  const handleError = err => {
    setSubmitting(false);
    addErrorMessage(err?.message || "Something went wrong");
  };

  const handleSuccess = () => {
    addNotification("Subscription successfully transferred");
    refreshUser(uid);
    setShowOverlay(false);
  };

  const transferUser = async targetUserId => {
    const response = await fetch(
      `${SUBSCRIPTIONS_DOMAIN}/admin/plans/transfer/${uid}/to/${targetUserId}`,
      { method: "PUT", credentials: "include" }
    );
    setSubmitting(false);
    if (response.status === 200) {
      handleSuccess();
    } else {
      const res = await response.json();
      handleError(new Error(res?.message));
    }
  };

  const handleSubmit = async evt => {
    setSubmitting(true);
    let userId;
    if (isValidEmail(targetUser)) {
      try {
        userId = await getUserIdFromEmail(targetUser.trim());
      } catch (err) {
        return handleError(err);
      }
    } else {
      userId = targetUser;
    }
    transferUser(userId);
  };

  const onShowOverlay = () => {
    setShowOverlay(true);
    setTargetUser("");
  };

  return (
    <Fragment>
      <dt>Stripe:</dt>
      <dd>
        {period ? <span style={{ marginRight: 10 }}>{period}</span> : null}
        <InlineButton onClick={onShowOverlay} style={{ marginLeft: -5 }}>
          Transfer subscription
        </InlineButton>
      </dd>
      {showOverlay ? (
        <Modal
          title="Transfer Stripe subscription"
          modal
          onConfirm={handleSubmit}
          confirmButtonDisabled={targetUser.length < 1}
          confirmButtonLoading={submitting}
          onCancel={() => setShowOverlay(false)}>
          <TextBox
            label="User to transfer to (email address or user ID)"
            value={targetUser}
            disabled={submitting}
            onChange={change => setTargetUser(change.trim())}
          />
        </Modal>
      ) : null}
    </Fragment>
  );
}

StripeTransfer.propTypes = {
  //
};

export default StripeTransfer;
