import React from "react";
import SvgIcon from "../../ui/svg-icon";
import CrossIcon from "../../../../images/svg/cross.svg";
import SearchIcon from "../../../../images/svg/search.svg";
import "./index.scss";

function TableSearch({ search, setSearch }) {
  return (
    <div className="table-search">
      <input
        type="text"
        value={search}
        placeholder="Search organisations"
        onChange={e => setSearch(e.target.value)}
        className="table-search__input"
      />
      {search.length ? (
        <button className="table-search__clear" onClick={e => setSearch("")}>
          <SvgIcon icon={CrossIcon} />
        </button>
      ) : (
        <SvgIcon icon={SearchIcon} name="search" />
      )}
    </div>
  );
}

export default TableSearch;
