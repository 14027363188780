import React, { useState } from "react";
import { BOOKCREATOR_API_URL } from "../../../../../../../config";
import Modal from "../../../../../modal";
import Loader from "../../../../../ui/loader";

function DeleteAvatarConfirmation(props) {
  const { addErrorMessage, onClose, onComplete, userId } = props;

  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);
    try {
      await fetch(`${BOOKCREATOR_API_URL}/users/v2/${userId}/profile/avatar`, {
        method: "DELETE",
        credentials: "include",
      });
      onComplete();
      onClose();
    } catch (err) {
      addErrorMessage("Avatar couldn't be deleted");
      setDeleting(false);
    }
  };

  return (
    <Modal
      modal
      title={deleting ? "Deleting..." : "Delete avatar?"}
      hideFooter={deleting}
      onCancel={onClose}
      onConfirm={handleDelete}
      confirmButtonType="negative"
      confirmText="Delete">
      {deleting ? (
        <div style={{ height: 70 }}>
          <Loader center />
        </div>
      ) : (
        <div>Are you sure you want to delete this avatar?</div>
      )}
    </Modal>
  );
}

export default DeleteAvatarConfirmation;
