import React, { Component } from "react";
import classNames from "classnames";
import ReactSelect from "react-select";
import SvgIcon from "../../ui/svg-icon";
import ChevronIcon from "../../../../images/svg/chevron.svg";
import TriangleIcon from "../../../../images/svg/triangle.svg";
import "./index.scss";

export default class Dropdown extends Component {
  state = {
    openAbove: false,
  };

  handleOpen = () => {
    const { top } = this._arrow.getBoundingClientRect();
    const h = window.innerHeight;
    this.setState({
      openAbove: top + 100 > h,
    });
  };

  handleChange = e => {
    const { onChange } = this.props;
    if (e) {
      onChange(e);
    }
  };

  render() {
    const {
      value,
      options,
      className,
      label,
      menuPlacement,
      placeholder,
      triangle,
      width,
      open,
    } = this.props;

    const { openAbove } = this.state;

    const arrow = triangle
      ? () => {
          return (
            <div ref={c => (this._arrow = c)}>
              <SvgIcon icon={TriangleIcon} />
            </div>
          );
        }
      : () => {
          return (
            <div ref={c => (this._arrow = c)}>
              <SvgIcon icon={ChevronIcon} />
            </div>
          );
        };

    const containerClass = classNames("dropdown", {
      "dropdown--with-label": !!label,
    });

    let cssClass = classNames("Select", {
      [`${className}`]: className,
      triangle: triangle,
      "open-above": openAbove,
    });

    return (
      <div className={containerClass}>
        {label ? <label className="dropdown__label">{label}</label> : null}
        <ReactSelect
          ref={c => (this._select = c)}
          onChange={this.handleChange}
          value={value}
          className={cssClass}
          classNamePrefix={"Select"}
          isSearchable={false}
          placeholder={placeholder}
          arrowRenderer={arrow}
          autosize
          onOpen={this.handleOpen}
          menuPlacement={menuPlacement}
          clearable={false}
          searchable={false}
          width={width}
          openOnFocus={open}
          options={options.map(o =>
            o.label ? o : { value: o.value, label: o.value }
          )}
        />
      </div>
    );
  }
}

Dropdown.defaultProps = {
  options: [],
};
