import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import ImageSearch from "./";
import { getUserId } from "../../../../core/auth";

function ImageSearchContainer(props) {
  return <ImageSearch {...props} />;
}

const mapStateToProps = createSelector(getUserId, (userId) => ({
  userId,
}));

export default connect(mapStateToProps, null)(ImageSearchContainer);
