import React, { Fragment } from "react";
import { withRouter } from "react-router";
import Helmet from "react-helmet";
import Header, { HeaderActions } from "../../header";
import PageControl from "../../ui/page-control";
import Content from "../../content";
import Search from "./search";

function ImageSearch(props) {
  const { history } = props;
  const params = new URLSearchParams(history.location.search);
  const searchTerm = params.get("search");
  const blockedUrl = searchTerm
    ? `/image-search/google/blocked?search=${searchTerm}`
    : `/image-search/google/blocked`;

  return (
    <Fragment>
      <Helmet title="Google Image Search" />
      <Header title="Google Image Search">
        <HeaderActions right>
          <PageControl link={blockedUrl} text="Blocked images">
            Blocked images
          </PageControl>
        </HeaderActions>
      </Header>
      <Content>
        <Search {...props} />
      </Content>
    </Fragment>
  );
}

export default withRouter(ImageSearch);
