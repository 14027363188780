import { useEffect, useState } from "react";
import { OrgDomain, OrgDomainTestCase } from ".";
import { testDomainMatcher } from "src/core/organisations/orgs-api";

interface ValidatedTestCase extends OrgDomainTestCase {
  valid?: boolean;
}

interface ApiHookResponse {
  validatedTestCases: ValidatedTestCase[];
  isLoading: boolean;
}

export const useValidatedTestCases = (
  props: OrgDomain,
  matchers: OrgDomain[],
  runValidation: boolean
): ApiHookResponse => {
  const { domain, local, type, testCases } = props;

  const [validatedTestCases, setValidatedTestCases] = useState<
    ValidatedTestCase[]
  >(props.testCases || []);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!testCases.length || !domain || !type || isLoading) {
      return;
    }
    const getData = async () => {
      try {
        setIsLoading(true);
        const validateTestCases = testCases.map(testCase => ({ ...testCase }));
        for await (const testCase of validateTestCases) {
          const { email, expectedType } = testCase;
          const payload = {
            domain,
            local,
            type,
            matchers,
          };
          const pass = await testDomainMatcher(expectedType, email, payload);
          testCase.valid = pass;
        }
        setValidatedTestCases(validateTestCases);
      } catch (error) {}
      setIsLoading(false);
    };
    if (!isLoading && runValidation) {
      getData();
    }
  }, [matchers, domain, local, type, testCases, isLoading, runValidation]);

  return { validatedTestCases, isLoading };
};
