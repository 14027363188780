import React, { Component } from "react";
import Button from "../../button";
import "./index.scss";

const MAX_CODES = 10;

export default class PromoCodeGeneratorForm extends Component {
  state = {
    codesToGenerate: 1,
  };

  handleInputChange = (e) => {
    const val = parseInt(e.target.value, 10);
    const minVal = val ? val : null;
    this.setState({
      codesToGenerate: minVal ? Math.min(minVal, MAX_CODES) : "",
    });
  };

  handleSubmit = (e) => {
    this.props.onSubmit(this.state.codesToGenerate);
    e.preventDefault();
  };

  render() {
    const { codesToGenerate } = this.state;

    return (
      <form className="generator-form" onSubmit={this.handleSubmit}>
        <label className="generator-form__label">Generate</label>
        <input
          type="number"
          className="generator-form__input"
          value={codesToGenerate}
          max={MAX_CODES}
          onChange={this.handleInputChange}
        />
        <label className="generator-form__unit">codes</label>
        <Button type="submit" small>
          Go
        </Button>
      </form>
    );
  }
}
