import React from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import BlockedImages from ".";
import { getUserId } from "../../../../../core/auth";

function BlockedImagesContainer(props) {
  return <BlockedImages {...props} />;
}

const mapStateToProps = createSelector(getUserId, (userId) => ({
  userId,
}));

export default connect(mapStateToProps, null)(BlockedImagesContainer);
