import React, { useRef, useEffect, useState } from "react";
import { SEARCH_API_DOMAIN } from "../../../../../config";
import { withRouter } from "react-router";
import useFetch from "use-http";
import SearchField from "../../../forms/search-field";
import Loader from "../../../ui/loader";
import Results from "./results";
import Message from "../../../ui/message";
import "./index.scss";

function PixabayImageSearch(props) {
  const {
    blockedIDs,
    blockedTags,
    history,
    userId,
    blockById,
    unBlockById,
    blockByTag,
    unBlockByTag,
  } = props;

  const [results, setResults] = useState(null);
  const [searching, setSearching] = useState(false);
  const [searchError, setSearchError] = useState(null);
  const locationSearchRef = useRef();

  const { get, response, error } = useFetch(
    `${SEARCH_API_DOMAIN}/pixabay/images`,
    {
      credentials: "include",
    }
  );

  const handleSearch = async (searchTerm) => {
    history.push(`/image-search/pixabay?search=${searchTerm}`);
  };

  useEffect(() => {
    const doSearch = async () => {
      const params = new URLSearchParams(history.location.search);
      const searchTerm = params.get("search");
      setSearching(true);
      const lang = "en";
      const result = await get(
        encodeURI(
          `${searchTerm.toLowerCase()}?userInterfaceLanguage=${lang}&per_page=200`
        )
      );
      setSearching(false);
      setSearchError(false);
      if (response.ok) {
        setResults(result);
      } else {
        setSearchError(true);
      }
    };

    if (
      history.location.search &&
      history.location.search !== locationSearchRef.current
    ) {
      doSearch();
      locationSearchRef.current = history.location.search;
    }
  }, [
    history.location.search,
    locationSearchRef,
    get,
    response.ok,
    response.status,
    response.headers,
  ]);

  const params = new URLSearchParams(history.location.search);
  const searchedTerm = params.get("search");

  return (
    <div>
      <SearchField
        onSubmit={handleSearch}
        placeholder="Search for images"
        search={searchedTerm}
      />
      {error || searchError ? (
        <Message
          type="error"
          style={{ maxWidth: 500, margin: "0 auto", textAlign: "center" }}
        >
          {(error && error.message) ||
            "Something went wrong searching for the images"}
        </Message>
      ) : null}
      {searching ? <Loader /> : null}
      {results && results.length === 0 ? (
        <p style={{ textAlign: "center" }}>No results found '{searchedTerm}'</p>
      ) : null}
      {results && results.length > 0 ? (
        <Results
          results={results}
          blockedIDs={blockedIDs}
          blockedTags={blockedTags}
          obscureblocked
          blockById={blockById}
          unBlockById={unBlockById}
          blockByTag={blockByTag}
          unBlockByTag={unBlockByTag}
          userId={userId}
        />
      ) : null}
    </div>
  );
}

export default withRouter(PixabayImageSearch);
