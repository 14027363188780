import React, { Fragment } from "react";
import Slug from "./slug";
import ID from "./id";
import classNames from "classnames";
import "./index.scss";

function NounProjectSearchResult(props) {
  const {
    blockedIDs,
    blockedSlugs,
    id,
    onToggleSlug,
    onToggleId,
    previewUrl,
    tagSlugs,
  } = props;

  const blockedId = blockedIDs.filter((blockedID) => blockedID.value === id);
  const isBlockedId = blockedId.length > 0;

  let slugs = [];
  let blockedBySlug = false;

  tagSlugs.forEach((slug) => {
    const blockedSlug = blockedSlugs.filter(
      (blockedSlug) => blockedSlug.value === slug
    );
    const isBlocked = blockedSlug.length > 0;
    if (isBlocked) {
      blockedBySlug = true;
    }
    slugs.push(
      <Slug
        selected={isBlocked}
        blockedId={isBlocked ? blockedSlug[0].key : null}
        onToggle={onToggleSlug}
        key={slug}
      >
        {slug}
      </Slug>
    );
  });

  const className = classNames("noun-search-result", {
    "noun-search-result--blocked": isBlockedId || blockedBySlug,
  });

  let blockedText;

  if (isBlockedId && blockedBySlug) {
    blockedText = "Blocked by ID and slug";
  } else if (isBlockedId) {
    blockedText = "Blocked by ID";
  } else if (blockedBySlug) {
    blockedText = "Blocked by slug";
  }

  return (
    <div className={className}>
      <div className="noun-search-result__preview">
        <img className="noun-search-result__image" src={previewUrl} alt="" />
        {isBlockedId || blockedBySlug ? (
          <Fragment>
            <img
              src="https://storage.bookcreator.com/static/emoji/1f6ab.svg"
              alt=""
              className="noun-search-result__emoji"
            />
            <span className="noun-search-result__blocked">{blockedText}</span>
          </Fragment>
        ) : null}
      </div>
      <ID
        id={id}
        firebaseId={isBlockedId ? blockedId[0].key : null}
        onToggle={onToggleId}
        blocked={isBlockedId}
      />
      {slugs}
    </div>
  );
}

export default NounProjectSearchResult;
