import moment from "moment";
import { TIMES, USER_FILTERS, USER_TYPES } from "../constants";

const thirtyDaysAgo = moment().subtract(30, "days");
const eighteenMonthsAgo = moment().subtract(18, "months");
const GA_CUTOFF_DATE = "2023-07-01";

function getHAxisLabel(dateString, timespan) {
  let d;
  if (timespan === TIMES.MONTH) {
    d = moment(dateString).format("MMM YYYY");
  } else {
    d = moment(dateString).format("MMM D");
  }
  return d;
}

const addEmptyRows = (range, data, timeDimension, startOfDay, defaultValue) => {
  let entries, format;
  switch (timeDimension) {
    case TIMES.WEEK:
      entries = Array.from(range.by("week"));
      // use start of week day based on CA data to prevent locale issues
      format = entry => entry.day(startOfDay).format("YYYY-MM-DD");
      break;
    case TIMES.MONTH:
      entries = Array.from(range.by("month"));
      format = entry => entry.startOf("month").format("YYYY-MM-DD");
      break;
    case TIMES.DAY:
    default:
      entries = Array.from(range.by("day"));
      format = entry => entry.format("YYYY-MM-DD");
      break;
  }
  entries.forEach(entry => {
    const dateStr = format(entry);
    if (!data[dateStr]) {
      data[dateStr] = defaultValue;
    }
  });
  return data;
};

const getDateRange = days => {
  const yesterday = moment().subtract(1, "days");
  const startDate = yesterday.clone().subtract(days, "days");
  const range = moment.range(startDate, yesterday);
  return range;
};

export const defaultRenderer = ({
  allData,
  timeDimension,
  userDimension,
  title,
  rowFormatter,
}) => {
  const { data, from, days: totalDays } = allData;
  // in case we want to start showing charts by student / teacher
  // default to total in case the chart is not set with userFilter
  const userData =
    userDimension === null
      ? USER_FILTERS[USER_TYPES.ALL]
      : USER_FILTERS[userDimension];
  // get range for the current date and time dimension
  const dateRange = getDateRange(totalDays);
  let d = [
    [
      title,
      `${title} (${userData})`,
      { type: "string", role: "annotation" },
      { type: "string", role: "annotationText" },
    ],
  ];
  if (!data.length) {
    return d;
  }
  // The response could contain stale data for older dates
  // Filter it to extract only the ones within the valid range
  let days = {};
  data.forEach(row => {
    const day = row.date;
    if (dateRange.contains(moment(day))) {
      let value = parseFloat(row[userData]);
      if (rowFormatter) {
        value = rowFormatter(value);
      }
      days[day] = value;
    }
  });
  const startOfWeek = moment(from).day();
  days = addEmptyRows(dateRange, days, timeDimension, startOfWeek, 0);
  // sort keys by date otherwise they come orderded by the ones containing data first
  Object.keys(days)
    .sort((a, b) => moment(a) - moment(b))
    .forEach(date => {
      d.push([
        date,
        days[date],
        ...getHistoricDataAnnotations(date, timeDimension, startOfWeek),
      ]);
    });
  const dataRes = d.map((row, i) => {
    if (i === 0) {
      return row;
    } else {
      return [getHAxisLabel(row[0], timeDimension), row[1], row[2], row[3]];
    }
  });
  return dataRes;
};

const getHistoricDataAnnotations = (date, timeDimension, startOfWeek) => {
  // we want to show a single annotation point that's closer to the cutoff date
  // depending on the time dimension we need to check this differently
  let cutoffDate;
  switch (timeDimension) {
    case TIMES.MONTH:
      cutoffDate = [moment(GA_CUTOFF_DATE), "month"];
      break;
    case TIMES.WEEK:
      cutoffDate = [moment(GA_CUTOFF_DATE).day(startOfWeek)];
      break;
    case TIMES.DAY:
    default:
      cutoffDate = [moment(GA_CUTOFF_DATE)];
      break;
  }

  if (moment(date).isSame(...cutoffDate)) {
    return [
      "⚠",
      "From July 1st we have stopped using Google Analytics for this data resulting in more accurate reporting.",
    ];
  }
  return [null, null];
};

export const totalBookCountRenderer = ({ allData, timeDimension }) => {
  let dateComparison;
  switch (timeDimension) {
    case TIMES.MONTH:
      // e.g 201903
      dateComparison = Number(
        `${eighteenMonthsAgo.get("year")}${eighteenMonthsAgo.format("MM")}`
      );
      break;
    case TIMES.WEEK:
      // e.g 201909
      dateComparison = Number(
        `${eighteenMonthsAgo.get("year")}${eighteenMonthsAgo.format("WW")}`
      );
      break;
    case TIMES.DAY:
    default:
      // e.g 20190301
      dateComparison = Number(
        `${eighteenMonthsAgo.get("year")}${eighteenMonthsAgo.format(
          "MM"
        )}${eighteenMonthsAgo.format("DD")}`
      );
      break;
  }

  const pastTotal = allData.filter(entry => entry.date === null)[0].count;
  const current = allData.filter(
    entry => parseInt(entry.date, 10) >= dateComparison
  );

  let currentKeyed = {};
  current.forEach(entry => {
    currentKeyed[entry.date] = entry.count;
  });
  let yesterday = moment().subtract(1, "days");
  if (timeDimension === TIMES.WEEK) {
    yesterday = moment().add(6, "days");
  }

  const startDate =
    timeDimension === TIMES.DAY
      ? thirtyDaysAgo.format("YYYY-MM-DD")
      : eighteenMonthsAgo.format("YYYY-MM-DD");

  const range = moment.range(startDate, yesterday);
  const days = Array.from(range.by("day"));
  const weeks = Array.from(range.by("week"));
  const months = Array.from(range.by("month"));

  let runningTotal = pastTotal;

  let outputArr = [["", "Total books created"]];
  if (timeDimension === TIMES.WEEK) {
    const outputWeeks = {};
    weeks.forEach(week => {
      let w = week.startOf("week").format("WW");
      const dateStr = `${week.get("GG")}${w}`;
      const weekCount = currentKeyed[dateStr] || 0;
      runningTotal += weekCount;
      outputWeeks[dateStr] = runningTotal;
    });

    Object.keys(outputWeeks).forEach(key => {
      outputArr.push([
        moment(key, "YYYYWW").startOf("week").format("MMM D"),
        outputWeeks[key],
      ]);
    });
  } else if (timeDimension === TIMES.MONTH) {
    const outputMonths = {};
    months.forEach(week => {
      const dateStr = `${week.get("year")}${week.format("MM")}`;
      const monthCount = currentKeyed[dateStr] || 0;
      runningTotal += monthCount;
      outputMonths[dateStr] = runningTotal;
    });
    Object.keys(outputMonths).forEach(key => {
      outputArr.push([
        moment(key, "YYYYMM").format("MMM YYYY"),
        outputMonths[key],
      ]);
    });
  } else {
    const outputDays = {};
    days.forEach(day => {
      const dateStr = day.format("YYYYMMDD");
      const dayCount = currentKeyed[dateStr] || 0;
      runningTotal += dayCount;
      outputDays[dateStr] = runningTotal;
    });
    Object.keys(outputDays).forEach(key => {
      outputArr.push([moment(key).format("MMM D"), outputDays[key]]);
    });
  }
  return outputArr;
};

export const pieChartRenderer = ({
  allData,
  title,
  userDimension = USER_TYPES.ALL,
}) => {
  const { data } = allData;
  let d = [[title, title]];
  if (data) {
    const metrics = data.reduce((all, item) => {
      item.values.forEach(metric => {
        const key = !metric.name ? "unknown" : metric.name;
        const value = metric[USER_FILTERS[userDimension]];
        const prevVal = all[key] || 0;
        all[key] = prevVal + value;
      });
      return all;
    }, {});

    Object.keys(metrics).forEach(key => {
      d.push([key, parseFloat(metrics[key])]);
    });
  }
  return d;
};
