import React from "react";
import "./index.scss";

interface OrganisationSectionProps {
  title: string;
  children: any;
}

export default function OrganisationSection(props: OrganisationSectionProps) {
  const { title, children } = props;

  return (
    <div className="organisation-section">
      {title ? <h2 className="organisation-section__title">{title}</h2> : null}
      <div className="organisation-section__content">{children}</div>
    </div>
  );
}
