import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "../../../../modal";
import TextBox from "../../../../forms/text-box";
import { isValidUrl } from "../../../../../../utils/validators";
import { blockImageByUrl } from "../../../../../../core/image-search/actions";

function BlockByUrl(props) {
  const { onClose } = props;
  const [link, setLink] = useState("");
  const dispatch = useDispatch();
  const handleConfirm = () => {
    dispatch(blockImageByUrl({ link }));
    onClose();
  };
  return (
    <Modal
      onCancel={onClose}
      onConfirm={isValidUrl(link) ? handleConfirm : null}
      confirmText="Block"
      showX
      modal
      clickBackgroundToClose
    >
      <form onSubmit={handleConfirm}>
        <TextBox value={link} onChange={setLink} label="URL" autoFocus />
      </form>
    </Modal>
  );
}

export default BlockByUrl;
