import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  usersActions,
  getUserToDelete,
  getDeletingUser,
} from "../../../../core/users";
import Modal from "../../modal";
import Loader from "../../ui/loader";
import UserToDelete from "./user-to-delete";

class DeleteUserModal extends Component {
  handleCancelClick = e => {
    const { setShowDeleteUserModal, clearUserToDelete } = this.props;
    setShowDeleteUserModal(false);
    clearUserToDelete();
  };

  handleConfirmClick = e => {
    const { deleteUser, userToDelete } = this.props;
    deleteUser(userToDelete.uid);
  };

  render() {
    const { userToDelete, deletingUser } = this.props;

    const email = userToDelete?.email;

    const hasBooksInOrgLibrary = userToDelete?.errors?.find(
      err => err.errorType === "BooksInOrganisationLibrary"
    );

    return (
      <Modal
        modal
        title={
          email && !deletingUser
            ? `Delete user ${email}?`
            : deletingUser
            ? `Deleting ${email}`
            : null
        }
        onCancel={!deletingUser ? this.handleCancelClick : null}
        onConfirm={
          userToDelete && !deletingUser && !hasBooksInOrgLibrary
            ? this.handleConfirmClick
            : null
        }
        confirmButtonType="negative"
        confirmText="Permanently delete user">
        {!userToDelete || deletingUser ? (
          <div style={{ height: 100 }}>
            <Loader center />
          </div>
        ) : null}
        {userToDelete && !deletingUser ? (
          <UserToDelete userToDelete={userToDelete} />
        ) : null}
      </Modal>
    );
  }
}

const mapStateToProps = createSelector(
  getUserToDelete,
  getDeletingUser,
  (userToDelete, deletingUser) => ({
    userToDelete,
    deletingUser,
  })
);

const mapDispatchToProps = {
  ...usersActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUserModal);
