import React, { Component } from "react";
import { connect } from "react-redux";
import uuid from "node-uuid";
// import { createSelector } from 'reselect';
import PromoCodeGeneratorForm from "./form";
import PromoCodeGeneratorResults from "./results";
import { notificationsActions } from "../../../core/notifications";
import "./index.scss";

class PromoCodeGenerator extends Component {
  state = {
    codes: [],
  };

  handleFormSubmit = (codesToGenerate) => {
    let codes = [];
    for (let i = 0; i < codesToGenerate; i++) {
      codes.push({
        code: null,
        failed: false,
        ready: i === 0,
        id: uuid.v4(),
      });
    }
    this.setState({
      codes,
    });
  };

  handleCodeError = (index) => {
    const { addErrorMessage } = this.props;
    this.setState({
      codes: [
        ...this.state.codes.map((code, i) => {
          if (i === index) {
            code.failed = true;
          }
          if (i - 1 === index) {
            code.ready = true;
          }
          return code;
        }),
      ],
    });
    addErrorMessage("Something went wrong generating the code");
  };
  handleCodeLoad = (index, generatedCode) => {
    this.setState({
      codes: [
        ...this.state.codes.map((code, i) => {
          if (i === index) {
            code.code = generatedCode;
          }
          if (i - 1 === index) {
            code.ready = true;
          }
          return code;
        }),
      ],
    });
  };

  render() {
    const { codes } = this.state;

    return (
      <div>
        <PromoCodeGeneratorForm onSubmit={this.handleFormSubmit} />
        {codes.length ? (
          <PromoCodeGeneratorResults
            codes={codes}
            onCodeError={this.handleCodeError}
            onCodeLoad={this.handleCodeLoad}
          />
        ) : null}
      </div>
    );
  }
}

// const mapStateToProps = createSelector(
//   getShowDowngradeModal,
//   getShowDeleteUserModal,
//   (showDowngradeModal, showDeleteUserModal) => ({
//     showDowngradeModal,
//     showDeleteUserModal
//   })
// )

const mapDispatchToProps = {
  ...notificationsActions,
};

export default connect(null, mapDispatchToProps)(PromoCodeGenerator);
