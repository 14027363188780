import { routerReducer } from "react-router-redux";
import { combineReducers } from "redux";
import { authReducer } from "./auth";
import { analyticsReducer } from "./analytics";
import { downgradeReducer } from "./downgrade";
import { usersReducer } from "./users";
import { organisationsReducer } from "./organisations";
import { notificationsReducer } from "./notifications";

export default combineReducers({
  auth: authReducer,
  analytics: analyticsReducer,
  routing: routerReducer,
  downgrade: downgradeReducer,
  users: usersReducer,
  organisations: organisationsReducer,
  notifications: notificationsReducer,
});
